import { NewPageData } from '../../../../AppTypes';
import { API_URLS } from '../../../../utils/API/urls';

export const componentsData: NewPageData = {
  apiMethod: API_URLS.COMPONENTS,
  dataListName: 'componentsList',
  idName: 'id',
  pageHeaderTitle: 'Компоненты',
  tabNameField: 'title',
  columnsName: 'ComponentsTable',
};
