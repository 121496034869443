import { DictionaryData, OnceRecordProps } from '../types';
import { API_URLS } from '../../../API/urls';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import {
  setContentResultInterview,
  setDataResultInterview,
} from '../../../../store/reducers/ResultInterview';
import { PermissionPage } from '../../../../AppTypes';

const tabNameField = 'title';

export const resultInterviewPermission: PermissionPage = {
  update: 'updateInterviewersDecision',
  create: 'createInterviewersDecision',
  delete: 'deleteInterviewersDecision',
  one: 'getInterviewersDecision',
  all: 'getAllInterviewersDecision',
};

export const resultInterviewDictionaryData: DictionaryData = {
  apiMethod: API_URLS.RESULTS_INTERVIEW,
  dataListName: 'interviewersDecisionList',
  idName: 'id',
  pageHeaderTitle: 'Результаты интервью',
  tabNameField: tabNameField,
  reducer: 'resultInterviewReducer',
  columnsName: 'ResultInterviewTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: tabNameField,
  popupList: getPopupList(resultInterviewPermission),
  setData: setDataResultInterview,
  setContent: setContentResultInterview,
  permission: resultInterviewPermission,
};

export const onceResultInterviewProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: API_URLS.RESULTS_INTERVIEW,
  dataListName: 'interviewersDecision',
  name: tabNameField,
  permission: resultInterviewPermission,
};
