import React from 'react';
import { Empty, Modal } from 'antd/es';
import { connect } from 'react-redux';
import {
  openList,
  setNotificationsList,
} from '../../store/reducers/NotificationsReducer';
import API from '../../utils/API/API';
import EditForm from './EditForm';
import NotificationUtils from './NotificationUtils';
import NotificationListComponent from './Components/NotificationListComponent';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  visibleList: boolean;
  dispatch: Function;
  profile: any;
  notificationsList: any;
  navigate: NavigateFunction;
};

type State = {
  editModal: boolean;
  currentItem: any;
  loading: boolean;
};

class NotificationList extends React.Component<Props, State> {
  private notificationUtils;

  constructor(props) {
    super(props);

    this.state = {
      editModal: false,
      currentItem: null,
      loading: false,
    };

    this.notificationUtils = new NotificationUtils({
      id: props.profile.id,
      dispatch: props.dispatch,
      successEdit: this.successEdit,
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    const self = this;
    API.get(`api/notification?accountId=${this.props.profile.id}`)
      .then(function (response) {
        self.props.dispatch(
          setNotificationsList(response.data.notificationList)
        );
        self.setState({ loading: false });
      })
      .catch(() => self.setState({ loading: false }));
  };

  successEdit = (editModal: boolean, currentItem: any) => {
    this.setState({ editModal: editModal, currentItem: currentItem });
  };

  render() {
    return (
      <>
        <Modal
          open={this.props.visibleList}
          title="Список напоминаний"
          onCancel={() => {
            this.props.dispatch(openList(false));
          }}
          width={1000}
          footer={null}
        >
          {this.props.notificationsList && (
            <NotificationListComponent
              onDelete={this.notificationUtils.onDelete}
              successEdit={this.successEdit}
              navigate={this.props.navigate}
              loading={this.state.loading}
            />
          )}
          {!this.props.notificationsList && (
            <Empty description="Нет запланированных напоминаний" />
          )}
        </Modal>

        {this.state.currentItem && (
          <Modal
            open={this.state.editModal}
            onCancel={() => {
              this.setState({ editModal: false });
            }}
            footer={null}
          >
            <EditForm
              onFinish={this.notificationUtils.onEdit}
              item={this.state.currentItem}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    visibleList: state.notificationsReducer.visibleList,
    notificationsList: state.notificationsReducer.notificationsList,
    profile: state.authReducer.profile,
    current: state.notificationsReducer.currentNotification,
    visible: state.notificationsReducer.visibleNotification,
  };
};

export default connect(mapStateToProps)(NotificationList);
