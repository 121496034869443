import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, notification, Table } from 'antd';
import { useAppSelector } from '../../../../store/rootReducer';
import { AbstractTableProps } from '../type';
import { TableRowSelection } from 'antd/es/table/interface';
import { usePaginationSetting } from '../helpers/usePaginationSetting';
import { getPermissionPopup } from '../../../Base/helpers/permissionHelpers';
import { isDesktop } from 'react-device-detect';
import { EditableCell } from './EditableCell';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import dayjs from 'dayjs';
import {
  useCreateAbstractDataMutation,
  useDeleteAbstractDataMutation,
  useGetAbstractDataQuery,
  useUpdateAbstractDataMutation,
} from '../../../../store/api/abstract.api';
import { getCurrentLink } from '../helpers/getCurrentLink';
import { useSearchParams } from 'react-router-dom';
import { PopupList } from '../../Popup/PopupTypes';
import { useChangeTab } from '../../../../components/Layouts/Components/helpers/useChangeTab';
import Popup from '../../Popup/Popup';

const AbstractTable = React.memo(
  ({
    apiMethod,
    idName,
    rowSelect,
    expandable,
    scrollX,
    rowClassName,
    popupList,
    columns,
    initialColumns,
    setIsDuplicate,
    setIsEdit,
    onRow,
    popup,
    currentRow,
    dataSource,
    cancelAddRow,
    columnsName,
    formFields,
    form,
    setIsAddRow,
    editRow,
    setEditRow,
    getFilteredSearchFields,
    dataListName,
    isListMutation = true,
  }: AbstractTableProps) => {
    const { onChange, pagination } = usePaginationSetting(
      apiMethod,
      dataListName
    );
    const { darkTheme } = useAppSelector((state) => state.authReducer);
    const [deleteRowId, setDeleteRowId] = useState(0);
    const changeTab = useChangeTab();
    const currentLink = getCurrentLink(apiMethod);
    const path = currentLink.replace('api/', '');
    const { isFetching } = useGetAbstractDataQuery(`${path}`);
    const [update, { isLoading: editLoading }] =
      useUpdateAbstractDataMutation();
    const [onDelete] = useDeleteAbstractDataMutation();
    const [onFinishAdd, { isLoading: addLoading }] =
      useCreateAbstractDataMutation();
    const searchParams = currentLink.split('?')[1];
    const [_, setSearchParams] = useSearchParams();

    const renderColumns = (columns) => {
      return columns
        .filter((col) => col.visible)
        .reduce((prev, col) => {
          const initCol = initialColumns.find(
            (initCol) => initCol.key === col.key
          );
          return initCol
            ? [...prev, col.fixed ? { ...initCol, fixed: col.fixed } : initCol]
            : prev;
        }, []);
    };

    const tablesSettings = useAppSelector(
      (state) => state.abstractColumnsSlice.settings
    );
    const currentColum = tablesSettings[columnsName] ?? null;
    const rawColumns = useMemo(
      () =>
        currentColum
          ? renderColumns(currentColum!)
          : initialColumns.filter((el) => el.visible),
      [currentColum, initialColumns]
    );

    const rowSelection: TableRowSelection<any> = {
      type: rowSelect?.type,
      hideSelectAll: rowSelect?.hideSelectAll,
      preserveSelectedRowKeys: true,
      onChange: (rowKeys, rows) => {
        rowSelect?.onSelectRows(rowKeys, rows);
      },
      selectedRowKeys: rowSelect?.selectedRows,
      getCheckboxProps: rowSelect?.getCheckboxProps,
      columnWidth: 40,
    };

    useEffect(() => {
      setSearchParams(searchParams);
    }, []);

    const setPopupList = (): PopupList | undefined => {
      if (!popupList) return;
      const newPopupList =
        typeof popupList === 'function'
          ? getPermissionPopup(popupList(currentRow))
          : getPermissionPopup(popupList);

      newPopupList.forEach((item) => {
        switch (item.type) {
          case 'checkout':
            return (item.onClick = () =>
              changeTab(`${window.location.pathname}/${currentRow[idName]}`));
          case 'edit':
            return (item.onClick = () => setIsEdit(true));
          case 'duplicate':
            return (item.onClick = () => setIsDuplicate(true));
          case 'massEdit':
            return (item) => {
              return item.onClick(item);
            };
          case 'getRecord':
            return (item.onClick = () => item.getRecord!(currentRow));
          case 'delete':
            return (item.onClick = () =>
              onDelete({
                path: `${path.split('?')[0]}/${currentRow[idName]}?${
                  path.split('?')[1]
                }`,
                listKey: path,
              }));
          case 'massDelete':
            return (item.onClick = () => console.log('massDelete'));
        }
      });
      return newPopupList as PopupList;
    };

    const popupMemo = useMemo(setPopupList, [currentRow]);

    const scroll =
      scrollX && isDesktop
        ? {
            x: (scrollX / initialColumns.length) * columns.length,
          }
        : { x: 1200 };

    const formatDateBeforeSubmit = (body: Record<string, any>) => {
      const dateNameList = columns
        .filter((col) => col.editSetting?.formatDate)
        .map((col) => ({
          name: col.editSetting?.editName,
          format: col.editSetting?.formatDate,
        }));

      dateNameList.forEach(({ name, format }) => {
        if (name)
          body[name] = body[name]
            ? dayjs(body[name]).format(format)
            : undefined;
      });
    };

    const onClickAdd = async () => {
      try {
        await form.validateFields();
        const fields = form.getFieldsValue();
        const body = { ...fields };
        formatDateBeforeSubmit(body);

        await onFinishAdd({
          body: isListMutation ? [body] : body,
          path,
        }).then((res) => {
          if ('error' in res) {
            // @ts-ignore
            notification.error({
              // @ts-ignore
              message: res?.error?.data?.error ?? 'Error creating record',
            });
            throw Error;
          } else {
            setIsAddRow(false);
            getFilteredSearchFields?.(window.location.search);
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    const editingColumns = useMemo(() => {
      return formFields
        ? [
            ...rawColumns?.map((col) => {
              if (!col.editSetting?.editName) return col;
              return {
                ...col,
                onCell: (record) => ({
                  record,
                  form: form,
                  required: col.editSetting.required,
                  dataIndex: col.editSetting.editName,
                  title: col.title,
                  component: col.editSetting.component(record),
                  editing: record[idName] === editRow?.[idName],
                }),
              };
            }),
            {
              title: '',
              dataIndex: 'edit',
              width: 40,
              align: 'center',
              render: (item, record) => (
                <>
                  {!Object.keys(record).length ? (
                    <>
                      <Button
                        icon={<CheckOutlined />}
                        type={'text'}
                        size={'small'}
                        onClick={onClickAdd}
                        loading={addLoading}
                      />
                      <Button
                        icon={<CloseOutlined />}
                        type={'text'}
                        size={'small'}
                        onClick={() => {
                          cancelAddRow();
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {editRow?.[idName] === record[idName] ? (
                        <>
                          <Button
                            icon={<CheckOutlined />}
                            disabled={
                              editRow?.[idName] !== record[idName] && editRow
                            }
                            type={'text'}
                            size={'small'}
                            onClick={async () => {
                              try {
                                await form.validateFields();
                                onFinishEditRow(form.getFieldsValue());
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                            loading={editLoading}
                          />
                          <Button
                            icon={<CloseOutlined />}
                            type={'text'}
                            size={'small'}
                            onClick={() => {
                              setEditRow(null);
                            }}
                          />
                        </>
                      ) : (
                        <Button
                          icon={<EditOutlined />}
                          type={'text'}
                          size={'small'}
                          onClick={() => {
                            const formatFormFields = Object.entries(
                              formFields
                            ).reduce((prev, [key, value]) => {
                              const tableValue = get(record, value);
                              return {
                                ...prev,
                                [key]: key.toLowerCase().includes('date')
                                  ? tableValue
                                    ? dayjs(tableValue)
                                    : undefined
                                  : tableValue,
                              };
                            }, {});
                            form.setFieldsValue(formatFormFields);
                            setEditRow((prev) => (prev ? null : record));
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              ),
            },
          ]
        : rawColumns;
    }, [rawColumns, editRow, form]);

    const onFinishEditRow = (values) => {
      const body = { ...values, [idName]: editRow[idName] };
      formatDateBeforeSubmit(body);

      update({
        path: path,
        body: isListMutation ? [body] : body,
        id: editRow![idName],
      }).then((res) => {
        if ('error' in res) {
          // @ts-ignore
          notification.error({
            // @ts-ignore
            message: res?.error?.data?.error ?? 'Error editing record',
          });
          throw Error;
        } else {
          setEditRow(null);
          getFilteredSearchFields?.(window.location.search.toString());
          return res;
        }
      });
    };

    return (
      <div className={'tableContainer'}>
        <Form component={false} form={form}>
          <Table
            bordered
            // @ts-ignore
            columns={editingColumns}
            size="small"
            dataSource={dataSource}
            rowKey={(record) => record[idName]}
            rowSelection={rowSelect ? { ...rowSelection } : undefined}
            onRow={onRow}
            pagination={pagination}
            onChange={onChange}
            scroll={scroll}
            sticky={{
              offsetHeader: 91,
              offsetScroll: 0,
            }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={(record) =>
              (currentRow?.[idName] === record[idName] && popup.visible) ||
              record[idName] === deleteRowId
                ? darkTheme
                  ? 'row-select-dark'
                  : 'row-select'
                : rowClassName?.(record) ?? ''
            }
            expandable={expandable}
            style={{ fontSize: '0.75rem' }}
            showSorterTooltip={false}
            loading={isFetching}
          />
        </Form>
        <Popup
          {...popup}
          list={popupMemo!}
          currentRow={currentRow}
          setDeleteRow={(row) => setDeleteRowId(row ? row[idName] : 0)}
        />
      </div>
    );
  }
);
AbstractTable.displayName = 'AbstractTable';
export default AbstractTable;
