import { PermissionPage } from '../../../AppTypes';

export const employeeRepostCardPermission: PermissionPage & {
  arrayCreate: string;
} = {
  all: 'getAllEmployeeReportCard',
  delete: 'deleteEmployeeReportCard',
  one: 'getEmployeeReportCard',
  create: 'createEmployeeReportCard',
  update: 'updateEmployeeReportCard',
  arrayCreate: 'createEmployeeReportCardList',
};
