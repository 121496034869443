import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { Role } from '../Accounts/type';
import { SearchFieldsType } from '../../../../utils/Components/SearchPanel/types/SearchPanelTypes';

export const roleColumns: AbstractColumns<Role> = [
  {
    title: 'Наименование',
    key: 'title',
    dataIndex: 'title',
    align: 'center',
    visible: true,
  },
  {
    title: 'Описание',
    key: 'description',
    dataIndex: 'description',
    align: 'center',
    visible: true,
  },
];

export const searchFields: SearchFieldsType[] = [
  {
    component: 'input',
    label: 'Наименование',
    name: 'title',
    placeholder: '',
    required: false,
    span: 4,
  },
];
