import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_WORK_STATUS = 'SET_DATA_WORK_STATUS';
const SET_CONTENT_WORK_STATUS = 'SET_CONTENT_WORK_STATUS';

export declare type WorkStatus = {
  id: number;
  title: string;
  description: string | null;
  color: string | null;
  isWorkingStatus: boolean;
};

export declare type DataWorkStatus = {
  success: boolean;
  DataList: ResponseData<WorkStatus>;
};

export declare type WorkStatusReducer = {
  data: DataWorkStatus | null;
  content: Array<WorkStatus> | null;
};

/**
 * @param {DataWorkStatus} data
 * @return {any}
 */
export function setDataWorkStatus(data) {
  return {
    type: SET_DATA_WORK_STATUS,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkStatus>} data
 * @return {any}
 */
export function setContentWorkStatus(data) {
  return {
    type: SET_CONTENT_WORK_STATUS,
    payload: data,
  };
}

const initialState: WorkStatusReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function workStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WORK_STATUS:
      return { ...state, data: action.payload };
    case SET_CONTENT_WORK_STATUS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
