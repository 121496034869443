import React, { useState } from 'react';
import { Button, ColorPicker, Form, Input, Switch } from 'antd/es';
import { NewFormProps } from '../../../../AppTypes';
import { SubworkDirection } from '../../../../store/reducers/SubworkDirection';
import { WorkStatus } from '../../../../store/reducers/WorkStatus';

const WorkStatusForm = React.memo(
  ({
    currentRow,
    type,
    onFinishEdit,
    onFinishAdd,
    idName,
  }: NewFormProps<WorkStatus> & { idName: string }) => {
    const record = type === 'edit' ? currentRow : null;
    const [title, setTitle] = useState(record?.title);
    const [description, setDescription] = useState(record?.description);
    const [color, setColor] = useState(record?.color ?? '#fff');
    const [isWorkingStatus, setIsWorkingStatus] = useState(
      !!record?.isWorkingStatus
    );

    const onFinish = (values: Partial<SubworkDirection>) => {
      type === 'edit'
        ? onFinishEdit!({
            ...values,
            [idName]: record?.[idName],
          })
        : onFinishAdd!(values);
    };

    const fields = [
      { name: 'title', value: title },
      { name: 'description', value: description },
      { name: 'color', value: color },
      { name: 'isWorkingStatus', value: isWorkingStatus },
    ];
    return (
      <Form layout={'vertical'} fields={fields} onFinish={onFinish}>
        <Form.Item
          name={'title'}
          rules={[{ required: true, message: '' }]}
          label={'Наименование'}
        >
          <Input onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item name={'description'} label={'Описание'}>
          <Input onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>
        <Form.Item name={'color'} label={'Цвет'}>
          <ColorPicker onChange={(_, hex) => setColor(hex)} />
        </Form.Item>
        <Form.Item
          name={'isWorkingStatus'}
          label={'Действующий сотрудник'}
          valuePropName={'checked'}
        >
          <Switch onChange={setIsWorkingStatus} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
WorkStatusForm.displayName = 'WorkStatusForm';
export default WorkStatusForm;
