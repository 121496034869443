import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_ORGANIZATION = 'SET_DATA_ORGANIZATION';
const SET_CONTENT_ORGANIZATION = 'SET_CONTENT_ORGANIZATION';

export declare type Organization = Dictionary;

export declare type DataOrganization = {
  success: boolean;
  organizationDataList: ResponseData<Organization>;
};

export declare type OrganizationReducer = {
  data: DataOrganization | null;
  content: Array<any> | null;
};

/**
 * @param {DataOrganization} data
 * @return {any}
 */
export function setDataOrganization(data) {
  return {
    type: SET_DATA_ORGANIZATION,
    payload: data,
  };
}

/**
 * @param {Array<DataOrganization>} data
 * @return {any}
 */
export function setContentOrganization(data) {
  return {
    type: SET_CONTENT_ORGANIZATION,
    payload: data,
  };
}

const initialState: OrganizationReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ORGANIZATION:
      return { ...state, data: action.payload };
    case SET_CONTENT_ORGANIZATION:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
