import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { commonApi } from './commonApi';
import {yambookApi} from "./yambookApi";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(commonApi.middleware, yambookApi.middleware),
});

export default store;
