import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { DeviceToEmployee } from '../SetToDevices/DeviceToEmployee.type';
import { SearchFieldsType } from '../../../../utils/Components/SearchPanel/types/SearchPanelTypes';
import { renderDate } from '../../../../utils/Base/helpers/renderDate';
import { PopupList } from '../../../../utils/Components/Popup/PopupTypes';
import React from 'react';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import { FolderOutlined } from '@ant-design/icons';
import { SendInArchive } from '../helpers/useSendInArchiveDeviceToEmployee';
import { Tag } from 'antd/es';

export const initialColumns: AbstractColumns<DeviceToEmployee> = [
  {
    title: 'Сотрудник',
    key: 'employee',
    dataIndex: ['employee', 'fullName'],
    align: 'center',
    visible: true,
  },
  {
    title: 'Номер комплекта',
    key: 'setOfDevice',
    dataIndex: ['setOfDevice', 'number'],
    align: 'center',
    visible: true,
  },
  {
    title: 'Дата создания',
    key: 'createDate',
    dataIndex: ['createDate'],
    align: 'center',
    visible: true,
    render: renderDate,
  },
  {
    title: 'В архиве',
    key: 'isArchived',
    dataIndex: ['isArchived'],
    align: 'center',
    visible: true,
    render: (item) => <Tag>{item ? 'Да' : 'Нет'}</Tag>,
  },
];

export const getSearchFields = (): SearchFieldsType[] => [
  {
    component: 'input',
    label: 'Номер',
    name: 'number',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Input',
    label: 'Сотрудник',
    name: 'fullName',
    placeholder: 'ФИО',
    required: false,
    span: 4,
  },
  {
    component: 'datepicker',
    label: 'Дата создания',
    name: 'creationDate',
    placeholder: '',
    required: false,
    span: 4,
  },
];

export const getPopupList = (
  sendInArchive: SendInArchive
): PopupList<DeviceToEmployee> => {
  return [
    {
      title: 'Подробнее',
      icon: <FileTextOutlined />,
      type: 'checkout',
      onClick: () => {
        console.log('More details');
      },
    },
    {
      title: (record) => {
        return record?.isArchived ? 'Вернуть из архива' : 'Отправить в архив';
      },
      icon: <FolderOutlined />,
      type: 'getRecord',
      getRecord: (record) => {
        sendInArchive({
          id: record.id,
          isArchived: !record.isArchived,
          employeeId: record.employee.id,
        });
      },
    },
    {
      title: 'Удалить',
      icon: <MinusOutlined />,
      type: 'delete',
      onClick: () => {
        console.log('Delete');
      },
    },
  ];
};
