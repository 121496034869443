import store from '../../../store/store';
import { PopupList } from '../../Components/Popup/PopupTypes';
import intersection from 'lodash/intersection';
import { getMenuItems } from '../../../components/Layouts/PageLayoutExtra';

export const menuPermissionSetter = (
  menu: ReturnType<typeof getMenuItems>,
  permissionList: string[]
) => {
  if (!permissionList.length) return menu;
  const getFilterElements = (filterItem) => {
    if (!filterItem) return;
    if (filterItem.length === 0) return true;
    for (let i = 0; i < filterItem.length; i++) {
      const menuPermission = filterItem[i];
      if (permissionList.includes(menuPermission)) return true;
    }
  };

  const menuFilter = (menuItem) => {
    if (!menuItem?.permission) return true;
    if (menuItem.children) {
      menuItem.children.forEach((item) => menuFilter(item));
      menuItem.children = menuItem.children.filter((el) =>
        getFilterElements(el.permission)
      );
      if (!menuItem.children.length) return false;
    }

    return getFilterElements(menuItem.permission);
  };

  return menu.filter(menuFilter);
};

export const routerPermissionSetter = (
  routerPermission,
  permissionList: string[],
  profile
) => {
  if (permissionList === null) return true;
  if (routerPermission && permissionList) {
    if (routerPermission.includes('superAdmin')) {
      return profile?.admin;
    }
    if (!routerPermission.length || !permissionList.length) return true;
    return intersection(permissionList, routerPermission).length;
  }
};

export const isPermissionAction = (typeName: string) => {
  const permissionList = store.getState().authReducer.permissionList;

  if (permissionList.length === 0) return true;

  const currentPermissions: string[] =
    store.getState().workplaceTabsReducer.currentTab?.permission ?? [];
  const currentPermName = currentPermissions.find(
    (perm: string) => perm.slice(0, typeName.length) === typeName
  );
  if (!currentPermName) return true;
  return !!permissionList.find((perm) => perm === currentPermName);
};

export const getPermissionPopup = (popup: PopupList) => {
  return popup.filter((pop) => {
    if (pop.type === 'edit' || pop.type === 'massEdit') {
      return isPermissionAction('update');
    } else if (pop.type === 'checkout') {
      return isPermissionAction('get');
    } else if (pop.type === 'delete' || pop.type === 'massDelete') {
      return isPermissionAction('delete');
    } else return true;
  });
};
