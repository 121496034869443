import React from 'react';
import { useAppSelector } from '../../../../store/rootReducer';
import { isDesktop } from 'react-device-detect';
import { Button, Tag, Tooltip } from 'antd';
import { PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { AbstractClassPageHeaderExtraButtonItem } from '../../AbstractClass/AbstractClassTypes';
import { AbstractPageHeaderProps } from '../type';
import { PageHeader as PageHeaderA } from '@ant-design/pro-layout';
import { useDownloadFile } from '../helpers/useDownloadFile';
import DownloadBtn from './DownloadBtn';

const PageHeader = React.memo(
  ({
    pageHeaderTitle,
    pageHeaderExtraComponents,
    pageHeaderExtra,
    totalElements,
    setIsEdit,
    setIsAdd,
    setIsMultiUpload,
    setIsVisibleDrawer,
    apiMethod,
    addRow,
    isAddRow,
  }: AbstractPageHeaderProps) => {
    const { darkTheme, permissionList } = useAppSelector(
      (state) => state.authReducer
    );
    const downloadExcelFile = useDownloadFile();

    const getInnerFunction = (funcName: string) => {
      switch (funcName) {
        case 'setIsAdd':
          return setIsAdd;
        case 'setIsEdit':
          return setIsEdit;
        case 'setIsMultiUpload':
          return setIsMultiUpload;
        case 'downloadExcelFile':
          return downloadExcelFile;
      }
    };

    const onPageHeaderExtraClick = (
      item: AbstractClassPageHeaderExtraButtonItem
    ) => {
      const controlledStateFunctions = [
        'setIsAdd',
        'setIsEdit',
        'downloadExcelFile',
        'setIsMultiUpload',
      ];
      switch (item.onClick.type) {
        case 'outerFunction': {
          try {
            return item.onClick.func(
              item.onClick.extra !== undefined ? item.onClick.extra : null
            );
          } catch (error) {
            throw new Error();
          }
        }
        case 'innerFunction': {
          if (
            typeof item.onClick.func === 'string' &&
            controlledStateFunctions.includes(item.onClick.func)
          ) {
            try {
              return getInnerFunction(item.onClick.func)?.(
                item.onClick.extra,
                apiMethod
              );
            } catch (error) {
              throw new Error();
            }
          }
        }
      }
    };

    const filteredPageHeaderExtra =
      pageHeaderExtra?.filter((item) => {
        return item.permission
          ? permissionList.includes(item.permission)
          : true;
      }) ?? [];

    const pageHeaderRender = () => {
      return [
        totalElements !== undefined && isDesktop ? (
          <Tag color={'cyan'} key={'total'}>
            {`Всего элементов: ${totalElements}`}
          </Tag>
        ) : null,
        pageHeaderExtraComponents ?? null,
        pageHeaderExtra
          ? filteredPageHeaderExtra.map(
              (item: AbstractClassPageHeaderExtraButtonItem, index) => {
                return (
                  <>
                    {item.onClick?.func === 'downloadExcelFile' ? (
                      <DownloadBtn
                        item={item}
                        onPageHeaderExtraClick={onPageHeaderExtraClick}
                      />
                    ) : (
                      <Tooltip key={`tooltip${index}}`} title={item.title}>
                        <Button
                          {...item}
                          onClick={() => onPageHeaderExtraClick(item)}
                        >
                          {item.text}
                        </Button>
                      </Tooltip>
                    )}
                  </>
                );
              }
            )
          : false,
        !isAddRow && addRow && (
          <>
            <Button
              type={'primary'}
              icon={<PlusCircleOutlined />}
              onClick={() => addRow()}
            />
          </>
        ),
        isDesktop && (
          <Tooltip title={'Редактировать колонки'} key={'editTableColumns'}>
            <Button
              icon={<SettingOutlined />}
              onClick={() => setIsVisibleDrawer(true)}
            />
          </Tooltip>
        ),
      ];
    };

    return (
      <PageHeaderA
        title={
          <span
            style={{
              color: darkTheme ? 'rgba(255, 255, 255, 0.85)' : undefined,
            }}
          >
            {pageHeaderTitle}
          </span>
        }
        extra={pageHeaderRender()}
        style={{ flexWrap: 'wrap' }}
      />
    );
  }
);
PageHeader.displayName = 'PageHeader';
export default PageHeader;
