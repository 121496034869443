import React, { ReactNode } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Avatar,
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from 'antd/es';
import { EditOutlined, UserOutlined } from '@ant-design/icons/es/icons';
import { ElementViewProps, ElementViewState } from '../AbstractElementTypes';
import CollapsibleModal from '../../../CollapsibleModal/CollapsibleModal';
import { connect } from 'react-redux';
import { isDesktop, isMobile } from 'react-device-detect';
import API from '../../../API/API';
import { setImgSrc } from '../../../../store/reducers/AuthReducer';
import { Tabs as TabsM } from 'antd-mobile';
import get from 'lodash/get';
import { additionalValues } from '../../../../store/reducers/WorkplaceTabs';

/**
 * Отображение отдельного элемента
 */
class AbstractElementViewer extends React.Component<
  ElementViewProps,
  ElementViewState
> {
  /**
   * @param {ElementViewProps} props
   */
  constructor(props) {
    super(props);

    this.state = {
      tabKey: new URLSearchParams(window.location.search).get('tab') ?? '0',
      avatarSrc: null,
    };
  }

  componentDidMount() {
    const { avatar } = this.props;
    if (avatar?.src) this.setAvatar();
  }

  setAvatar = async () => {
    const { avatar, imageSrc, dispatch } = this.props;
    const avatarId = +avatar!.src!;
    if (imageSrc[avatarId]) {
      this.setState({ avatarSrc: imageSrc[avatarId] });
    } else {
      const avatarResult = await API.get(`api/file/download/${avatar!.src}`, {
        responseType: 'blob',
      });
      const fileUrl = URL.createObjectURL(avatarResult.data);

      dispatch!(setImgSrc([avatarId, fileUrl]));

      this.setState({ avatarSrc: fileUrl });
    }
  };

  /**
   * Отрисовка меню extra
   * @return {Array<JSX>}
   */
  renderExtra = () => {
    const { tabs, pageExtra, editMode, content, descriptions } = this.props;
    const { tabKey: key } = this.state;
    const editButton: ReactNode = (
      <Button
        key="edit"
        type={'primary'}
        icon={<EditOutlined />}
        onClick={() => {
          this.props.openEdit(true);
        }}
      />
    );
    const pageExtraButtons: Array<any> = [];
    if (pageExtra) {
      pageExtra.forEach((item) => {
        if (React.isValidElement(item)) {
          if (this.props?.getRecord) {
            pageExtraButtons.push(
              React.cloneElement(item, {
                // @ts-ignore
                onClick: () => {
                  // @ts-ignore
                  this.props.getRecord(this.props.content);
                },
              })
            );
          } else {
            pageExtraButtons.push(item);
          }
        }
      });
    }

    if (key === '0') {
      if (editMode && this.props.editComponent) {
        return pageExtra ? [...pageExtraButtons, editButton] : [editButton];
      } else {
        return pageExtra ? pageExtraButtons : null;
      }
    } else if (tabs) {
      const idx = descriptions ? +key - 1 : +key;
      return tabs[idx]?.extra ? tabs[idx].extra!(content) : null;
    } else {
      return null;
    }
  };

  renderMobileBtn = (key) => {
    const { editMode, pageExtra } = this.props;
    if (key === 'info') {
      return editMode
        ? [
            {
              key: 'edit',
              text: 'Редактировать',
              onClick: () => this.props.openEdit(true),
            },
            ...(pageExtra ?? []),
          ]
        : [...(pageExtra ?? [])];
    }
  };

  /**
   * Обработка заголовка страницы
   * @return {string}
   */
  pageHeaderTitle = () => {
    const { pass, title } = this.props.pageHeaderList;
    if (pass) {
      const passResult = this.checkPass(pass);

      if (title) {
        return `${title}: ${passResult}`;
      } else {
        return passResult;
      }
    } else {
      if (title) {
        return title;
      } else {
        return 'Info';
      }
    }
  };

  /**
   * @param {string} pass
   * @return {JSX | string}
   */
  checkPass = (pass: string | string[] | ((any) => string)) => {
    const { content } = this.props;
    return typeof pass === 'function' ? pass(content) : get(content, pass);
  };

  /**
   * Инъекция
   * @param {React.ReactNode} children
   * @return {React.ReactNode}
   */
  injection = (children) => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        // @ts-ignore
        content: this.props.content,
        routerProps: this.props.router,
      });
    }
  };

  /**
   * @param {string} activeKey
   */
  onChangeTab = (activeKey) => {
    const { dispatch, router } = this.props;
    dispatch!(
      additionalValues([
        {
          action: 'add',
          key: 'params',
          value: activeKey,
        },
      ])
    );
    router.setSearchParams(`tab=${activeKey}`);

    this.setState({ tabKey: activeKey });
  };

  /**
   * @return {JSX}
   */
  render() {
    const {
      avatar,
      tabs,
      content,
      alert,
      darkTheme,
      descriptions,
      destroyInactiveTabPane,
    } = this.props;
    const { avatarSrc } = this.state;

    const getNotNullAlert = () => {
      return !!(
        alert &&
        (alert.notNull === true ||
          (Array.isArray(content[alert.notNull])
            ? content[alert.notNull].length
            : content[alert.notNull]))
      );
    };

    const extra = this.renderExtra();

    const tabsElements =
      tabs?.map((tab, index) => {
        return {
          key: String(index + (descriptions ? 1 : 0)),
          label: tab.title,
          children: this.injection(tab.component(content)),
        };
      }) ?? [];
    const infoTab = descriptions
      ? {
          key: '0',
          label: 'Информация',
          children: (
            <>
              {getNotNullAlert() && (
                <Alert
                  {...alert}
                  description={
                    alert!.descriptionF(content) ?? alert?.description
                  }
                  message={alert!.messageF(content) ?? alert?.message}
                  style={{ marginBottom: 16 }}
                />
              )}
              <Descriptions
                bordered
                column={24}
                layout="vertical"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {descriptions.map((item) => {
                  return (
                    <Descriptions.Item
                      span={isMobile ? 24 : item.span}
                      label={item.label}
                      key={item.fieldName}
                    >
                      {!item.render && <>{this.checkPass(item.fieldName)}</>}
                      {item.render && (
                        <>
                          {item.render(
                            this.checkPass(item.fieldName),
                            this.props.content
                          )}
                        </>
                      )}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </>
          ),
        }
      : undefined;
    const items: TabsProps['items'] = [];
    if (infoTab) {
      items.push(infoTab);
    }
    items.push(...tabsElements);

    return (
      <div style={{ margin: isMobile ? 16 : '0' }}>
        {isDesktop ? (
          <PageHeader
            title={
              <span
                style={{
                  color: darkTheme ? 'rgba(255, 255, 255, 0.85)' : undefined,
                }}
              >
                {this.pageHeaderTitle()}
              </span>
            }
            extra={extra}
            avatar={
              avatar
                ? { icon: <UserOutlined />, src: avatarSrc, size: 50 }
                : undefined
            }
          />
        ) : (
          <Row align={'middle'} justify={'space-between'} gutter={[16, 8]}>
            <Col>
              {avatar && (
                <Avatar
                  icon={<UserOutlined />}
                  size={40}
                  src={avatarSrc}
                  style={{ margin: '8px 8px 0 0' }}
                />
              )}
            </Col>
            <Col>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {this.pageHeaderTitle()}
              </Typography.Title>
            </Col>
            <Col>
              <Space>{extra}</Space>
            </Col>
          </Row>
        )}

        {isDesktop ? (
          <Tabs
            items={items}
            defaultActiveKey={this.state.tabKey}
            onChange={this.onChangeTab}
            destroyInactiveTabPane={destroyInactiveTabPane}
          />
        ) : (
          <TabsM
            defaultActiveKey={this.state.tabKey}
            onChange={this.onChangeTab}
          >
            {items.map((tab) => (
              <TabsM.Tab title={tab.label} key={tab.key}>
                {tab.children}
              </TabsM.Tab>
            ))}
          </TabsM>
        )}

        {this.props.isEdit && (
          <CollapsibleModal
            title="Редактировать запись"
            onCancel={() => {
              this.props.openEdit(false);
            }}
            visible={this.props.isEdit}
            width={
              isDesktop
                ? typeof this.props.editModalWidth === 'number'
                  ? `${this.props.editModalWidth}vw`
                  : this.props.editModalWidth
                : '90vw'
            }
          >
            {this.props.editComponent}
          </CollapsibleModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.authReducer.profile,
  darkTheme: state.authReducer.darkTheme,
  imageSrc: state.authReducer.imageSrc,
});

export default connect(mapStateToProps)(AbstractElementViewer);
