import { NavigateFunction } from 'react-router-dom';
import { useState } from 'react';

export const useOnRow = <T,>(
  gotoRecord: NavigateFunction | undefined = undefined
) => {
  const [popup, setPopup] = useState({
    visible: false,
    pageX: 0,
    pageY: 0,
  });
  const [currentRow, setCurrentRow] = useState<T>();
  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          document.addEventListener(`click`, function onClickOutside() {
            setPopup(Object.assign(popup, { visible: false }));
            document.removeEventListener(`click`, onClickOutside);
          });
        }
        setPopup({
          visible: true,
          pageX: event.clientX,
          pageY: event.clientY,
        });
        setCurrentRow(record);
        return record;
      },
      onDoubleClick: () => {
        gotoRecord && gotoRecord(record);
      },
    };
  };

  return { popup, currentRow, onRow };
};
