import React from 'react';
import { Form, Input, Select } from 'antd';
import { REQUIRED_RULES } from '../../../../../utils/Base/constants/requiredRule';
import { useDictionarySelectOptions } from '../../../../../utils/Base/hooks/useDictionarySelectOptions';
import { brandsData } from '../../../../../utils/Components/Dictionary/extra/brandData';
import { NewFormProps } from '../../../../../AppTypes';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { Devices } from '../Devices.type';
import TableSearchForm from '../../../../../utils/Components/TableSearch/TableSearchForm';
import { API_URLS } from '../../../../../utils/API/urls';
import { componentsData } from '../../ComponentsDevice/componentsData';
import {
  getSearchColumns,
  getSearchFields,
} from '../../ComponentsDevice/ComponentsExtra';
import { Col, Row } from 'antd/es';
import { useForm } from 'antd/es/form/Form';
import { Key } from 'antd/es/table/interface';

const DevicesForm = React.memo(
  ({ type, onFinishEdit, onFinishAdd, currentRow }: NewFormProps<Devices>) => {
    const brandsOptions = useDictionarySelectOptions(brandsData);
    const [form] = useForm();
    const onFinish = async (componentsIdSet: Key[]) => {
      await form.validateFields();
      const values = form.getFieldsValue();
      const data = {
        ...values,
        createDate: dayjs().format('YYYY-MM-DD'),
        componentsIdSet,
      };
      return type === 'edit'
        ? onFinishEdit!({
            ...data,
            id: currentRow?.id,
            createDate: currentRow?.createDate,
          })
        : onFinishAdd!(data);
    };
    return (
      <>
        <Form
          layout={'vertical'}
          form={form}
          initialValues={
            currentRow
              ? { ...currentRow, brandId: currentRow?.brand?.id }
              : undefined
          }
        >
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                name={'name'}
                label={'Наименование'}
                rules={REQUIRED_RULES}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={'brandId'}
                label={'Компания производитель'}
                rules={REQUIRED_RULES}
              >
                <Select
                  options={brandsOptions}
                  optionFilterProp={'label'}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'model'} label={'Модель'} rules={REQUIRED_RULES}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={'serialNumber'}
                label={'Серийный номер'}
                rules={REQUIRED_RULES}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'yearOfProduction'} label={'Год производства'}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'workingCondition'} label={'Рабочее состояние'}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'comment'} label={'Комментарий'}>
                <TextArea autoSize={{ minRows: 2 }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'description'} label={'Описание'}>
                <TextArea autoSize={{ minRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <TableSearchForm
          api={API_URLS.COMPONENTS + '?'}
          onFinish={onFinish}
          dataListName={componentsData.dataListName}
          idName={componentsData.idName}
          columns={getSearchColumns}
          searchFields={getSearchFields()}
          isQuantity={false}
          disabledCreate={(rows) => !rows.length}
          initValue={currentRow?.componentsSet}
        />
      </>
    );
  }
);
DevicesForm.displayName = 'DevicesForm';
export default DevicesForm;
