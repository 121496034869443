import React from 'react';
import CollapsibleModal from '../../../CollapsibleModal/CollapsibleModal';
import { isDesktop } from 'react-device-detect';
import { useCreateAbstractDataMutation } from '../../../../store/api/abstract.api';
import { notification } from 'antd';
import { getCurrentLink } from '../helpers/getCurrentLink';
import { AbstractTableProps } from '../type';

const AbstractDuplicate = React.memo(
  ({
    isDuplicate,
    setIsDuplicate,
    width,
    children,
    currentRow,
    apiMethod,
    getFilteredSearchFields,
  }: {
    isDuplicate: boolean;
    width?: number | string;
    setIsDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    currentRow: any;
    apiMethod: string;
    getFilteredSearchFields: AbstractTableProps['getFilteredSearchFields'];
  }) => {
    const [onFinishAdd, { isLoading: loading }] =
      useCreateAbstractDataMutation();
    const path = getCurrentLink(apiMethod).replace('api/', '');

    const getComponent = (children) => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          // @ts-ignore
          currentRow: currentRow,
          onFinishAdd: (values) =>
            onFinishAdd({ body: values, path }).then((res) => {
              // @ts-ignore
              if (res.error) {
                // @ts-ignore
                notification.error({ message: res?.error?.data?.error });
                throw Error;
              }
              setIsDuplicate(false);
              getFilteredSearchFields?.(window.location.search);
            }),
          isFetching: loading,
        });
      }
    };

    return (
      <CollapsibleModal
        visible={isDuplicate}
        destroyOnClose={true}
        onCancel={() => {
          setIsDuplicate(false);
        }}
        title={'Создание новой записи'}
        width={
          width
            ? `${
                isDesktop
                  ? typeof width === 'number'
                    ? width + 'vw'
                    : width
                  : '90vw'
              }`
            : undefined
        }
      >
        {getComponent(children)}
      </CollapsibleModal>
    );
  }
);
AbstractDuplicate.displayName = 'AbstractDuplicate';
export default AbstractDuplicate;
