import { DictionaryData, OnceRecordProps } from '../types';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import {
  setContentLocationEmployee,
  setDataLocationEmployee,
} from '../../../../store/reducers/LocationEmployee';
import { PermissionPage } from '../../../../AppTypes';

const idName = 'id';
const api = 'api/employee-location';
const name = 'title';

export const locationEmployeePermission: PermissionPage = {
  all: 'getAllEmployeeLocation',
  update: 'updateEmployeeLocation',
  one: 'getEmployeeLocation',
  create: 'createEmployeeLocation',
  delete: 'deleteEmployeeLocation',
};

export const locationEmployeeData: DictionaryData = {
  apiMethod: api,
  dataListName: 'employeeLocationList',
  idName: idName,
  pageHeaderTitle: 'Местоположения сотрудников',
  tabNameField: name,
  reducer: 'locationEmployeeReducer',
  columnsName: 'LocationEmployeeTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList(locationEmployeePermission),
  setData: setDataLocationEmployee,
  setContent: setContentLocationEmployee,
  permission: locationEmployeePermission,
};

export const onceLocationEmployeeProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'employeeLocation',
  name: name,
  permission: locationEmployeePermission,
};
