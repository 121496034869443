import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { REQUIRED_RULES } from '../../../../../utils/Base/constants/requiredRule';
import { NewFormProps } from '../../../../../AppTypes';
import TextArea from 'antd/es/input/TextArea';
import { useDictionarySelectOptions } from '../../../../../utils/Base/hooks/useDictionarySelectOptions';
import { brandsData } from '../../../../../utils/Components/Dictionary/extra/brandData';
import dayjs from 'dayjs';
import { ComponentsDevice } from '../ComponentsDevice.type';

const ComponentForm = React.memo(
  ({
    type,
    onFinishAdd,
    onFinishEdit,
    currentRow,
    isFetching,
  }: NewFormProps<ComponentsDevice>) => {
    const brandOptions = useDictionarySelectOptions(brandsData);

    const onFinish = (values) => {
      const data = { ...values, createDate: dayjs().format('YYYY-MM-DD') };
      type === 'add'
        ? onFinishAdd!(data)
        : onFinishEdit!({
            ...data,
            id: currentRow?.id,
            createDate: currentRow?.createDate,
          });
    };

    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={
          currentRow
            ? { ...currentRow, brandId: currentRow?.brand?.id }
            : undefined
        }
      >
        <Form.Item name={'title'} rules={REQUIRED_RULES} label={'Наименование'}>
          <Input />
        </Form.Item>
        <Form.Item
          name={'serialNumber'}
          rules={REQUIRED_RULES}
          label={'Серийный номер'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'yearOfProduction'}
          rules={REQUIRED_RULES}
          label={'Год производства'}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Рабочее состояние'} name={'workingCondition'}>
          <Input />
        </Form.Item>
        <Form.Item
          label={'Компания производитель'}
          name={'brandId'}
          rules={REQUIRED_RULES}
        >
          <Select
            options={brandOptions}
            showSearch
            optionFilterProp={'title'}
          />
        </Form.Item>
        <Form.Item label={'Описание'} name={'description'}>
          <TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item label={'Комментарий'} name={'comment'}>
          <TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {type === 'edit' ? 'Редактировать' : 'Создать'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
ComponentForm.displayName = 'ComponentForm';
export default ComponentForm;
