import { Modal, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useInvalidateAbstractData } from '../../../../utils/Base/hooks/useInvalidateAbstractData';
import API from '../../../../utils/API/API';
import { AbstractPageProps } from '../../../../utils/Components/AbstractPage/type';

const { confirm } = Modal;

type ListPropName<T> = {
  title: (record: T) => string;
  description: (record: T) => string;
};
export const useMassDelete = <T,>(
  deleteUrl: string,
  invalidateUrl: string,
  listPropName: ListPropName<T & { id: number }>
) => {
  const [selectedRecords, setSelectedRecords] = useState<
    Array<T & { id: number }>
  >([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const invalidate = useInvalidateAbstractData();

  const onSelectedRow = (selectedRowsId, selectedRecords) => {
    setSelectedRecords(selectedRecords);
    setSelectedRows(selectedRowsId);
  };

  const rowSelect: AbstractPageProps['rowSelect'] = {
    type: 'checkbox',
    selectedRows: selectedRows,
    hideSelectAll: false,
    selectedRecords: selectedRecords,
    onSelectRows: onSelectedRow,
  };

  const handlerMassDelete = () => {
    confirm({
      title:
        'Вы действительно хотиту удалить эти записи?' +
        ` (${selectedRecords.length})`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отмена',
      content: (
        <Typography.Paragraph
          ellipsis={{
            rows: 6,
            expandable: 'collapsible',
            symbol: (e) => (e ? 'Свернуть' : 'Больше'),
          }}
        >
          {selectedRecords.map((record) => (
            <Typography.Paragraph key={record.id}>
              <Typography.Text strong>
                {listPropName.title(record)}
              </Typography.Text>
              : {listPropName.description(record)}
            </Typography.Paragraph>
          ))}
        </Typography.Paragraph>
      ),
      onOk: () => {
        return API.delete(`${deleteUrl}${selectedRows.join(',')}`).then(
          (res) => {
            if (res.data.massDeleteBasePhaseErrorData?.length) {
              const itemCodeList = selectedRecords
                .filter((el) =>
                  res.data.massDeleteBasePhaseErrorData?.includes(el.id)
                )
                .map(listPropName.title)
                .join(', ');
            }
            invalidate(invalidateUrl, selectedRows);
            onSelectedRow([], []);
          }
        );
      },
    });
  };

  return { handlerMassDelete, rowSelect };
};
