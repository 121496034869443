import API from '../../API/API';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export const useOnCustomSelect = <T,>(
  link: string,
  dataListName: string,
  idName?: string,
  labelName?: string,
  initialValues?: T | T[]
) => {
  const [dataList, setDataList] = useState<Array<T> | null>(null);
  const [optionList, setOptionList] = useState<
    Array<{ value: any; label: string; data: T }>
  >([]);

  useEffect(() => {
    if (initialValues && labelName && idName)
      if (Array.isArray(initialValues)) {
        setOptionList(
          initialValues.map((el) => ({
            value: el[idName],
            label: el[labelName],
            data: el,
          }))
        );
      } else {
        setOptionList([
          {
            value: initialValues[idName],
            label: initialValues[labelName],
            data: initialValues,
          },
        ]);
      }
  }, []);

  const onSearch = (value: string) => {
    API.get(`${link}${value}`).then((res) => {
      const rawData: Array<T> =
        res.data[dataListName].content ?? res.data[dataListName];
      setDataList(rawData);
      if (idName && labelName)
        setOptionList(
          rawData.map((data) => ({
            value: data[idName],
            label: data[labelName],
            data,
          }))
        );
    });
  };
  const searchHandler = debounce((value: string) => {
    if (value && value.length) {
      onSearch(value.trim());
    }
  }, 300);

  const selectProps = {
    showSearch: true,
    filterOption: false,
    onSearch: searchHandler,
    options: optionList,
  };

  return { searchHandler, optionList, dataList, selectProps };
};
