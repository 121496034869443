import React, { useMemo, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { SearchFieldsType } from './types/SearchPanelTypes';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import API from '../../API/API';
import { SelectProps } from 'antd/es/select';
import SelectOptionDictionary from './SelectOptionDictionary';

const SelectSearchPanel = React.memo(
  ({
    item: selectSettings,
    disabled,
  }: {
    item: SearchFieldsType;
    disabled: boolean;
  }) => {
    const [dataList, setDataList] = useState<any[]>();
    const [selectItemsSearch, setSelectItemsSearch] = useState<
      Array<{ value: any; item: any }>
    >([]);

    const selectItems = selectSettings?.link
      ? selectItemsSearch
      : selectSettings.selectItem ?? [];

    const onSearch = (value) => {
      const { link, dataListName } = selectSettings;
      API.get(`${link}${value}`).then((res) => {
        const resData = Array.isArray(res.data[dataListName!])
          ? res.data[dataListName!]
          : res.data[dataListName!].content;
        const rawData = resData.map((selectItem) => {
          return {
            value: get(selectItem, selectSettings.selectItem![0].value),
            item: get(selectItem, selectSettings.selectItem![0].item),
          };
        });
        setDataList(resData);
        setSelectItemsSearch(rawData);
      });
    };

    const options = useMemo(
      () =>
        selectSettings?.selectItem?.[0]?.render
          ? dataList?.map((data) => ({
              value: get(data, selectSettings.selectItem![0].value).toString(),
              label: selectSettings.selectItem![0].render!(data),
              selected: get(data, selectSettings.selectItem![0].item),
            }))
          : selectItems.map((rawItem) => ({
              value: rawItem.value.toString(),
              label: rawItem.item,
              selected: rawItem.item,
            })),
      [dataList, selectSettings]
    );

    const searchHandler = debounce((value) => {
      if (value && value.length) {
        onSearch(value.trim());
      }
    }, 300);

    const selectProps: SelectProps = {
      mode: selectSettings.selectMode,
      optionFilterProp: 'children',
      filterOption: selectSettings.showSearch
        ? false
        : (input, option) => {
            // @ts-ignore
            return (option?.children ?? option?.label ?? '')
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase());
          },
      notFoundContent: selectSettings.loading ? <Spin size="small" /> : null,
      showSearch: true,
      onSearch: selectSettings.showSearch ? searchHandler : undefined,
      placeholder: selectSettings.placeholder,
      allowClear: !selectSettings.selectMode,
      disabled: disabled,
      optionLabelProp: 'selected',
      options,
    };

    return selectSettings.dictionaryData ? (
      <SelectOptionDictionary
        selectProps={selectProps}
        dictionaryData={selectSettings.dictionaryData}
        selectSettings={selectSettings}
      />
    ) : (
      <Form.Item
        label={selectSettings.label}
        name={selectSettings.name}
        rules={[{ required: selectSettings.required, message: '' }]}
      >
        <Select {...selectProps} />
      </Form.Item>
    );
  }
);
SelectSearchPanel.displayName = 'SelectSearchPanelF';
export default SelectSearchPanel;
