import React, {ReactElement} from 'react';
import { Badge } from 'antd';
import {useGetNotificationCount} from "../useGetNotificationCount";

const NotificationLabel = React.memo(({children, offset}: {children?: ReactElement, offset?: [number, number]}) => {
  const notificationCount = useGetNotificationCount();
  return (
    <Badge count={notificationCount} size={'small'}>
        {children ? children : <div
            style={{color: 'transparent', display: 'inline-block', width: 1}}
        />}
    </Badge>
  );
});
NotificationLabel.displayName = 'NotificationLabel';
export default NotificationLabel;
