import BookOutlined from '@ant-design/icons/BookOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import ExportOutlined from '@ant-design/icons/ExportOutlined';
import HistoryOutlined from '@ant-design/icons/HistoryOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { openList } from '../../store/reducers/NotificationsReducer';
import React from 'react';
import store from '../../store/store';
import { KeycloakApp } from '../../utils/keycloak/KeycloakApp';
import { RiComputerLine } from 'react-icons/ri';
import { AuthProfile } from '../Pages/Admin/Accounts/type';
import { candidatesPermissions } from '../Pages/Сandidates/Candidates.permissions';
import { employeePermission } from '../Pages/Employee/Employee.permission';
import { employeeRepostCardPermission } from '../Pages/EmployeeRepostCard/EmployeeRepostCard.permission';
import { departmentDirectionBondPermission } from '../Pages/DepartmentDirectionBond/DepartmentDirectionBond.permission';
import {
  citizenshipPermission,
  organizationPermission,
  subWorkDirectionPermission,
  workingMethodPermission,
} from '../../utils/Components/Dictionary/DictionaryExtra';
import { workDirectionPermission } from '../Pages/Dictionary/WorkDirection/WorkDirectionExtra';
import { locationEmployeePermission } from '../../utils/Components/Dictionary/extra/locationEmployee';
import { workStatusPermission } from '../../utils/Components/Dictionary/extra/workStatus';
import { devicePermission } from '../Pages/EmpoyeeDevices/Devices/Device.permission';
import { deviceToEmployeePermission } from '../Pages/EmpoyeeDevices/DeviceToEmployee/DeviceToEmployee.permission';
import { setToDevicePermission } from '../Pages/EmpoyeeDevices/SetToDevices/SetToDevice.permission';
import { brandPermission } from '../../utils/Components/Dictionary/extra/brandData';
import { componentDevicePermission } from '../Pages/EmpoyeeDevices/ComponentsDevice/ComponentDevice.permission';
import { interviewRequestPermission } from '../Pages/InterviewRequest/InterviewRequest.permission';
import { rolesPermission } from '../Pages/Admin/Roles/Roles.permission';
import { questionnairePermission } from '../Pages/Questionnaire/Questionnaire.permission';
import { completedQuestionnairePermission } from '../Pages/CompletedQuestionnaire/CompletedQuestionnaire.permission';
import { projectPermission } from '../../utils/Components/Dictionary/extra/projectData';
import { positionPermission } from '../../utils/Components/Dictionary/extra/positionsData';
import { keySkillsPermission } from '../../utils/Components/Dictionary/extra/keySkillsData';
import { departmentPermission } from '../../utils/Components/Dictionary/extra/departmentData';
import { workplacePermission } from '../../utils/Components/Dictionary/extra/workplaceData';
import { refusalPermission } from '../../utils/Components/Dictionary/extra/refusalData';
import { resultInterviewPermission } from '../../utils/Components/Dictionary/extra/resultInterviewData';
import { notificationPermission } from '../Notification/Notification.permission';
import NotificationLabel from './Components/Notificationlabel';
import { Badge } from 'antd';
import { historyResumePermission } from '../Pages/HistoryResume/HistoryResume.permission';

export const getMenuItems = (
  navigate,
  profile: AuthProfile,
  notificationCount: number,
  collapsed: boolean
) => {
  return [
    {
      label: 'Главная страница',
      key: '/',
      icon: <HomeOutlined />,
      onClick: () => navigate('/'),
    },
    {
      label: 'Кандидаты',
      key: '/candidates',
      permission: [candidatesPermissions.all],
      icon: <ContactsOutlined />,
      onClick: () => navigate('/candidates'),
    },
    {
      label: 'Управление сотрудниками',
      key: '/employee-menu',
      icon: <TeamOutlined />,
      permission: [
        employeePermission.all,
        employeeRepostCardPermission.all,
        departmentDirectionBondPermission.all,
      ],
      children: [
        {
          label: 'Сотрудники',
          key: '/employee',
          onClick: () => navigate('/employee'),
          permission: [employeePermission.all],
        },
        {
          label: 'Табели сотрудников',
          key: '/employee-report-card',
          permission: [employeeRepostCardPermission.all],
          onClick: () => navigate('/employee-report-card'),
        },
        {
          label: 'Табель',
          key: '/employee-report-card-summary',
          permission: [employeeRepostCardPermission.all],
          onClick: () => navigate('/employee-report-card-summary'),
        },
        {
          label: 'Отдел - Направление - Поднаправление',
          key: '/departments-direction-work-bond',
          permission: [departmentDirectionBondPermission.all],
          onClick: () => navigate('/departments-direction-work-bond'),
        },
        {
          label: 'Иное',
          key: '/dictionary-employs',
          permission: [
            organizationPermission.all,
            workingMethodPermission.all,
            citizenshipPermission.all,
            workDirectionPermission.all,
            subWorkDirectionPermission.all,
            locationEmployeePermission.all,
            workStatusPermission.all,
          ],
          children: [
            {
              label: 'Организации',
              key: '/organization',
              permission: [organizationPermission.all],
              onClick: () => navigate('/organization'),
            },
            {
              label: 'Методы работы',
              key: '/working-method',
              permission: [workingMethodPermission.all],
              onClick: () => navigate('/working-method'),
            },
            {
              label: 'Список гражданств',
              key: '/citizenship',
              permission: [citizenshipPermission.all],
              onClick: () => navigate('/citizenship'),
            },
            {
              label: 'Направления работ',
              key: '/work-direction',
              permission: [workDirectionPermission.all],
              onClick: () => navigate('/work-direction'),
            },
            {
              label: 'Поднаправления работ',
              key: '/sub-work-direction',
              permission: [subWorkDirectionPermission.all],
              onClick: () => navigate('/sub-work-direction'),
            },
            {
              label: 'Статусы работ',
              key: '/work-status',
              permission: [workStatusPermission.all],
              onClick: () => navigate('/work-status'),
            },
            {
              label: 'Местоположения сотрудников',
              key: '/location-employee',
              permission: [employeePermission.all],
              onClick: () => navigate('/location-employee'),
            },
          ],
        },
      ],
    },
    {
      label: 'Оборудование',
      key: '/employee-devices',
      permission: [
        employeePermission.all,
        devicePermission.all,
        deviceToEmployeePermission.all,
        setToDevicePermission.all,
        brandPermission.all,
        componentDevicePermission.all,
      ],
      icon: <RiComputerLine />,
      children: [
        {
          label: 'Сотрудники',
          key: '/employee-devices',
          permission: [employeePermission.all],
          onClick: () => navigate('/employee-devices'),
        },
        {
          label: 'Комплекты сотрудников',
          key: '/device-to-employee',
          permission: [deviceToEmployeePermission.all],
          onClick: () => navigate('/device-to-employee'),
        },
        {
          label: 'Устройства',
          key: '/devices',
          permission: [devicePermission.all],
          onClick: () => navigate('/devices'),
        },
        {
          label: 'Наборы устройств',
          key: '/set-to-device',
          permission: [setToDevicePermission.all],
          onClick: () => navigate('/set-to-device'),
        },
        {
          label: 'Иное',
          key: '/others',
          permission: [brandPermission.all, componentDevicePermission.all],
          children: [
            {
              label: 'Компании производители',
              key: '/brands',
              permission: [brandPermission.all],
              onClick: () => navigate('/brands'),
            },
            {
              label: 'Компоненты',
              key: '/components',
              permission: [componentDevicePermission.all],
              onClick: () => navigate('/components'),
            },
          ],
        },
      ],
    },
    {
      label: 'Заявки на кандидатов',
      key: '/interviews-request',
      icon: <UsergroupAddOutlined />,
      permission: [interviewRequestPermission.all],
      onClick: () => navigate('/interviews-request'),
    },
    {
      label: 'Роли и аккаунты',
      key: '/role-and-accounts',
      icon: <UnorderedListOutlined />,
      permission: [rolesPermission.all],
      children: [
        {
          label: 'Аккаунты',
          key: '/accounts',
          permission: [rolesPermission.all],
          onClick: () => navigate('/accounts'),
        },
        {
          label: 'Роли',
          key: '/roles',
          permission: [rolesPermission.all],
          onClick: () => navigate('/roles'),
        },
      ],
    },
    {
      label: 'Опросы',
      key: '/questionnaire',
      permission: [
        questionnairePermission.all,
        completedQuestionnairePermission.all,
      ],
      icon: <SolutionOutlined />,
      children: [
        {
          label: 'Опросники',
          key: '/questionnaire-list',
          permission: [questionnairePermission.all],
          onClick: () => navigate('/questionnaire'),
        },
        {
          label: 'Пройденные опросы',
          key: '/completed-questionnaire',
          permission: [completedQuestionnairePermission.all],
          onClick: () => navigate('/completed-questionnaire'),
        },
      ],
    },
    {
      label: 'Словари',
      key: '/dictionaries',
      icon: <BookOutlined />,
      permission: [
        projectPermission.all,
        positionPermission.all,
        keySkillsPermission.all,
        departmentPermission.all,
        workplacePermission.all,
        refusalPermission.all,
        resultInterviewPermission.all,
      ],
      children: [
        {
          label: 'Должности',
          key: '/positions',
          permission: [positionPermission.all],
          onClick: () => navigate('/positions'),
        },
        {
          label: 'Ключевые навыки',
          key: '/key-skills',
          permission: [keySkillsPermission.all],
          onClick: () => navigate('/key-skills'),
        },
        {
          label: 'Отделы',
          key: '/departments',
          permission: [departmentPermission.all],
          onClick: () => navigate('/departments'),
        },
        {
          label: 'Места работы',
          key: '/workplaces',
          permission: [workplacePermission.all],
          onClick: () => navigate('/workplaces'),
        },
        {
          label: 'Причины отказа',
          key: '/refusals',
          permission: [refusalPermission.all],
          onClick: () => navigate('/refusals'),
        },
        {
          label: 'Проекты',
          key: '/projects',
          permission: [projectPermission.all],
          onClick: () => navigate('/projects'),
        },
        {
          label: 'Результаты интервью',
          key: '/result-interview',
          permission: [resultInterviewPermission.all],
          onClick: () => navigate('/result-interview'),
        },
      ],
    },
    {
      label: 'История действий',
      key: '/logs',
      icon: <HistoryOutlined />,
      permission: ['getInterviewerLogs'],
      onClick: () => navigate('/logs'),
    },
    {
      label: 'История просмотра резюме',
      key: '/history-resume',
      icon: <HistoryOutlined />,
      permission: [historyResumePermission.all],
      onClick: () => navigate('/history-resume'),
    },
    {
      label: 'Профиль',
      key: 'profile',
      icon: (
        <Badge
          offset={collapsed ? [10, 10] : [0, 0]}
          count={notificationCount}
          size={'small'}
        >
          <UserOutlined />
        </Badge>
      ),
      permission: [notificationPermission.all],
      children: [
        {
          label: 'Напоминания',
          key: '/notifications',
          icon: <NotificationOutlined />,
          permission: [notificationPermission.all],
          onClick: () => store.dispatch(openList(true)),
        },
        {
          label: (
            <>
              Профиль <NotificationLabel />
            </>
          ),
          key: '/background',
          icon: <UserOutlined />,
          onClick: () => navigate(`/accounts/${profile?.id}`),
          permission: [],
        },
        {
          label: 'Выход',
          key: '/exit',
          permission: [],
          icon: <ExportOutlined />,
          onClick: () => {
            KeycloakApp?.logout();
          },
        },
      ],
    },
  ];
};
