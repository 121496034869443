import React, { useState } from 'react';
import { Button, Flex, Menu, Modal } from 'antd';
import { isDesktop } from 'react-device-detect';
import { CustomerServiceOutlined } from '@ant-design/icons';
import FeedbackForm from './FeedbackForm';
import { getMenuItems } from '../PageLayoutExtra';
import { menuPermissionSetter } from '../../../utils/Base/helpers/permissionHelpers';
import { WorkplaceMenuProps } from '../LayoutTypes';
import { useAppSelector } from '../../../store/rootReducer';
import { useGetNotificationCount } from '../useGetNotificationCount';

const WorkplaceMenuF = React.memo((props: WorkplaceMenuProps) => {
  const { router } = props;
  const notificationCount = useGetNotificationCount();
  const { profile, permissionList } = useAppSelector(
    (state) => state.authReducer
  );
  const [isFeedbackForm, setIsFeedbackForm] = useState(false);
  const activeMenuItem = [`/${router.location.pathname.split('/')[1]}`];
  const menu = getMenuItems(
    router.navigate,
    profile!,
    notificationCount,
    props.collapsed ?? false
  );
  const menuItems = isDesktop
    ? menu
    : menu.slice(2).filter((menuItem) => menuItem.key !== '/vacancy');
  let rawMenu;
  if (menuItems && permissionList) {
    rawMenu = menuPermissionSetter(menu, permissionList);
  }
  return isDesktop ? (
    <>
      <Flex vertical>
        <Menu
          theme={'dark'}
          mode={'inline'}
          items={rawMenu}
          selectedKeys={activeMenuItem}
        />
        <Flex justify={'center'}>
          <Button
            type={'link'}
            onClick={() => setIsFeedbackForm(true)}
            icon={<CustomerServiceOutlined />}
          />
        </Flex>
      </Flex>
      <Modal
        open={isFeedbackForm}
        onCancel={() => setIsFeedbackForm(false)}
        destroyOnClose
        footer={false}
        title={'Связаться с нами'}
      >
        <FeedbackForm onFinishEnd={() => setIsFeedbackForm(false)} />
      </Modal>
    </>
  ) : (
    <Menu
      theme={'light'}
      mode={'inline'}
      items={rawMenu}
      selectedKeys={activeMenuItem}
    />
  );
});
WorkplaceMenuF.displayName = 'WorkplaceMenuF';
export default WorkplaceMenuF;
