const SET_AREAS_HH = 'SET_AREAS_HH';

export declare type AreasHH = { id: string; name: string; url: string };

export declare type DictionariesHhReducer = {
  areas: AreasHH[] | null;
};

/**
 * @param {any} data
 * @return {any}
 */
export function setAreasHh(data) {
  return {
    type: SET_AREAS_HH,
    payload: data,
  };
}

const initialState: DictionariesHhReducer = {
  areas: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function dictionariesHhReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AREAS_HH:
      return { ...state, areas: action.payload };
    default:
      return state;
  }
}
