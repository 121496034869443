import React from 'react';
import CollapsibleModal from '../../../CollapsibleModal/CollapsibleModal';
import { isDesktop } from 'react-device-detect';
import { AbstractTableProps } from '../type';
import { useCreateAbstractDataMutation } from '../../../../store/api/abstract.api';
import { getCurrentLink } from '../helpers/getCurrentLink';
import { notification } from 'antd';

const AbstractAdd = React.memo(
  ({
    isAdd,
    setIsAdd,
    createModalWidth,
    children,
    apiMethod,
    getFilteredSearchFields,
  }: {
    isAdd: boolean;
    createModalWidth?: number | string;
    setIsAdd: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    apiMethod: string;
    getFilteredSearchFields: AbstractTableProps['getFilteredSearchFields'];
  }) => {
    const [onFinishAdd, { isLoading: loading }] =
      useCreateAbstractDataMutation();
    const path = getCurrentLink(apiMethod).replace('api/', '');

    const addComponent = () => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          // @ts-ignore
          onFinishAdd: (values) =>
            onFinishAdd({ body: values, path }).then((res) => {
              // @ts-ignore
              if (res.error) {
                // @ts-ignore
                notification.error({ message: res?.error?.data?.error });
                throw Error;
              }
              setIsAdd(false);
              getFilteredSearchFields?.(window.location.search);
            }),
          isFetching: loading,
        });
      }
    };

    return (
      <CollapsibleModal
        visible={isAdd}
        destroyOnClose={true}
        onCancel={() => {
          setIsAdd(false);
        }}
        title={'Создание новой записи'}
        width={
          createModalWidth
            ? `${
                isDesktop
                  ? typeof createModalWidth === 'number'
                    ? createModalWidth + 'vw'
                    : createModalWidth
                  : '90vw'
              }`
            : undefined
        }
      >
        {addComponent()}
      </CollapsibleModal>
    );
  }
);
AbstractAdd.displayName = 'AbstractAdd';
export default AbstractAdd;
