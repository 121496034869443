const VISIBLE_LIST = 'VISIBLE_LIST';
const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST';
const VISIBLE_NOTIFICATION = 'VISIBLE_NOTIFICATION';
const CURRENT_NOTIFICATION = 'CURRENT_NOTIFICATION';

export function openList (data) {
    return {
        type: VISIBLE_LIST,
        payload: data
    }
}

export function setNotificationsList (data) {
    return {
        type: NOTIFICATIONS_LIST,
        payload: data
    }
}


export function setVisibleNotification(data) {
    return {
        type: VISIBLE_NOTIFICATION,
        payload: data
    }
}

export function setCurrentNotification(data) {
    return {
        type: CURRENT_NOTIFICATION,
        payload: data
    }
}

const initialNotifications = {
    visibleList: false,
    notificationsList: null,
    visibleNotification: false,
    currentNotification: null,
}

export function notificationsReducer (state = initialNotifications, action) {
    switch (action.type) {
        case VISIBLE_LIST:
            return {...state, visibleList: action.payload}
        case NOTIFICATIONS_LIST:
            return {...state, notificationsList: action.payload}
        case VISIBLE_NOTIFICATION:
            return {...state, visibleNotification: action.payload}
        case CURRENT_NOTIFICATION:
            return {...state, currentNotification: action.payload}
        default:
            return state
    }
}
