import { commonApi } from '../../../../store/commonApi';

export const searchParams = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchParams: builder.query({
      query: (path: string) => `/${path}`,
      providesTags: (result, error, arg) => {
        const key = arg.split('?')[0];
        return [{ type: 'SearchParams', id: key }];
      },
    }),
  }),
});

export const { useGetSearchParamsQuery } = searchParams;
