import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from '../../../store/rootReducer';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { yambookApi } from '../../../store/yambookApi';
import { KeycloakApp } from '../../../utils/keycloak/KeycloakApp';

const ErrorAction = React.memo(({ onFinishEnd }: { onFinishEnd: Function }) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const url = `https://backtaskboard.weldnet.ru/api`;

  const token = KeycloakApp.token;
  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      fileList: values.fileList?.fileList?.map((file) => file.response.data.id),
      memberIdList: [1, 4, 7],
      description: `Ссылка
-----------
${window.location.href}

Сообщение
-----------
${values.description ?? 'Отсутствует'}

Автор
-----------

${profile?.fullName}

Браузер
-----------
${navigator.userAgent}`,
      labelList: values.labelList ? [values.labelList] : undefined,
    };

    if (KeycloakApp?.isTokenExpired(5)) {
      await KeycloakApp.updateToken(5);
    }

    axios
      .post(`${url}/tasks`, data, {
        headers: {
          authorization: 'Bearer ' + token,
        },
      })
      .then(() => {
        notification.success({ message: 'Сообщение успешно отправлено' });
        yambookApi.util?.invalidateTags([
          { type: 'Yambook', id: 'getSupportTask' },
        ]);
        onFinishEnd();
      })
      .catch(() => {
        notification.error({ message: 'Ошибка' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const options = [
    { value: 162, label: 'Ошибка на сайте' },
    { value: 163, label: 'Предложение' },
    { value: 166, label: 'Вопрос' },
    { value: 161, label: 'Другое' },
  ];

  const labelOptions = [
    { value: 70, label: 'Очень срочно' + ' 🔥' },
    { value: 71, label: 'Срочно' },
    { value: 72, label: 'Не срочно' },
  ];

  const uploadProps: UploadProps = {
    name: 'file',
    action: `https://backtaskboard.weldnet.ru/api/filesystem`,
    headers: {
      authorization: 'Bearer ' + (token ?? ''),
    },
    defaultFileList: [],
    multiple: true,
    onRemove: (file) => {
      axios
        .delete(`${url}/filesystem/${file.response.data.id}`, {
          headers: {
            authorization: 'Bearer ' + token,
          },
        })
        .catch(() => notification.error({ message: 'Ошибка удаления файла' }));
    },
  };

  return (
    <Form layout={'vertical'} onFinish={onFinish}>
      <Form.Item
        label={'Наименование'}
        name={'title'}
        rules={[{ required: true, message: '' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={'Категория'}
        name={'columnId'}
        rules={[{ required: true, message: '' }]}
      >
        <Select options={options} />
      </Form.Item>
      <Form.Item label={'Приоритет'} name={'labelList'}>
        <Select options={labelOptions} />
      </Form.Item>
      <Form.Item label={'Описание'} name={'description'}>
        <TextArea autoSize={{ minRows: 4 }} />
      </Form.Item>
      <Form.Item label={'Файлы'} name={'fileList'}>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType={'submit'}
        >
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );
});
ErrorAction.displayName = 'ErrorAction';
export default ErrorAction;
