import React from 'react';
import AbstractElementF from '../../../../../utils/Components/AbstractElement/AbstractElementF';
import { deviceToEmployeeData } from '../DeviceToEmployeeData';
import { DeviceToEmployee } from '../../SetToDevices/DeviceToEmployee.type';
import { getDescriptionItems } from '../../../../../utils/Base/helpers/getDescriptionItems';
import { initialColumns } from '../DeviceToEmployeeExtra';
import { tabsList } from '../../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { Button, Table } from 'antd';
import { getInitialColumns } from '../../Devices/DevicesExtra';
import { useSendInArchiveDeviceToEmployee } from '../../helpers/useSendInArchiveDeviceToEmployee';
import { FolderOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd/es';
import { useGetAbstractContent } from '../../../../../utils/Base/hooks/useGetAbstractContent';
import { useParams } from 'react-router-dom';
import { deviceToEmployeePermission } from '../DeviceToEmployee.permission';

const OnceDeviceToEmployee = React.memo(() => {
  const { sendInArchive } = useSendInArchiveDeviceToEmployee();
  const { id } = useParams();
  const content = useGetAbstractContent<DeviceToEmployee>(
    deviceToEmployeeData.apiMethod,
    'deviceToEmployee',
    id
  );
  const tabs: tabsList = [
    {
      title: 'Устройства',
      component: (content: DeviceToEmployee) => {
        const data = content.setOfDevice.deviceSet;
        return (
          <Table
            dataSource={data}
            columns={getInitialColumns().filter((el) => el.visible)}
            rowKey={'id'}
            pagination={false}
            size={'small'}
          />
        );
      },
      extra: null,
    },
  ];
  return (
    <AbstractElementF
      apiMethod={deviceToEmployeeData.apiMethod}
      dataListName={'deviceToEmployee'}
      pageHeaderList={{
        pass: (record: DeviceToEmployee) => record.employee.fullName,
        title: 'Комплект сотрудника',
      }}
      editPermission={deviceToEmployeePermission.update}
      tabs={tabs}
      pageExtra={[
        <Tooltip
          title={
            content?.isArchived ? 'Вернуть из архива' : 'Отправить в архив'
          }
        >
          <Button
            type={'primary'}
            icon={<FolderOutlined />}
            onClick={() =>
              content
                ? sendInArchive({
                    id: +id!,
                    employeeId: content.employee.id,
                    isArchived: !content.isArchived,
                  })
                : undefined
            }
          />
        </Tooltip>,
      ]}
      descriptions={getDescriptionItems(initialColumns)}
    />
  );
});
OnceDeviceToEmployee.displayName = 'OnceDeviceToEmployee';
export default OnceDeviceToEmployee;
