import React, { CSSProperties } from 'react';
import { Button, Card, Drawer, Space, Switch } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { ColumnDragNDrop, DragNDropDrawerProps } from './DragNDropDrawer.type';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDraggableProps,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from '../../../store/rootReducer';
import { AbstractColumns } from '../AbstractClass/AbstractClassTypes';
import { updateTableSettings } from '../../../store/reducers/AbstractColumnsSlice';

const DragNDropDrawer = ({
  isVisibleDrawer,
  setIsVisibleDrawer,
  initialColumns,
  columnsName,
  incrementIndex,
}: DragNDropDrawerProps) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const { settings } = useAppSelector((state) => state.abstractColumnsSlice);
  const dispatch = useAppDispatch();

  const formatColumns = () => {
    return settings[columnsName] ?? initialColumns;
  };

  const columns = formatColumns();

  const changeColumns = (columns: AbstractColumns) => {
    const tableColumns: typeof settings = {
      ...settings,
      [columnsName]: columns,
    };

    dispatch(
      updateTableSettings({
        tableColumns,
        accountProfileId: profile!.accountProfileData!.accountProfileId,
      })
    );
    incrementIndex?.();
  };

  const reorder = (
    list: AbstractColumns,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderColumns = reorder(
      columns,
      result.source.index,
      result.destination.index
    );

    changeColumns(reorderColumns);
  };

  const updateColumnProperty = (
    item: ColumnDragNDrop,
    property: keyof ColumnDragNDrop,
    value: any
  ) => {
    const idx = columns.findIndex((el) => el.key === item.key);
    const clonedColumns = [...columns];
    clonedColumns[idx] = { ...columns[idx], [property]: value };
    changeColumns(clonedColumns);
  };

  const changeVisible = (item: ColumnDragNDrop, visible: boolean) =>
    updateColumnProperty(item, 'visible', visible);
  const changePin = (item: ColumnDragNDrop, isPin: boolean) =>
    updateColumnProperty(item, 'fixed', isPin ? 'left' : undefined);

  const onReset = () => {
    changeColumns(initialColumns);
  };

  const grid = 2;

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggableProvidedDraggableProps['style']
  ): CSSProperties => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'grey',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
  });

  return (
    <Drawer
      title={'Настройка таблицы'}
      open={isVisibleDrawer}
      onClose={setIsVisibleDrawer}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Button
          onClick={onReset}
          type="primary"
          danger
          style={{ width: '100%', marginBottom: 5 }}
        >
          Сбросить
        </Button>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {columns.map((item, index) => (
                <Draggable key={item.key} draggableId={item.key} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      data-testid={`dragItem${index}`}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Card
                        title={item.title as string}
                        size={'small'}
                        extra={
                          <Space>
                            <Switch
                              checkedChildren={<EyeOutlined />}
                              unCheckedChildren={<EyeInvisibleOutlined />}
                              checked={item.visible}
                              onChange={(value) => changeVisible(item, value)}
                            />
                            <Button
                              icon={
                                <PushpinOutlined
                                  data-testid={'pushpin-btn'}
                                  style={{
                                    color: item.fixed ? '#1677ff' : '',
                                  }}
                                />
                              }
                              onClick={() => changePin(item, !item.fixed)}
                              type={'text'}
                            />
                          </Space>
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Drawer>
  );
};
DragNDropDrawer.displayName = 'DragNDropDrawer';
export default DragNDropDrawer;
