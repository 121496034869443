import { NewPageData } from '../../../../AppTypes';
import { API_URLS } from '../../../../utils/API/urls';

export const deviceToEmployeeData: NewPageData = {
  apiMethod: API_URLS.DEVICE_TO_EMPLOYEE,
  dataListName: 'deviceToEmployeePage',
  idName: 'id',
  pageHeaderTitle: 'Комплекты сотрудников',
  tabNameField: 'employee.fullName',
  columnsName: 'DeviceToEmployeeTable',
};
