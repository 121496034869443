export const getCurrentLink = (apiMethod: string) => {
  const params = new URLSearchParams(window.location.search);

  if (!params.has('page')) {
    params.append('page', '0');
  }

  if (!params.has('size')) {
    params.append('size', '10');
  }

  return apiMethod.split('?')[0] + `?${params}`;
};
