import { ResponseData } from '../../utils/API/APITypes';
import { Candidate } from '../../components/Pages/Сandidates/Types/Candidates';

const SET_DATA_MAIN_PAGE = 'SET_DATA_MAIN_PAGE';
const SET_CONTENT_MAIN_PAGE = 'SET_CONTENT_MAIN_PAGE';

export declare type DataMainPage = {
  success: boolean;
  DataList: ResponseData<Candidate>;
};

export declare type MainPageReducer = {
  data: DataMainPage | null;
  content: Array<Candidate> | null;
};

/**
 * @param {DataMainPage} data
 * @return {any}
 */
export function setDataMainPage(data) {
  return {
    type: SET_DATA_MAIN_PAGE,
    payload: data,
  };
}

/**
 * @param {Array<DataMainPage>} data
 * @return {any}
 */
export function setContentMainPage(data) {
  return {
    type: SET_CONTENT_MAIN_PAGE,
    payload: data,
  };
}

const initialState: MainPageReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function mainPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_MAIN_PAGE:
      return { ...state, data: action.payload };
    case SET_CONTENT_MAIN_PAGE:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
