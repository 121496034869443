import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { abstractApi } from '../api/abstract.api';
import { TabType } from '../../components/Layouts/LayoutTypes';
import { MAIN_TAB } from '../../components/Layouts/Components/helpers/mainTab';
import API from '../../utils/API/API_FORMATION';

export declare type WorkplaceTabsReducer = {
  tabs: Array<TabType>;
  activeKey: string;
  currentTab: TabType | null;
  removeKey: string;
  latestRequestId: null | string;
};

const initialState: WorkplaceTabsReducer = {
  tabs: [],
  activeKey: window.location.pathname,
  currentTab: null,
  removeKey: '',
  latestRequestId: null,
};

type ResponseAccountProfile = {
  tabs_settings: string;
};
export const fetchAccountTabs = createAsyncThunk(
  'data/fetchData',
  async ({
    tabs,
    accountProfileId,
  }: {
    tabs: TabType[];
    accountProfileId: number;
  }) => {
    const response = await API.put<ResponseAccountProfile>(
      `account-profile/tabs/${accountProfileId}`,
      {
        tabsSetting: JSON.stringify(tabs),
      }
    );
    return response.data;
  }
);

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentTabName: (state, action) => {
      const [link, tabName] = action.payload;
      const currentTab = state.tabs.find((tab) => tab.key === link);
      if (currentTab) currentTab.title = tabName;
    },
    setInitialTab: (state, action: PayloadAction<TabType[]>) => {
      state.tabs = action.payload.length ? action.payload : [MAIN_TAB];
      // если появляется новый таб, текущий таб невозможно установить пока не отработает setInitialTab
      if (state.activeKey !== state.currentTab?.key) {
        state.currentTab =
          state.tabs.find((tab) => tab.key === state.activeKey) ?? MAIN_TAB;
      }
    },
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
      state.currentTab = state.tabs.find((tab) => tab.key === action.payload)!;
    },
    setRemoveKey: (state, action) => {
      state.removeKey = action.payload;
    },
    additionalValues: (
      state,
      action: PayloadAction<
        Array<
          | {
              action: 'add';
              key: string;
              value: any;
            }
          | {
              action: 'remove';
              key: string;
            }
        >
      >
    ) => {
      const { activeKey } = state;
      const params = action.payload;
      const id = state.tabs.findIndex((tab) => tab.key === activeKey);
      if (id !== -1) {
        // eslint-disable-next-line guard-for-in
        for (const i in params) {
          const param = params[i];
          if (param !== null && params !== undefined) {
            const { action, key } = param;
            if (action === 'remove') delete state.tabs[id][key];
            if (action === 'add') state.tabs[id][key] = param.value;
          }
        }
        state.currentTab = state.tabs[id] ?? null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountTabs.pending, (state, action) => {
        state.latestRequestId = action.meta.requestId;
        state.tabs = action.meta.arg.tabs;
        if (state.activeKey !== state.currentTab?.key) {
          state.currentTab =
            state.tabs.find((tab) => tab.key === state.activeKey) ?? MAIN_TAB;
        }
      })
      .addCase(fetchAccountTabs.fulfilled, (state, action) => {
        if (action.meta.requestId === state.latestRequestId) {
          state.tabs = action.payload.tabs_settings
            ? JSON.parse(action.payload.tabs_settings)
            : [MAIN_TAB];
        }
      })
      .addMatcher(
        abstractApi.endpoints.getAbstractData.matchFulfilled,
        (state, { meta }) => {
          const search = meta.arg.originalArgs.split('?').at(-1);
          if (state.currentTab && search?.includes('page')) {
            const idx = state.tabs.findIndex(
              (tab) => tab.key === state.currentTab?.key
            );
            if (idx !== -1) {
              state.tabs[idx].searchParams = search;
              state.currentTab.searchParams = search;
            }
          }
        }
      );
  },
});

export default slice.reducer;

export const {
  additionalValues,
  setRemoveKey,
  setActiveKey,
  setInitialTab,
  setCurrentTabName,
} = slice.actions;
