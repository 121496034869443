import { AuthProfile } from '../../components/Pages/Admin/Accounts/type';
import uniq from 'lodash/uniq';

const LOGGED_IN = 'LOGGED_IN';
const LOG_OUT = 'LOG_OUT';
const BACKGROUND_URL = 'BACKGROUND_URL';
const AVATAR_URL = 'AVATAR_URL';
const IS_SOCKET_CONNECT = 'IS_SOCKET_CONNECT';
const SET_IS_EDIT_DESIGN = 'SET_IS_EDIT_DESIGN';
const SET_IMG_SRC = 'SET_IMG_SRC';
const SET_DARK_THEME = 'SET_DARK_THEME';
const SET_BIG_MODE = 'SET_BIG_MODE';

type Initial = {
  loggedIn: boolean | null;
  profile: AuthProfile | null;
  isSocketConnect: false;
  backgroundUrl: null;
  avatarUrl: null;
  isEditDesign: boolean;
  permissionList: string[];
  imageSrc: { [n: number]: string };
  darkTheme: boolean;
  bigMode: boolean;
};

export function updateState(data) {
  return {
    type: LOGGED_IN,
    payload: data,
  };
}

/**
 * Хранить информацию о src картиночек
 * @param data
 */
export function setImgSrc(data: [number, string]) {
  return {
    type: SET_IMG_SRC,
    payload: data,
  };
}

/**
 * Запись информации профиля при входе в учётную запись
 * @param {any} data
 * @return {any}
 */
export function updateSocketConnect(data) {
  return {
    type: IS_SOCKET_CONNECT,
    payload: data,
  };
}

/**
 * Видимость окна редактирование профиля
 * @param {any} data
 * @return {any}
 */
export function setIsEditDesign(data) {
  return {
    type: SET_IS_EDIT_DESIGN,
    payload: data,
  };
}

/**
 * @param {any} data
 * @return {any}
 */
export function updateBackground(data) {
  return {
    type: BACKGROUND_URL,
    payload: data,
  };
}
/**
 * @param {any} data
 * @return {any}
 */
export function updateAvatar(data) {
  return {
    type: AVATAR_URL,
    payload: data,
  };
}

/**
 * @param {any} data
 * @return {any}
 */
export function setBigMode(data) {
  return {
    type: SET_BIG_MODE,
    payload: data,
  };
}

/**
 * Запись информации о правах
 * @param {any} data
 * @return {any}
 */
export function setDarkTheme(data) {
  return {
    type: SET_DARK_THEME,
    payload: data,
  };
}

const initialAuth: Initial = {
  loggedIn: null,
  profile: null,
  isSocketConnect: false,
  backgroundUrl: null,
  avatarUrl: null,
  isEditDesign: false,
  permissionList: [],
  imageSrc: {},
  darkTheme: !!localStorage.getItem('darkTheme'),
  bigMode: !!localStorage.getItem('bigMode'),
};

export function authReducer(state = initialAuth, action): Initial {
  switch (action.type) {
    case LOGGED_IN:
      const { roleSet, ...withoutRole } = action.payload;
      const permissions: string[] = roleSet.flatMap((el) =>
        el.permissionSet.map((el) => el.subject)
      );
      const permissionList = uniq(permissions);
      return {
        ...state,
        loggedIn: true,
        profile: { ...withoutRole, roleSet: roleSet.map((el) => el.title) },
        permissionList,
      };
    case LOG_OUT:
      return { ...state, loggedIn: false, profile: null };
    case IS_SOCKET_CONNECT:
      return { ...state, isSocketConnect: action.payload };
    case BACKGROUND_URL:
      return { ...state, backgroundUrl: action.payload };
    case AVATAR_URL:
      return { ...state, avatarUrl: action.payload };
    case SET_IS_EDIT_DESIGN:
      return { ...state, isEditDesign: action.payload };
    case SET_BIG_MODE:
      return { ...state, bigMode: action.payload };
    case SET_IMG_SRC:
      const [id, src] = action.payload;
      const obj = { ...state.imageSrc, [id]: src };
      return { ...state, imageSrc: obj };
    case SET_DARK_THEME:
      return { ...state, darkTheme: action.payload };
    default:
      return state;
  }
}
