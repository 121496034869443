import React, { useEffect, useMemo, useState } from 'react';
import { Card, Descriptions, Space, Table } from 'antd/es';
import { KanbanTableProps } from '../AbstractClassTypes';
import Popup from '../../Popup/Popup';
import groupBy from 'lodash/groupBy';

const KanbanTable = React.memo(
  ({
    data,
    groupBy: group,
    descriptions,
    pagination,
    popupList,
    cardTitleName,
    setCurrentRow,
    onChange,
    columnNameList,
    layout,
  }: KanbanTableProps) => {
    const dataSource = useMemo(() => {
      const groupValue = groupBy(data, group);
      if (groupValue.undefined) {
        groupValue['Н/Д'] = [
          ...(groupValue['Н/Д'] ?? []),
          ...groupValue.undefined,
        ];
        delete groupValue.undefined;
      }
      return groupValue;
    }, [data]);
    const [pageXy, setPageXy] = useState({ pageX: 0, pageY: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const rawDataSource = useMemo(() => {
      const props = {};
      Object.keys(dataSource).forEach((key) => {
        props[key] = dataSource[key];
      });
      return [
        {
          key: 'first',
          ...props,
        },
      ];
    }, [dataSource]);

    const handleContextMenu = (e, record) => {
      e.preventDefault();
      setPageXy({ pageX: e.clientX, pageY: e.clientY });
      setCurrentRow(record);
      setPopupVisible(true);
    };

    useEffect(() => {
      if (popupVisible) {
        document.addEventListener('click', function onClickOutside() {
          setPopupVisible(false);
          document.removeEventListener(`click`, onClickOutside);
        });
      }
    }, [popupVisible]);

    const getFormatColumns = () => {
      return columnNameList?.map((key) => {
        return {
          title: key,
          key: key,
          align: 'center',
          dataIndex: key,
          width: `${100 / columnNameList.length}%`,
          render: (cards) => {
            return (
              <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
              >
                {cards?.map((card) => (
                  <Card
                    title={card[cardTitleName]}
                    key={card.toString()}
                    onContextMenu={(e) => handleContextMenu(e, card)}
                    size="small"
                  >
                    <Descriptions size="small" layout={layout ?? 'horizontal'}>
                      {descriptions.map((desc) => (
                        <Descriptions.Item
                          label={<b>{desc.label}</b>}
                          key={desc.fieldName}
                          span={desc.span}
                        >
                          {desc.render
                            ? desc.render(card[desc.fieldName], card)
                            : card[desc.fieldName] ?? '-'}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </Card>
                ))}
              </Space>
            );
          },
        };
      });
    };
    const formatColumns = useMemo(getFormatColumns, [dataSource]);
    return (
      <>
        <Table
          // @ts-ignore
          columns={formatColumns}
          dataSource={rawDataSource}
          pagination={pagination}
          className={'kanban-table'}
          onChange={onChange}
          rowClassName="kanban-row"
        />
        <Popup {...pageXy} visible={popupVisible} list={popupList} />
      </>
    );
  }
);
KanbanTable.displayName = 'KanbanTable';
export default KanbanTable;
