import React, { useState } from 'react';
import AbstractElementF from '../../../../utils/Components/AbstractElement/AbstractElementF';
import { API_URLS } from '../../../../utils/API/urls';
import { descriptions } from '../../Employee/OnceEmployee/OnceRecordExtra';
import { tabsList } from '../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { Button, Space, Table, Tag } from 'antd';
import { Modal, Popconfirm, Tooltip } from 'antd/es';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import FormEmployeeDevices from '../components/FormEmployeeDevices';
import { useParams } from 'react-router-dom';
import { useInvalidateAbstractData } from '../../../../utils/Base/hooks/useInvalidateAbstractData';
import { employeeDevicesData } from '../employeeDevicesData';
import { Employee } from '../../Employee/type';
import { initialColumns } from '../SetToDevices/SetToDeviceExtra';
import { SetToDevice } from '../SetToDevices/SetToDevice.type';
import { getInitialColumns } from '../Devices/DevicesExtra';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import API from '../../../../utils/API/API';
import { FolderOutlined } from '@ant-design/icons';
import { useSendInArchiveDeviceToEmployee } from '../helpers/useSendInArchiveDeviceToEmployee';
import { employeePermission } from '../../Employee/Employee.permission';
import { useAppSelector } from '../../../../store/rootReducer';
import { deviceToEmployeePermission } from '../DeviceToEmployee/DeviceToEmployee.permission';

const OnceEmployeeDevices = React.memo(() => {
  const { id } = useParams();
  const [isAddComplect, setIsAddComplect] = useState(false);
  const invalidate = useInvalidateAbstractData();
  const invalidateEmployee = () =>
    invalidate(employeeDevicesData.apiMethod, id);
  const { sendInArchive, isArchiveLoading } =
    useSendInArchiveDeviceToEmployee();
  const { permissionList } = useAppSelector((state) => state.authReducer);

  const onDelete = (id) => {
    return API.delete(`${API_URLS.DEVICE_TO_EMPLOYEE}/${id}`).then(() =>
      invalidateEmployee()
    );
  };

  const tabs: tabsList = [
    {
      title: 'Комплекты оборудования',
      component: (content: Employee) => {
        const data = content.deviceToEmployeeList.map((el) => ({
          ...el.setOfDevice,
          recordId: el.id,
          isArchived: el.isArchived,
        }));
        return (
          <Table
            dataSource={data}
            columns={[
              ...initialColumns,
              {
                title: 'В архиве',
                key: 'isArchived',
                dataIndex: 'isArchived',
                align: 'center',
                render: (item) => <Tag>{item ? 'Да' : 'Нет'}</Tag>,
              },
              {
                title: '',
                width: 50,
                dataIndex: 'recordId',
                align: 'center',
                render: (
                  item,
                  record: Employee['deviceToEmployeeList'][number]
                ) => (
                  <Space>
                    <Tooltip
                      title={
                        record.isArchived
                          ? 'Вернуть из архива'
                          : 'Отправить в архив'
                      }
                    >
                      <Button
                        icon={<FolderOutlined />}
                        size={'small'}
                        type={'text'}
                        onClick={() =>
                          sendInArchive({
                            id: item,
                            isArchived: !record.isArchived,
                            employeeId: +id!,
                          })
                        }
                        loading={isArchiveLoading}
                      />
                    </Tooltip>
                    <Popconfirm
                      title={'Вы точно хотите удалить этот набор устройств?'}
                      onConfirm={() => onDelete(item)}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        size={'small'}
                        type={'text'}
                        danger
                      />
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
            size={'small'}
            rowKey={'recordId'}
            pagination={false}
            expandable={{
              expandedRowRender: (record: SetToDevice) => (
                <Table
                  dataSource={record.deviceSet}
                  columns={getInitialColumns().filter((el) => el.visible)}
                  rowKey={'id'}
                  pagination={false}
                  size={'small'}
                />
              ),
            }}
          />
        );
      },
      extra: () =>
        permissionList.includes(deviceToEmployeePermission.create) ? (
          <Tooltip title={'Добавить комплект оборудования'}>
            <Button
              onClick={() => setIsAddComplect(true)}
              type={'primary'}
              icon={<PlusOutlined />}
            ></Button>
          </Tooltip>
        ) : null,
    },
  ];
  const onFinishAdd = () => {
    invalidateEmployee();
    setIsAddComplect(false);
  };
  return (
    <>
      <AbstractElementF
        apiMethod={API_URLS.EMPLOYEE}
        dataListName={'employee'}
        descriptions={descriptions}
        editPermission={employeePermission.update}
        tabs={tabs}
        pageHeaderList={{
          pass: 'fullName',
          title: 'Сотрудник',
        }}
      />
      <Modal
        destroyOnClose
        title={'Добавить комплект оборудования'}
        footer={null}
        open={isAddComplect}
        onCancel={() => setIsAddComplect(false)}
      >
        <FormEmployeeDevices employeeId={+id!} onFinishAdd={onFinishAdd} />
      </Modal>
    </>
  );
});
OnceEmployeeDevices.displayName = 'OnceEmployeeDevices';
export default OnceEmployeeDevices;
