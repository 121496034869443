import { PermissionPage } from '../../AppTypes';

export const notificationPermission: PermissionPage & { completed: string } = {
  create: 'createNotification',
  update: 'updateNotification',
  all: 'getAllNotification',
  delete: 'deleteNotification',
  one: '',
  completed: 'completedNotification',
};
