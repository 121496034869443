import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_WORK_DIRECTION = 'SET_DATA_WORK_DIRECTION';
const SET_CONTENT_WORK_DIRECTION = 'SET_CONTENT_WORK_DIRECTION';

export declare type WorkDirection = {
  id: number;
  title: string;
  description: string | null;
  color: string | null;
};

export declare type DataWorkDirection = {
  success: boolean;
  DataList: ResponseData<WorkDirection>;
};

export declare type WorkDirectionReducer = {
  data: DataWorkDirection | null;
  content: Array<WorkDirection> | null;
};

/**
 * @param {DataWorkDirection} data
 * @return {any}
 */
export function setDataWorkDirection(data) {
  return {
    type: SET_DATA_WORK_DIRECTION,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkDirection>} data
 * @return {any}
 */
export function setContentWorkDirection(data) {
  return {
    type: SET_CONTENT_WORK_DIRECTION,
    payload: data,
  };
}

const initialState: WorkDirectionReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function workDirectionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WORK_DIRECTION:
      return { ...state, data: action.payload };
    case SET_CONTENT_WORK_DIRECTION:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
