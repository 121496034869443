import { useGetAbstractDataQuery } from '../../../store/api/abstract.api';

export const useGetAbstractContent = <T = any>(
  apiMethod: string,
  dataListName: string,
  id?: string | number
): T | undefined => {
  const path = apiMethod.replace('api/', '');
  const { data } = useGetAbstractDataQuery(`${path}${id ? `/${id}` : ''}`);
  return data?.[dataListName];
};
