import React from 'react';
import { Button, Result } from 'antd/es';
import { KeycloakApp } from '../../../utils/keycloak/KeycloakApp';

const Forbidden = React.memo(
  ({
    status,
  }: {
    status: 404 | 403 | 500 | 'NetworkError';
    title?: string;
  }) => {
    return (
      <Result
        status={status === 'NetworkError' ? 'error' : status}
        title={status}
        subTitle={
          status === 'NetworkError'
            ? 'Не удалось подключиться к серверу. Пожалуйста, проверьте ваше интернет-соединение и попробуйте снова.'
            : 'Отсутствует доступ к сайту'
        }
        extra={
          typeof status === 'number' ? (
            <Button onClick={() => KeycloakApp.logout()}>
              Выйти из weldnet
            </Button>
          ) : undefined
        }
      />
    );
  }
);
Forbidden.displayName = 'Forbidden';
export default Forbidden;
