import {
  addDownloadFile,
  deleteDownloadFile,
} from '../../../../store/reducers/AbstractClassData';
import { getCurrentLink } from './getCurrentLink';
import { useDispatch } from 'react-redux';
import API from '../../../API/API';

export const useDownloadFile = () => {
  const dispatch = useDispatch();
  const saveDataNoDownload = (serverFilename: string, path: string) => {
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a['style'] = 'display: none';
    const baseUrl = process.env.REACT_APP_EXCEL_SERVER;

    const url = `${baseUrl}/reports/${serverFilename}`;
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    dispatch(deleteDownloadFile(path));
  };

  return (path: string, apiMethod: string) => {
    const controller = new AbortController();

    dispatch(addDownloadFile(path, controller));

    const params = new URLSearchParams(getCurrentLink(apiMethod).split('?')[1]);

    params.delete('page');
    params.delete('size');

    API.get(`${path}`, { params, signal: controller.signal })
      .then((response) => {
        saveDataNoDownload(response.data, path);
      })
      .catch(() => {
        dispatch(deleteDownloadFile(path));
      });
  };
};
