import React from 'react';
import AbstractElementF from '../../../../../utils/Components/AbstractElement/AbstractElementF';
import { API_URLS } from '../../../../../utils/API/urls';
import SetToDeviceForm from '../components/SetToDeviceForm';
import { tabsList } from '../../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { Table } from 'antd';
import { SetToDevice } from '../SetToDevice.type';
import { getInitialColumns } from '../../Devices/DevicesExtra';
import { setToDevicePermission } from '../SetToDevice.permission';

const OnceSetToDevice = React.memo(() => {
  const tabs: tabsList = [
    {
      title: 'Устройства',
      component: (content: SetToDevice) => (
        <Table
          dataSource={content.deviceSet}
          columns={getInitialColumns().filter((el) => el.visible)}
          pagination={false}
          size={'small'}
        />
      ),
      extra: null,
    },
  ];
  return (
    <AbstractElementF
      apiMethod={API_URLS.SET_TO_DEVICES}
      dataListName={'SetOfDevice'}
      pageHeaderList={{ pass: 'number', title: 'Номер набора' }}
      editComponent={<SetToDeviceForm type={'edit'} />}
      editPermission={setToDevicePermission.update}
      editModalWidth={'90vw'}
      tabs={tabs}
    />
  );
});
OnceSetToDevice.displayName = 'OnceSetToDevice';
export default OnceSetToDevice;
