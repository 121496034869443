import React from 'react';
import CollapsibleModal from '../../../CollapsibleModal/CollapsibleModal';
import { AbstractTableProps } from '../type';
import { useUpdateAbstractDataMutation } from '../../../../store/api/abstract.api';
import { notification } from 'antd';
import { getCurrentLink } from '../helpers/getCurrentLink';

const AbstractEdit = React.memo(
  ({
    isEdit,
    setIsEdit,
    currentRow,
    EditComponent,
    editModalWidth,
    apiMethod,
    getFilteredSearchFields,
    idName,
  }: {
    isEdit: boolean;
    currentRow: any;
    idName: string;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    EditComponent: React.ReactNode;
    editModalWidth?: number | string;
    apiMethod: string;
    getFilteredSearchFields: AbstractTableProps['getFilteredSearchFields'];
  }) => {
    const [onFinishEdit, { isLoading: loading }] =
      useUpdateAbstractDataMutation();
    const path = getCurrentLink(apiMethod).replace('api/', '');

    const editComponent = (children) => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          // @ts-ignore
          currentRow: currentRow,
          onFinishEdit: (value) =>
            onFinishEdit({
              path: path,
              body: value,
              id: currentRow[idName],
            }).then((res) => {
              if ('error' in res) {
                // @ts-ignore
                notification.error({ message: res.error.data?.error ?? '' });
                // @ts-ignore
                throw Error(res.error.data.error);
              }
              setIsEdit(false);
              getFilteredSearchFields?.(window.location.search);
            }),
          isFetching: loading,
        });
      }
    };

    return (
      <CollapsibleModal
        visible={isEdit}
        destroyOnClose={true}
        onCancel={() => {
          setIsEdit(false);
        }}
        title={'Редактирование записи'}
        width={editModalWidth}
      >
        {editComponent(EditComponent)}
      </CollapsibleModal>
    );
  }
);
AbstractEdit.displayName = 'AbstractEdit';
export default AbstractEdit;
