import { NewPageData } from '../../../AppTypes';
import { API_URLS } from '../../../utils/API/urls';

export const employeeDevicesData: NewPageData = {
  apiMethod: API_URLS.EMPLOYEE,
  dataListName: 'employeeList',
  idName: 'id',
  pageHeaderTitle: 'Сотрудники',
  tabNameField: 'fullName',
  columnsName: 'EmployeeDevicesTable',
};
