import React from 'react';
import AbstractElementF from '../../../../../utils/Components/AbstractElement/AbstractElementF';
import { API_URLS } from '../../../../../utils/API/urls';
import { getDescriptionItems } from '../../../../../utils/Base/helpers/getDescriptionItems';
import { getInitialColumns } from '../DevicesExtra';
import { getInitialColumns as columnsComponents } from '../../ComponentsDevice/ComponentsExtra';
import { tabsList } from '../../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { Table } from 'antd';
import { Devices } from '../Devices.type';
import DevicesForm from '../components/DevicesForm';
import { devicePermission } from '../Device.permission';

const OnceDevices = React.memo(() => {
  const tabs: tabsList = [
    {
      title: 'Компоненты',
      component: (content: Devices) => (
        <Table
          dataSource={content.componentsSet}
          columns={columnsComponents()}
          size={'small'}
          pagination={false}
        />
      ),
      extra: null,
    },
  ];
  return (
    <AbstractElementF
      apiMethod={API_URLS.DEVICES}
      dataListName={'davice'}
      pageHeaderList={{ pass: 'name', title: 'Устройство' }}
      editPermission={devicePermission.update}
      editComponent={<DevicesForm type={'edit'} />}
      editModalWidth={'1000px'}
      descriptions={getDescriptionItems(getInitialColumns())}
      tabs={tabs}
    />
  );
});
OnceDevices.displayName = 'OnceDevices';
export default OnceDevices;
