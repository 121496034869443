import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Redirect = React.memo(() => {
  const [param] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SASS_URL}/hh/auth?code=${param.get('code')}`
      )
      .then((res) => {
        localStorage.setItem('hh_token', res.data.access_token);
        localStorage.setItem('hh_refresh_token', res.data.refresh_token);
        navigate('/vacancy');
      });
  }, []);
  return <></>;
});
Redirect.displayName = 'Redirect';
export default Redirect;
