import { SearchFieldsType } from '../../../../utils/Components/SearchPanel/types/SearchPanelTypes';
import { brandsData } from '../../../../utils/Components/Dictionary/extra/brandData';
import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { Employee } from '../../Employee/type';
import { renderDate } from '../../../../utils/Base/helpers/renderDate';

export const getSearchFields = (): SearchFieldsType[] => {
  return [
    {
      component: 'input',
      label: 'Рабочее состояние',
      name: 'workingCondition',
      placeholder: '',
      required: false,
      span: 6,
    },
    {
      component: 'select',
      label: 'Компания производитель',
      name: 'brandId',
      placeholder: '',
      required: false,
      span: 6,
      selectItem: [],
      dictionaryData: brandsData,
    },
  ];
};

export const getInitialColumns = (): AbstractColumns<Employee> => {
  return [
    {
      title: 'Наименование',
      key: 'title',
      dataIndex: 'title',
      align: 'center',
      visible: true,
    },
    {
      title: 'Серийный номер',
      key: 'serialNumber',
      dataIndex: 'serialNumber',
      align: 'center',
      visible: true,
    },
    {
      title: 'Год производства',
      key: 'yearOfProduction',
      dataIndex: 'yearOfProduction',
      align: 'center',
      visible: true,
    },
    {
      title: 'Рабочее состояние',
      key: 'workingCondition',
      dataIndex: 'workingCondition',
      align: 'center',
      visible: true,
    },
    {
      title: 'Компания производитель',
      key: 'brand',
      dataIndex: ['brand', 'title'],
      align: 'center',
      visible: true,
    },
    {
      title: 'Дата создания',
      key: 'createDate',
      dataIndex: ['createDate'],
      align: 'center',
      visible: true,
      render: renderDate,
    },
    {
      title: 'Описание',
      key: 'description',
      dataIndex: ['description'],
      align: 'center',
      visible: false,
    },
    {
      title: 'Комментарий',
      key: 'comment',
      dataIndex: ['comment'],
      align: 'center',
      visible: false,
    },
  ];
};

export const getSearchColumns = () =>
  getInitialColumns().filter((el) => el.visible);
