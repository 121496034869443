import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { Employee } from '../../Employee/type';

export const initialColumns: AbstractColumns<Employee> = [
  {
    title: 'Номер',
    key: 'number',
    dataIndex: 'number',
    align: 'center',
    visible: true,
  },
];
