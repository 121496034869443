import React from 'react';
import AbstractPage from '../../../../utils/Components/AbstractPage/AbstractPage';
import { devicesData } from './devicesData';
import { getPageHeaderExtra } from '../../Employee/EmployeeExtra';
import DevicesForm from './components/DevicesForm';
import { getInitialColumns, getSearchFields } from './DevicesExtra';
import { useDictionarySelectOptions } from '../../../../utils/Base/hooks/useDictionarySelectOptions';
import { brandsData } from '../../../../utils/Components/Dictionary/extra/brandData';
import { getPopupList } from '../../../../utils/Components/Popup/PopupExtra';
import { devicePermission } from './Device.permission';

const Devices = React.memo(() => {
  const brandsOptions = useDictionarySelectOptions({
    ...brandsData,
    idName: 'title',
  });
  return (
    <AbstractPage
      {...devicesData}
      columns={getInitialColumns()}
      searchFields={getSearchFields(brandsOptions)}
      editModalWidth={'1000px'}
      createModalWidth={'1000px'}
      AddComponent={<DevicesForm type={'add'} />}
      EditComponent={<DevicesForm type={'edit'} />}
      pageHeaderExtra={getPageHeaderExtra(devicePermission.create)}
      popupList={getPopupList(devicePermission)}
    />
  );
});
Devices.displayName = 'Devices';
export default Devices;
