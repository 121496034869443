import {
  citizenshipDictionaryData,
  organizationDictionaryData,
  workingMethodDictionaryData,
} from '../../../utils/Components/Dictionary/DictionaryExtra';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../utils/Components/AbstractClass/AbstractClassTypes';
import BookOutlined from '@ant-design/icons/BookOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import React from 'react';
import { Employee } from './type';
import { Tag } from 'antd/es';
import { locationEmployeeData } from '../../../utils/Components/Dictionary/extra/locationEmployee';
import { PopupList } from '../../../utils/Components/Popup/PopupTypes';
import { SearchFieldsType } from '../../../utils/Components/SearchPanel/types/SearchPanelTypes';

export const emptyTag = (
  <Tag color="red" style={{ fontSize: 9, padding: 0 }}>
    Отсутствует
  </Tag>
);

export const getSearchFields = (
  bond,
  positions,
  workplace,
  projectOptions,
  departments,
  workDirection,
  subWork
): SearchFieldsType[] => {
  return [
    {
      component: 'input',
      label: 'ФИО',
      name: 'fullName',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'Input',
      label: 'Email',
      name: 'email',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'Input',
      label: 'Номер телефона',
      name: 'phoneNumber',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'Input',
      label: 'Табельный номер',
      name: 'serviceNumber',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'select',
      label: 'Организация',
      name: 'organizationId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: organizationDictionaryData,
    },
    {
      component: 'select',
      label: 'Должность',
      name: 'positionId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: positions,
    },
    {
      component: 'select',
      label: 'Места работы',
      name: 'workplaceId',
      placeholder: '',
      required: false,
      span: 2,
      selectItem: workplace,
    },
    {
      component: 'select',
      label: 'Местоположение',
      name: 'locationEmployeeId',
      placeholder: '',
      required: false,
      span: 2,
      selectItem: [],
      dictionaryData: locationEmployeeData,
    },
    {
      component: 'select',
      label: 'Отдел',
      name: 'departmentIdList',
      placeholder: '',
      required: false,
      span: 4,
      selectMode: 'multiple',
      selectItem: departments,
    },
    {
      component: 'select',
      label: 'Направление работы',
      name: 'workDirectionIdList',
      placeholder: '',
      required: false,
      span: 4,
      selectMode: 'multiple',
      selectItem: workDirection,
    },
    {
      component: 'select',
      label: 'Поднаправление работы',
      name: 'subWorkDirectionIdList',
      placeholder: '',
      required: false,
      selectMode: 'multiple',
      span: 4,
      selectItem: subWork,
    },
    {
      component: 'select',
      label: 'Отдел, Направление и поднаправление',
      name: 'departmentsDirectionWorkBondId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: bond,
    },
    {
      component: 'select',
      label: 'Метод работы',
      name: 'workingMethodId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: workingMethodDictionaryData,
    },
    {
      component: 'select',
      label: 'Гражданство',
      name: 'citizenshipId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: citizenshipDictionaryData,
    },
    {
      component: 'select',
      label: 'Проекты',
      name: 'projectIdList',
      placeholder: '',
      required: false,
      selectMode: 'multiple',
      span: 4,
      selectItem: projectOptions,
    },
    {
      component: 'select',
      label: 'В архиве',
      name: 'isArchived',
      placeholder: '',
      required: false,
      span: 2,
      selectItem: [
        { item: 'Нет', value: 'false' },
        { item: 'Да', value: 'true' },
      ],
    },
  ];
};

export const getInitialColumns = (): AbstractColumns<Employee> => {
  return [
    {
      title: 'ФИО',
      key: 'fullName',
      dataIndex: 'fullName',
      align: 'center',
      visible: true,
    },
    {
      title: 'Табельный номер',
      key: 'serviceNumber',
      dataIndex: 'serviceNumber',
      align: 'center',
      visible: true,
      width: 100,
      render: (value) => <Tag>{value}</Tag>,
    },
    {
      title: 'Телефон и почта',
      key: 'email',
      dataIndex: 'email',
      align: 'center',
      visible: true,
      render: (value, record) => (
        <>
          <div>{record?.phoneNumber}</div>
          <div>{value}</div>
        </>
      ),
    },
    {
      title: 'Проекты',
      key: 'projectSet',
      dataIndex: 'projectSet',
      align: 'center',
      visible: true,
      width: 100,
      render: (value) => value?.map((el) => el.title).join(', '),
    },
    {
      title: 'Организация, должность, место работы',
      key: 'organizationData',
      dataIndex: ['organization', 'title'],
      align: 'center',
      visible: true,
      render: (value, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <span style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            <span>
              <b>Организация: </b>
            </span>
            <span>{value ?? emptyTag}</span>
          </span>
          <span style={{ textAlign: 'left' }}>
            <span>
              <b>Должность: </b>
            </span>
            <span>{record?.position?.title ?? emptyTag}</span>
          </span>
          <span style={{ textAlign: 'left' }}>
            <span>
              <b>Место работы: </b>
            </span>
            <span>{record?.workplace.title ?? emptyTag}</span>
          </span>
        </div>
      ),
    },
    {
      title: 'Отдел, направление и поднаправление',
      key: 'departmentsDirectionWorkBondData',
      dataIndex: 'departmentsDirectionWorkBond',
      align: 'center',
      visible: true,
      render: (value: Employee['departmentsDirectionWorkBond']) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <span style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            <span>
              <b>Отдел: </b>
            </span>
            <span>{value?.department?.title ?? emptyTag}</span>
          </span>
          <span style={{ textAlign: 'left' }}>
            <span>
              <b>Направление: </b>
            </span>
            <span>{value?.workDirection?.title ?? emptyTag}</span>
          </span>
          <span style={{ textAlign: 'left' }}>
            <span>
              <b>Поднаправление: </b>
            </span>
            <span>{value?.subWorkDirection?.title ?? emptyTag}</span>
          </span>
        </div>
      ),
    },
    {
      title: 'Метод работы',
      key: 'workingMethod',
      dataIndex: ['workingMethod', 'title'],
      align: 'center',
      width: 150,
      visible: true,
    },
    {
      title: 'Статус работы',
      key: 'workStatus',
      dataIndex: ['workStatus', 'title'],
      align: 'center',
      width: 150,
      visible: true,
    },
    {
      title: 'Гражданство',
      key: 'citizenship',
      dataIndex: ['citizenship', 'title'],
      align: 'center',
      width: 150,
      visible: true,
    },
  ];
};

//на это ссылается много компонентов, редактировать аккуратно
export const getPageHeaderExtra = (
  permissionCreate: string
): AbstractClassPageHeaderExtraButtonItem[] => [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    title: 'Создать',
    permission: permissionCreate,
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
];

export const getMobileEmployeeItem = (content: Employee) => ({
  title: content.fullName,
  extra: <Tag color={'blue'}>{content.serviceNumber}</Tag>,
  descriptions: [
    {
      title: 'Отдел',
      text: content.departmentsDirectionWorkBond?.department.title,
    },
    {
      title: 'Направление',
      text: content?.departmentsDirectionWorkBond?.workDirection?.title,
    },
    {
      title: 'Поднаправление',
      text: content?.departmentsDirectionWorkBond?.subWorkDirection?.title,
    },
    {
      title: 'Должность',
      text: content?.position?.title,
    },
  ],
});

export const getPopupList = (
  currentRow: Employee | undefined,
  setIsArchive: Function,
  onDelete: (record: Employee) => void
): PopupList => {
  const popup: PopupList = [];

  popup.push(
    {
      title: 'Подробнее',
      icon: <FileTextOutlined />,
      type: 'checkout',
      onClick: () => {
        console.log('More details');
      },
    },
    {
      title: currentRow?.isArchived ? 'Вернуть из архива' : 'В архив',
      icon: <BookOutlined />,
      type: 'getRecord',
      getRecord: () => {
        setIsArchive();
      },
    },
    {
      title: 'Редактировать',
      icon: <EditOutlined />,
      type: 'edit',
      onClick: () => {
        console.log('Edit');
      },
    },
    {
      title: 'Удалить',
      icon: <MinusOutlined />,
      type: 'getRecord',
      getRecord: onDelete,
    }
  );

  return popup;
};
