import React, { useState } from 'react';
import { Button, Form, notification, Upload } from 'antd/es';
import { UploadOutlined } from '@ant-design/icons/es/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/rootReducer';
import API from '../../../../utils/API/API';
import {
  updateAvatar,
  updateBackground,
} from '../../../../store/reducers/AuthReducer';
import { getFileImage } from '../../../../utils/Base/helpers/getFileImage';

const EditDesign = React.memo(({ onCancel }: { onCancel: Function }) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const { accountProfileData } = profile!;
  const [userBackgroundId, setBackgroundId] = useState<number | null>(
    accountProfileData?.userBackgroundId ?? null
  );
  const [avatarId, setAvatarId] = useState(
    accountProfileData?.userAvatarId ?? null
  );
  const { backgroundUrl, avatarUrl } = useAppSelector(
    (state) => state.authReducer
  );
  const dispatch = useDispatch();
  const upload = `${process.env.REACT_APP_PRODUCTION_URL}/api/file/upload`;
  const sendFile = (value: UploadChangeParam, setState) => {
    if (value.file.status === 'done') {
      setState(value.file.response.fileData.fileId);
    }
    if (value.file.status === 'removed') {
      setState(null);
    }
  };

  const onFinish = () => {
    const data = {
      accountProfileId: accountProfileData?.accountProfileId,
      userBackgroundId: userBackgroundId,
      userAvatarId: avatarId,
    };
    API.put('api/accounts/account-profile', data).then(() => {
      checkNewImage(userBackgroundId, 'userBackgroundId', updateBackground);
      checkNewImage(avatarId, 'userAvatarId', updateAvatar);
      // const rawProfile = cloneDeep(profile);
      // if (rawProfile?.accountProfileData) {
      //   rawProfile.accountProfileData.userBackgroundId = userBackgroundId;
      //   rawProfile.accountProfileData.userAvatarId = avatarId;
      // }
      // dispatch(updateState(rawProfile));
      onCancel();
    });
  };

  const checkNewImage = (value, propName, update) => {
    if (value !== accountProfileData![propName]) {
      value ? getFileImage(value, update) : dispatch(update(null));
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      notification['error']({
        message: `Изображение должно быть меньше 1MB!`,
      });
    }
    return isLt2M || Upload.LIST_IGNORE;
  };

  const uploadOptions = {
    headers: {
      Authorization: localStorage['token'],
    },
    accept: 'image/png, image/jpeg',
    action: upload,
    maxCount: 1,
    beforeUpload: beforeUpload,
  };
  return (
    <Form onFinish={onFinish} layout={'vertical'}>
      <Form.Item name="2" label={'Аватар'}>
        <Upload
          {...uploadOptions}
          defaultFileList={
            avatarUrl
              ? [
                  {
                    uid: '1',
                    name: 'аватар',
                    status: 'done',
                    url: avatarUrl,
                  },
                ]
              : undefined
          }
          onChange={(file) => sendFile(file, setAvatarId)}
        >
          <Button icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="2" label={'Фон профиля'}>
        <Upload
          {...uploadOptions}
          defaultFileList={
            backgroundUrl
              ? [
                  {
                    uid: '1',
                    name: 'background',
                    status: 'done',
                    url: backgroundUrl,
                  },
                ]
              : undefined
          }
          onChange={(file) => sendFile(file, setBackgroundId)}
        >
          <Button icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
      </Form.Item>
      <Form.Item key="button">
        <Button type="primary" htmlType="submit">
          Редактировать
        </Button>
      </Form.Item>
    </Form>
  );
});
EditDesign.displayName = 'EditDesign';
export default EditDesign;
