import React from 'react';
import AbstractPage from '../../../../utils/Components/AbstractPage/AbstractPage';
import { deviceToEmployeeData } from './DeviceToEmployeeData';
import {
  getPopupList,
  getSearchFields,
  initialColumns,
} from './DeviceToEmployeeExtra';
import { getPageHeaderExtra } from '../../Employee/EmployeeExtra';
import DeviceToEmployeeForm from './components/DeviceToEmployeeForm';
import { useSendInArchiveDeviceToEmployee } from '../helpers/useSendInArchiveDeviceToEmployee';
import { deviceToEmployeePermission } from './DeviceToEmployee.permission';

const DeviceToEmployee = React.memo(() => {
  const { sendInArchive } = useSendInArchiveDeviceToEmployee();
  return (
    <AbstractPage
      searchFields={getSearchFields()}
      columns={initialColumns}
      {...deviceToEmployeeData}
      pageHeaderExtra={getPageHeaderExtra(deviceToEmployeePermission.create)}
      AddComponent={<DeviceToEmployeeForm type={'add'} />}
      popupList={getPopupList(sendInArchive)}
    />
  );
});
DeviceToEmployee.displayName = 'DeviceToEmployee';
export default DeviceToEmployee;
