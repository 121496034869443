import React from 'react';
import AbstractPage from '../../../utils/Components/AbstractPage/AbstractPage';
import { employeeDevicesData } from './employeeDevicesData';
import { getSearchFields, initialColumns } from './EmployeeDevicesExtra';

const EmployeeDevices = React.memo(() => {
  return (
    <AbstractPage
      searchFields={getSearchFields()}
      columns={initialColumns}
      {...employeeDevicesData}
    />
  );
});
EmployeeDevices.displayName = 'EmployeeDevices';
export default EmployeeDevices;
