import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_RESULT_INTERVIEW = 'SET_DATA_RESULT_INTERVIEW';
const SET_CONTENT_RESULT_INTERVIEW = 'SET_CONTENT_RESULT_INTERVIEW';
const SET_DICTIONARY_RESULT_INTERVIEW = 'SET_DICTIONARY_RESULT_INTERVIEW';

export declare type DataResultInterview = {
  success: boolean;
  DataList: ResponseData<Dictionary>;
};

export declare type ResultInterviewReducer = {
  data: DataResultInterview | null;
  content: Array<Dictionary> | null;
  dictionary: Array<Dictionary> | null;
};

/**
 * @param {DataResultInterview} data
 * @return {any}
 */
export function setDataResultInterview(data) {
  return {
    type: SET_DATA_RESULT_INTERVIEW,
    payload: data,
  };
}

/**
 * @param {Array<DataResultInterview>} data
 * @return {any}
 */
export function setContentResultInterview(data) {
  return {
    type: SET_CONTENT_RESULT_INTERVIEW,
    payload: data,
  };
}

/**
 * @param {Array<DataResultInterview>} data
 * @return {any}
 */
export function setDictionaryResultInterview(data) {
  return {
    type: SET_DICTIONARY_RESULT_INTERVIEW,
    payload: data,
  };
}

const initialState: ResultInterviewReducer = {
  data: null,
  content: null,
  dictionary: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function resultInterviewReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_RESULT_INTERVIEW:
      return { ...state, data: action.payload };
    case SET_CONTENT_RESULT_INTERVIEW:
      return { ...state, content: action.payload };
    case SET_DICTIONARY_RESULT_INTERVIEW:
      return { ...state, dictionary: action.payload };
    default:
      return state;
  }
}
