import React from 'react';
import AbstractElementF from '../../../../../utils/Components/AbstractElement/AbstractElementF';
import { API_URLS } from '../../../../../utils/API/urls';
import ComponentForm from '../components/ComponentForm';
import { getDescriptionItems } from '../../../../../utils/Base/helpers/getDescriptionItems';
import { getInitialColumns } from '../ComponentsExtra';
import { componentDevicePermission } from '../ComponentDevice.permission';

const OnceComponentDevices = React.memo(() => {
  return (
    <AbstractElementF
      apiMethod={API_URLS.COMPONENTS}
      dataListName={'component'}
      editComponent={<ComponentForm type={'edit'} />}
      editPermission={componentDevicePermission.update}
      editModalWidth={'400px'}
      descriptions={getDescriptionItems(getInitialColumns())}
      pageHeaderList={{ pass: 'title', title: 'Компонент' }}
    />
  );
});
OnceComponentDevices.displayName = 'OnceComponentDevices';
export default OnceComponentDevices;
