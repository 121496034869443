import {useGetNotificationQuery} from "../../store/yambookApi";

export const useGetNotificationCount = () => {
  const { data } = useGetNotificationQuery(undefined, {
    pollingInterval: 120000,
  });

  return (
    data?.filter((notification) => notification.type === 'comment').length ?? 0
  );
};
