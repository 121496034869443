import axios from 'axios';
import { notification } from 'antd/es';
import { isDesktop } from 'react-device-detect';
import { Toast } from 'antd-mobile';
import { KeycloakApp } from '../keycloak/KeycloakApp';

declare module 'axios' {
  export interface AxiosRequestConfig {
    notNotification?: boolean;
  }
}

const getMessage = (type) => {
  switch (type) {
    case 'put':
      return 'Запись успешно изменена';
    case 'post':
      return 'Запись успешно создана';
    case 'delete':
      return 'Запись успешно удалена';
  }
};

const API = axios.create({
  baseURL: process.env.REACT_APP_PRODUCTION_URL,
});

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.request.use(
  async function (config) {
    if (KeycloakApp?.isTokenExpired?.(5)) {
      await KeycloakApp.updateToken(5);
    }
    const AUTH_TOKEN = KeycloakApp?.token;
    config.headers = {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    if (
      response.config.method === 'put' ||
      response.config.method === 'post' ||
      response.config.method === 'delete'
    ) {
      if (!response.config.notNotification) {
        const message =
          response.data.message || getMessage(response.config.method);
        if (isDesktop) {
          notification.success({
            message,
          });
        } else {
          Toast.show({
            icon: 'success',
            content: message,
          });
        }
      }
    }
    return response;
  },
  function (error) {

    const defaultMessage = {
      message: error.response?.data.message || error.response?.data.error,
    };

    notification.error(defaultMessage);
    return Promise.reject(error);
  }
);

export default API;
