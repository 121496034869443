import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_WORKING_METHOD = 'SET_DATA_WORKING_METHOD';
const SET_CONTENT_WORKING_METHOD = 'SET_CONTENT_WORKING_METHOD';

export declare type WorkingMethod = Dictionary;

export declare type DataWorkingMethod = {
  success: boolean;
  workingMethodDataList: ResponseData<WorkingMethod>;
};

export declare type WorkingMethodReducer = {
  data: DataWorkingMethod | null;
  content: Array<{
    workingMethodId: number;
    title: string;
    description?: string;
  }> | null;
};

/**
 * @param {DataWorkingMethod} data
 * @return {any}
 */
export function setDataWorkingMethod(data) {
  return {
    type: SET_DATA_WORKING_METHOD,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkingMethod>} data
 * @return {any}
 */
export function setContentWorkingMethod(data) {
  return {
    type: SET_CONTENT_WORKING_METHOD,
    payload: data,
  };
}

const initialState: WorkingMethodReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function workingMethodReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WORKING_METHOD:
      return { ...state, data: action.payload };
    case SET_CONTENT_WORKING_METHOD:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
