import React from 'react';
import { EditableCellProps } from '../type';
import { Form } from 'antd';

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  component,
  form,
  required,
  ...restProps
}) => {
  const renderComponent = () => {
    if (React.isValidElement(component)) {
      return React.cloneElement(component, {
        // @ts-ignore
        form: form,
      });
    }
    return component;
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: required === undefined ? true : required,
              message: ``,
            },
          ]}
        >
          {renderComponent()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
