import React from 'react';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractClassViewerProps,
  AbstractClassViewerState,
} from '../AbstractClassTypes';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Collapse,
  notification,
  Segmented,
  Tag,
  Tooltip,
} from 'antd/es';
import {
  AppstoreOutlined,
  BarsOutlined,
  DownloadOutlined,
  SettingOutlined,
} from '@ant-design/icons/es/icons';
import CollapsibleModal from '../../../CollapsibleModal/CollapsibleModal';
import { connect } from 'react-redux';
import { TableRowSelection } from 'antd/es/table/interface';
import KanbanTable from '../Components/KanbanTable';
import store from '../../../../store/store';
import { isDesktop, isMobile } from 'react-device-detect';
import Table, { ColumnType } from 'antd/es/table';
import Popup from '../../Popup/Popup';
import SearchPanel from '../../SearchPanel/SearchPanel';
import { additionalValues } from '../../../../store/reducers/WorkplaceTabs';
import DragNDropDrawer from '../../DragNDropDrawer/DragNDropDrawer';
import MobileList from './MobileList';
import { PopupList } from '../../Popup/PopupTypes';

/**
 * Класс отрисовки универсальной таблицы
 * @class
 */
class AbstractClassViewer extends React.PureComponent<
  AbstractClassViewerProps,
  AbstractClassViewerState
> {
  private readonly searchRef: React.RefObject<any>;
  private action: ColumnType<any> = {
    title: '',
    key: 'actions',
    width: '10%',
    render: () => <>...</>,
    onCell: (record) => {
      return {
        onClick: (e) => {
          e.stopPropagation();
          this.handleMobileAction(e, record);
        },
      };
    },
  };

  /**
   * Конструктор класса AbstractClassViewer
   * @param {AbstractClassViewerProps} props
   */
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      pageY: null,
      pageX: null,
      currentRow: null,
      popupListModified: null,
      isVisibleDrawer: false,
      tableType: 'Table',
      rawMobileColumns: props.mobileColumns
        ? [...props.mobileColumns, this.action]
        : [],
    };

    this.searchRef = React.createRef();
  }

  /**
   * Стандартный метод, срабатывающий при отрисовке компонента.
   * Создаёт Popup, возвращает список методов.
   */
  componentDidMount() {
    const { currentTab } = this.props;
    const { tableType } = this.state;
    this.setState({ tableType: currentTab?.tableType ?? tableType });

    document.addEventListener('keydown', this.handleCopy);
    if (isMobile) {
      document.addEventListener('click', this.handleMobilePopup);
    }
  }

  /**
   * Удаление прослушивателя
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleCopy);
    document.removeEventListener('click', this.handleMobilePopup);
  }

  /**
   * Метод обновления класса наследуется из @Link{React}
   * @param {Readonly<AbstractClassViewerProps>} prevProps
   * @param {Readonly<AbstractClassViewerState>} prevState
   */
  componentDidUpdate(
    prevProps: Readonly<AbstractClassViewerProps>,
    prevState: Readonly<AbstractClassViewerState>
  ) {
    const { tableType } = this.state;

    if (prevState.tableType !== tableType) {
      store.dispatch(
        additionalValues([
          { action: 'add', key: 'tableType', value: tableType },
        ])
      );
    }
  }

  /**
   * Устанавливает текущую строку для канбан таблицы
   * @param {any} row
   */
  setCurrenRow = (row) => {
    const { setCurrentRow } = this.props;
    this.setState({
      currentRow: row,
    });
    setCurrentRow?.(row);
  };

  /**
   * @param {React.MouseEvent} e
   * @param {any} record
   */
  handleMobileAction = (e: React.MouseEvent, record) => {
    const { popupVisible } = this.state;
    e.stopPropagation();
    this.setCurrenRow(record);
    this.setState({
      popupVisible: !popupVisible,
      pageY: e.clientY + 20,
      pageX: e.clientX - 160,
    });

    this.setCurrenRow(record);
  };

  /**
   * @param {React.MouseEvent} e
   */
  handleCopy = (e) => {
    const { currentRow } = this.state;

    if (e.ctrlKey && e.keyCode === 67 && e.shiftKey) {
      e.preventDefault();
      navigator.clipboard
        .writeText(JSON.stringify(currentRow))
        .then(() => notification.success({ message: 'Скопировано' }));
    }
  };

  /**
   */
  handleMobilePopup = () => {
    const { popupVisible } = this.state;
    if (popupVisible) {
      this.setState({ popupVisible: false });
    }
  };

  setPopupList = (currentRow): PopupList | undefined => {
    const {
      popupList,
      setIsEdit,
      onFinishDelete,
      gotoRecord,
      rowSelect,
      onFinishMassDelete,
      setIsDuplicate,
    } = this.props;
    if (!popupList) return;
    const newPopupList =
      typeof popupList === 'function'
        ? popupList(this.state.currentRow)
        : popupList;

    newPopupList.forEach((item) => {
      switch (item.type) {
        case 'checkout':
          return (item.onClick = () => gotoRecord(currentRow));
        case 'edit':
          return (item.onClick = () => setIsEdit(true));
        case 'duplicate':
          return (item.onClick = () => setIsDuplicate(true));
        case 'massEdit':
          return (item) => {
            return item.onClick(item);
          };
        case 'getRecord':
          return (item.onClick = () => item.getRecord!(this.state.currentRow));
        case 'delete':
          return (item.onClick = () =>
            onFinishDelete(this.state.currentRow![this.props.idName]));
        case 'massDelete':
          return (item.onClick = () =>
            onFinishMassDelete(rowSelect?.selectedRows));
      }
    });

    return newPopupList as PopupList;
  };

  /**
   * Метод открытия окна редактирования элемента
   * @param {any} children
   * @return {React.ReactNode}
   */
  editComponent = (children) => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        // @ts-ignore
        currentRow: this.state.currentRow,
        onFinishEdit: this.props.onFinishEdit,
        routerProps: this.props.routerProps,
        isFetching: this.props.isFetching,
      });
    }
  };

  /**
   * Метод открытия окна добавления элемента
   * @param {any} children
   * @return {React.ReactNode}
   */
  addComponent = (children) => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        // @ts-ignore
        onFinishAdd: this.props.onFinishAdd,
        routerProps: this.props.routerProps,
        isFetching: this.props.isFetching,
      });
    }
  };

  /**
   * Метод событий меню заголовка
   * @param {AbstractClassPageHeaderExtraButtonItem} item
   * @return {Function}
   */
  onPageHeaderExtraClick = (item: AbstractClassPageHeaderExtraButtonItem) => {
    const controlledStateFunctions = [
      'setIsAdd',
      'setIsEdit',
      'downloadFile',
      'setIsMultiUpload',
    ];
    switch (item.onClick.type) {
      case 'outerFunction': {
        try {
          return item.onClick.func(
            item.onClick.extra !== undefined ? item.onClick.extra : null
          );
        } catch (error) {
          console.log(error);
          throw new Error();
        }
      }
      case 'innerFunction': {
        if (
          typeof item.onClick.func === 'string' &&
          controlledStateFunctions.includes(item.onClick.func)
        ) {
          try {
            return this.props[item.onClick.func](item.onClick.extra);
          } catch (error) {
            console.log(error);
            throw new Error();
          }
        }
      }
    }
  };

  setIsVisibleDrawer = () => {
    this.setState((state) => {
      return { isVisibleDrawer: !state.isVisibleDrawer };
    });
  };

  /**
   * Метод рендерит кнопки в PageHeader Extra
   * @return {JSX}
   */
  pageHeaderRender = () => {
    const {
      pageHeaderExtra,
      downloadFile,
      isDownload,
      excelDownload,
      totalElements,
      data,
      pageHeaderExtraComponents,
      permissionList,
    } = this.props;

    const filteredPageHeaderExtraByPermission = pageHeaderExtra?.filter((el) =>
      el.permission ? permissionList?.includes(el.permission) : true
    );

    return [
      totalElements && isDesktop ? (
        <Tag color={'cyan'} key={'total'}>
          {`Всего элементов: ${data!.totalElements}`}
        </Tag>
      ) : null,
      pageHeaderExtraComponents
        ? pageHeaderExtraComponents.map((item) => {
            return item;
          })
        : null,
      <div key={'downloadButtonDiv'}>
        {excelDownload?.activate && (
          <Button
            icon={<DownloadOutlined />}
            key={'downloadButton'}
            onClick={() => downloadFile()}
            loading={isDownload}
          />
        )}
      </div>,
      pageHeaderExtra
        ? filteredPageHeaderExtraByPermission?.map(
            (item: AbstractClassPageHeaderExtraButtonItem, index) => {
              return (
                <Tooltip key={`tooltip${index}}`} title={item.title}>
                  <Button
                    type={item.type}
                    icon={item.icon}
                    key={index}
                    block={item.block ? item.block : false}
                    loading={item.loading ? item.loading : false}
                    danger={item.danger ? item.danger : false}
                    ghost={item.ghost ? item.ghost : false}
                    disabled={item.disabled ? item.disabled : false}
                    shape={item.shape ? item.shape : false}
                    onClick={() => this.onPageHeaderExtraClick(item)}
                  >
                    {item?.text}
                  </Button>
                </Tooltip>
              );
            }
          )
        : false,
      isDesktop && (
        <Tooltip title="Редактировать колонки" key={'editTableColumns'}>
          <Button
            icon={<SettingOutlined />}
            onClick={this.setIsVisibleDrawer}
          />
        </Tooltip>
      ),
    ];
  };

  /**
   * Стандартный метод отрисовки компонента
   * @return {JSX}
   */
  render() {
    const {
      content,
      data,
      isEdit,
      isAdd,
      onLoadSearch,
      onSearchFinish,
      setIsAdd,
      setIsEdit,
      onChangeTable,
      apiMethod,
      searchFields,
      pageHeaderTitle,
      EditComponent,
      AddComponent,
      columns,
      gotoRecord,
      popupList,
      rowSelect,
      idName,
      editModalWidth,
      createModalWidth,
      routerProps,
      getCurrentLink,
      profile,
      initialColumns,
      columnsName,
      incrementIndex,
      additionalContent,
      rowClassName,
      expandable,
      notGotRecord,
      kanban,
      mobileColumns,
      getData,
      getListItemMobile,
      loading,
      setIsDuplicate,
      isDuplicate,
      DuplicateComponent,
      onRow,
      darkTheme,
      scrollX,
      permissionList,
    } = this.props;

    const { isVisibleDrawer, tableType, rawMobileColumns, currentRow } =
      this.state;

    const paramsUrl = new URLSearchParams(window.location.search);

    const paramPage = paramsUrl.get('page');
    const paramSize = paramsUrl.get('size');
    const paramSort = paramsUrl.get('sort');

    const pagination = {
      showSizeChanger: true,
      total: data?.totalElements ?? 0,
      current: paramPage ? +paramPage + 1 : 1,
      pageSize: paramSize ? +paramSize : 10,
      pageSizeOptions: [10, 20, 50, 100, 250],
    };

    const columnsWithSort = [...columns];
    if (paramSort) {
      const [field, order] = paramSort.split(',');
      const idx = columnsWithSort.findIndex((el) => el.dataIndex === field);

      if (idx !== -1) {
        if (columnsWithSort[idx].children) {
          const idxChildren = columnsWithSort[idx].children.findIndex(
            (el) => el.dataIndex === field
          );
          if (idxChildren >= 0) {
            if (order === 'asc') {
              columnsWithSort[idx].children[idxChildren].defaultSortOrder =
                'ascend';
            }
            if (order === 'desc') {
              columnsWithSort[idx].children[idxChildren].defaultSortOrder =
                'descend';
            }
          }
        } else {
          if (order === 'asc') {
            columnsWithSort[idx].defaultSortOrder = 'ascend';
          }
          if (order === 'desc') {
            columnsWithSort[idx].defaultSortOrder = 'descend';
          }
        }
      }
    } else {
      for (const i in columnsWithSort) {
        if (columnsWithSort[i] && columnsWithSort[i]?.defaultSortOrder) {
          delete columnsWithSort[i].defaultSortOrder;
        }
      }
    }

    const { popupListModified } = this.state;

    const rowSelection: TableRowSelection<any> = {
      type: rowSelect?.type,
      hideSelectAll: rowSelect?.hideSelectAll,
      preserveSelectedRowKeys: true,
      onChange: (rowKeys, rows) => {
        rowSelect?.onSelectRows(rowKeys, rows);
      },
      selectedRowKeys: rowSelect?.selectedRows,
      getCheckboxProps: rowSelect?.getCheckboxProps,
    };

    const organization =
      profile?.organizationPositionData?.organizationDepartmentData
        ?.organizationData?.title;
    const onChangePagination = (pagination, filers, sorter) => {
      onChangeTable({ pagination: pagination, sorter: sorter });
    };

    const calculateScroll = () => {
      if (scrollX) {
        const tablesSettingsProfile =
          store.getState().authReducer.profile?.accountProfileData
            ?.tablesSettings;
        if (columnsWithSort.length !== initialColumns.length) {
          const tablesSettings = tablesSettingsProfile
            ? JSON.parse(tablesSettingsProfile)
            : null;
          const currentColum = tablesSettings[columnsName!];
          let newScrollX = scrollX;

          for (let i = 0; i < currentColum.length; i++) {
            if (currentColum[i].visible === false) {
              if (
                typeof currentColum[i].width === 'number' ||
                currentColum[i].width?.includes('px')
              ) {
                newScrollX -= parseInt(currentColum[i].width.toString());
              } else {
                newScrollX -= 150;
              }
            }
          }

          return newScrollX;
        }
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const initValues = urlParams.toString().length
      ? Object.fromEntries(urlParams)
      : {};

    const scroll =
      scrollX && isDesktop
        ? { x: calculateScroll() }
        : isMobile && !mobileColumns
        ? { x: 3000 }
        : {};

    const filteredSearchFields = searchFields?.filter((el) =>
      el.permission ? permissionList?.includes(el.permission) : true
    );
    return (
      <div>
        <PageHeader
          title={
            <span
              style={{
                color: darkTheme ? 'rgba(255, 255, 255, 0.85)' : undefined,
              }}
            >
              {pageHeaderTitle}
            </span>
          }
          extra={
            organization?.toLowerCase().includes('нипи')
              ? null
              : this.pageHeaderRender()
          }
          style={{ flexWrap: 'wrap' }}
          key={routerProps?.location.key + 'pageHeader'}
        >
          <>
            {filteredSearchFields.length ? (
              <Collapse
                defaultActiveKey={['1']}
                items={[
                  {
                    label: 'Фильтры',
                    key: '1',
                    children: (
                      <SearchPanel
                        onFinish={onSearchFinish}
                        onLoad={onLoadSearch}
                        // @ts-ignore
                        searchFields={filteredSearchFields}
                        link={apiMethod}
                        ref={this.searchRef}
                        routerProps={routerProps!}
                        getCurrentLink={getCurrentLink}
                        initialValues={initValues}
                      />
                    ),
                  },
                ]}
              />
            ) : null}

            {additionalContent &&
              additionalContent?.map((item, index) => {
                return <div key={index}>{item}</div>;
              })}
          </>
        </PageHeader>
        {kanban && (
          <Segmented
            style={{ marginBottom: 16 }}
            value={tableType}
            options={[
              {
                value: 'Таблица',
                icon: <BarsOutlined />,
              },
              {
                value: 'Kanban',
                icon: <AppstoreOutlined />,
              },
            ]}
            onChange={(e) =>
              this.setState({ tableType: e as 'Table' | 'Kanban' })
            }
          />
        )}
        <div
          className="tableContainer"
          key={routerProps?.location.key + 'tableContainer'}
        >
          {tableType === 'Table' && isDesktop && (
            <Table
              key={routerProps?.location.key + 'table'}
              bordered
              columns={
                isMobile && mobileColumns ? rawMobileColumns : columnsWithSort
              }
              showSorterTooltip={false}
              size="small"
              dataSource={content}
              rowKey={(record) => record[idName]}
              rowSelection={rowSelect ? { ...rowSelection } : undefined}
              onRow={(record) => {
                return {
                  onContextMenu: (event) => {
                    if (organization?.toLowerCase().includes('нипи')) {
                      return false;
                    }
                    event.preventDefault();

                    if (!this.state.popupVisible) {
                      const self = this;
                      document.addEventListener(
                        `click`,
                        function onClickOutside() {
                          self.setState({ popupVisible: false }, () => {
                            document.removeEventListener(
                              `click`,
                              onClickOutside
                            );
                          });
                        }
                      );
                    }

                    this.setState({
                      pageX: event.clientX,
                      pageY: event.clientY,
                      popupVisible: true,
                    });

                    this.setCurrenRow(record);

                    return record;
                  },
                  onDoubleClick: () => {
                    if (!notGotRecord) gotoRecord(record);
                  },
                  onClick: () => {
                    if (!notGotRecord && isMobile) gotoRecord(record);
                  },
                  ...(onRow?.(record) ?? {}),
                };
              }}
              pagination={pagination}
              onChange={onChangePagination}
              scroll={scroll}
              sticky={{ offsetHeader: 0, offsetScroll: 0 }}
              rowClassName={rowClassName}
              expandable={expandable}
              style={{ fontSize: '0.75rem' }}
            />
          )}
          {isMobile && (
            <MobileList
              data={data}
              content={content}
              getListItem={getListItemMobile}
              getData={getData}
              popupList={popupListModified ? popupListModified : popupList}
              gotoRecord={gotoRecord}
              setCurrentRow={this.setCurrenRow}
              loading={loading}
            />
          )}
          {tableType === 'Kanban' && kanban && (
            <KanbanTable
              data={content}
              {...kanban}
              pagination={pagination}
              popupList={this.setPopupList(currentRow) ?? []}
              setCurrentRow={this.setCurrenRow}
              onChange={onChangePagination}
            />
          )}
        </div>
        {popupList && (
          <Popup
            pageX={this.state.pageX}
            pageY={this.state.pageY}
            visible={this.state.popupVisible}
            list={this.setPopupList(currentRow)!}
            key={routerProps?.location.key + 'popup'}
            currentRow={this.state.currentRow}
          />
        )}
        <CollapsibleModal
          visible={isDuplicate}
          destroyOnClose={true}
          onCancel={() => {
            setIsDuplicate(false);
          }}
          title={'Создать запись'}
          key={routerProps?.location.key + 'duplicateComponent'}
          width={
            createModalWidth
              ? `${
                  isDesktop
                    ? typeof createModalWidth === 'number'
                      ? createModalWidth + 'vw'
                      : createModalWidth
                    : '90vw'
                }`
              : undefined
          }
        >
          {this.addComponent(DuplicateComponent)}
        </CollapsibleModal>{' '}
        <CollapsibleModal
          visible={isAdd}
          destroyOnClose={true}
          onCancel={() => {
            setIsAdd(false);
          }}
          title="Создать запись"
          key={routerProps?.location.key + 'addComponent'}
          width={
            createModalWidth
              ? isMobile
                ? '90vh'
                : createModalWidth
              : undefined
          }
        >
          {this.addComponent(AddComponent)}
        </CollapsibleModal>
        <CollapsibleModal
          visible={isEdit}
          destroyOnClose={true}
          onCancel={() => {
            setIsEdit(false);
          }}
          title="Редатировать запись"
          width={
            editModalWidth ? (isMobile ? '90vh' : editModalWidth) : undefined
          }
          key={routerProps?.location.key + 'editComponent'}
        >
          {this.editComponent(EditComponent)}
        </CollapsibleModal>
        <DragNDropDrawer
          isVisibleDrawer={isVisibleDrawer}
          setIsVisibleDrawer={this.setIsVisibleDrawer}
          initialColumns={initialColumns}
          columnsName={columnsName ?? ''}
          incrementIndex={incrementIndex}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.authReducer.profile,
  permissionList: state.authReducer.permissionList,
  darkTheme: state.authReducer.darkTheme,
  currentTab: state.workplaceTabsReducer.currentTab,
});

export default connect(mapStateToProps)(AbstractClassViewer);
