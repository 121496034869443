import React from 'react';
import AbstractPage from '../../../utils/Components/AbstractPage/AbstractPage';
import { HistoryResumeData } from './HistoryResume.data';
import { HistoryResume as HistoryResumeType } from './HistoryResume.type';
import { useMassDelete } from './helpers/useMassDelete';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { initialColumns, searchFields } from './HistoryResume.extra';

const HistoryResume = () => {
  const { rowSelect, handlerMassDelete } = useMassDelete<HistoryResumeType>(
    'api/resume-download-history?idList=',
    HistoryResumeData.apiMethod,
    {
      title: (record) => record.interviewer.fullName,
      description: (record) => dayjs(record.dateTime).format('DD.MM.YYYY'),
    }
  );
  return (
    <AbstractPage
      searchFields={searchFields}
      columns={initialColumns}
      pageHeaderExtraComponents={[
        <Button
          key={'delete'}
          disabled={!rowSelect.selectedRows.length}
          onClick={handlerMassDelete}
        >
          Удалить выделенные записи
        </Button>,
      ]}
      dbClickRoute={false}
      {...HistoryResumeData}
      rowSelect={rowSelect}
      popupList={[]}
    />
  );
};
export default HistoryResume;
