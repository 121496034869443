import { Table, Typography } from 'antd';
import React from 'react';

export const getSummary = (columnsInfo: any[], propName = 'quantity') => {
  // eslint-disable-next-line react/display-name
  return (pageData: readonly any[]) => {
    const total = pageData.reduce((prev, curr) => prev + curr[propName], 0);

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>{'Всего'}</Table.Summary.Cell>
          {columnsInfo.slice(0, -3).map((el, index) => (
            <Table.Summary.Cell index={index + 1} key={index}>
              <Typography.Text />
            </Table.Summary.Cell>
          ))}
          <Table.Summary.Cell index={columnsInfo.length - 2} align={'center'}>
            <Typography.Text>{total}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={columnsInfo.length - 1} align={'center'}>
            <Typography.Text />
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
};
