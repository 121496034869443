import React from 'react';
import AbstractPage from '../../../../utils/Components/AbstractPage/AbstractPage';
import { componentsData } from './componentsData';
import { getInitialColumns, getSearchFields } from './ComponentsExtra';
import ComponentForm from './components/ComponentForm';
import { getPageHeaderExtra } from '../../Employee/EmployeeExtra';
import { getPopupList } from '../../../../utils/Components/Popup/PopupExtra';
import { componentDevicePermission } from './ComponentDevice.permission';

const ComponentDevice = React.memo(() => {
  return (
    <AbstractPage
      searchFields={getSearchFields()}
      columns={getInitialColumns()}
      AddComponent={<ComponentForm type={'add'} />}
      EditComponent={<ComponentForm type={'edit'} />}
      {...componentsData}
      pageHeaderExtra={getPageHeaderExtra(componentDevicePermission.create)}
      popupList={getPopupList(componentDevicePermission)}
    />
  );
});
ComponentDevice.displayName = 'ComponentDevice';
export default ComponentDevice;
