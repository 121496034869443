import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_LOCATION_EMPLOYEE = 'SET_DATA_LOCATION_EMPLOYEE';
const SET_CONTENT_LOCATION_EMPLOYEE = 'SET_CONTENT_LOCATION_EMPLOYEE';

export declare type LocationEmployee = Dictionary;

export declare type DataLocationEmployee = {
  success: boolean;
  DataList: ResponseData<LocationEmployee>;
};

export declare type LocationEmployeeReducer = {
  data: DataLocationEmployee | null;
  content: Array<LocationEmployee> | null;
};

/**
 * @param {DataLocationEmployee} data
 * @return {any}
 */
export function setDataLocationEmployee(data) {
  return {
    type: SET_DATA_LOCATION_EMPLOYEE,
    payload: data,
  };
}

/**
 * @param {Array<DataLocationEmployee>} data
 * @return {any}
 */
export function setContentLocationEmployee(data) {
  return {
    type: SET_CONTENT_LOCATION_EMPLOYEE,
    payload: data,
  };
}

const initialState: LocationEmployeeReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function locationEmployeeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_LOCATION_EMPLOYEE:
      return { ...state, data: action.payload };
    case SET_CONTENT_LOCATION_EMPLOYEE:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
