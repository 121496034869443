import {
  EditOutlined,
  FileTextOutlined,
  MinusOutlined,
} from '@ant-design/icons/es/icons';
import React from 'react';
import { PopupList } from './PopupTypes';
import { PermissionPage } from '../../../AppTypes';

export const getPopupList = (permission: PermissionPage): PopupList => {
  return [
    {
      title: 'Подробнее',
      icon: <FileTextOutlined />,
      type: 'checkout',
      permission: permission.one,
      onClick: () => {
        console.log('More details');
      },
    },
    {
      title: 'Редактировать',
      icon: <EditOutlined />,
      type: 'edit',
      permission: permission.update,
      onClick: () => {
        console.log('Edit');
      },
    },
    {
      title: 'Удалить',
      icon: <MinusOutlined />,
      permission: permission.delete,
      type: 'delete',
      onClick: () => {
        console.log('Delete');
      },
    },
  ];
};
