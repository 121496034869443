import { ResponseData } from '../../utils/API/APITypes';
import { Department } from '../../components/Pages/Сandidates/Types/Candidates';
import { WorkDirection } from './WorkDirection';
import { SubworkDirection } from './SubworkDirection';
import { GraphDirectionBond } from '../../components/Pages/DepartmentDirectionBond/type';

const SET_DATA_DEPARTMENT_DIRECTION_WORK_BOND =
  'SET_DATA_DEPARTMENT_DIRECTION_WORK_BOND';
const SET_CONTENT_DEPARTMENT_DIRECTION_WORK_BOND =
  'SET_CONTENT_DEPARTMENT_DIRECTION_WORK_BOND';
const SET_GRAPH_DATA_DIRECTION_BOND = 'SET_GRAPH_DATA_DIRECTION_BOND';

export declare type DepartmentDirectionWorkBond = {
  id: number;
  department: Department;
  workDirection: WorkDirection;
  subWorkDirection: SubworkDirection;
};

export declare type DataDepartmentDirectionWorkBond = {
  success: boolean;
  departmentDirectionWorkBondList: ResponseData<DepartmentDirectionWorkBond>;
};

export declare type DepartmentDirectionWorkBondReducer = {
  data: DataDepartmentDirectionWorkBond | null;
  content: Array<DepartmentDirectionWorkBond> | null;
  graphData: GraphDirectionBond[] | null;
};

/**
 * @param {DataDepartmentDirectionWorkBond} data
 * @return {any}
 */
export function setDataDepartmentDirectionWorkBond(data) {
  return {
    type: SET_DATA_DEPARTMENT_DIRECTION_WORK_BOND,
    payload: data,
  };
}

/**
 * @param {Array<DataDepartmentDirectionWorkBond>} data
 * @return {any}
 */
export function setContentDepartmentDirectionWorkBond(data) {
  return {
    type: SET_CONTENT_DEPARTMENT_DIRECTION_WORK_BOND,
    payload: data,
  };
}
/**
 * @param {Array<DataDepartmentDirectionWorkBond>} data
 * @return {any}
 */
export function setGraphDataDirectionBond(data) {
  return {
    type: SET_GRAPH_DATA_DIRECTION_BOND,
    payload: data,
  };
}

const initialState: DepartmentDirectionWorkBondReducer = {
  data: null,
  content: null,
  graphData: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function departmentDirectionWorkBondReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_DATA_DEPARTMENT_DIRECTION_WORK_BOND:
      return { ...state, data: action.payload };
    case SET_CONTENT_DEPARTMENT_DIRECTION_WORK_BOND:
      return { ...state, content: action.payload };
    case SET_GRAPH_DATA_DIRECTION_BOND:
      return { ...state, graphData: action.payload };
    default:
      return state;
  }
}
