import React, { useEffect } from 'react';
import store from '../../../../store/store';
import { additionalValues } from '../../../../store/reducers/WorkplaceTabs';
import { AbstractSearchPanel as AbstractSearchPanelType } from '../type';
import { getCurrentLink } from '../helpers/getCurrentLink';
import { useRouterProps } from '../helpers/useRouterProps';
import { useSearchParams } from 'react-router-dom';
import { Collapse } from 'antd';
import { useAppSelector } from '../../../../store/rootReducer';
import { useGetAbstractDataQuery } from '../../../../store/api/abstract.api';
import SearchPanel from '../../SearchPanel/SearchPanel';

const AbstractSearchPanel = React.memo(
  ({
    onSearchFinish,
    apiMethod,
    searchFields,
    getFilteredSearchFields,
    setIsAddRow,
  }: AbstractSearchPanelType) => {
    const { darkTheme } = useAppSelector((state) => state.authReducer);
    // eslint-disable-next-line no-unused-vars
    const [_, setSearchParams] = useSearchParams();
    const routerProps = useRouterProps();
    const path = getCurrentLink(apiMethod).replace('api/', '');
    const { isFetching } = useGetAbstractDataQuery(path);
    useEffect(() => {
      getFilteredSearchFields?.(window.location.search.replace('?', ''));
    }, []);

    const onLoadSearch = (searchParams: URLSearchParams) => {
      const params = new URLSearchParams(searchParams);
      const lastUrl = new URLSearchParams(window.location.search);

      params.set('page', '0');
      params.set('size', lastUrl.get('size') ?? '10');
      lastUrl.get('sort') && params.set('sort', lastUrl.get('sort')!);

      setSearchParams(params);

      store.dispatch(
        additionalValues([
          { action: 'add', key: 'searchParams', value: params.toString() },
        ])
      );
      getFilteredSearchFields?.(searchParams.toString());
      setIsAddRow(false);
    };

    const initValues = window.location.search.length
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : {};

    return (
      <Collapse
        defaultActiveKey={['1']}
        style={{ background: darkTheme ? '#1d1d1d' : '#fafafa' }}
        items={[
          {
            label: 'Поиск',
            key: '1',
            children: (
              <SearchPanel
                onFinish={onSearchFinish}
                onLoad={onLoadSearch}
                loadingBtn={isFetching}
                searchFields={searchFields}
                routerProps={routerProps}
                getCurrentLink={() => getCurrentLink(apiMethod)}
                initialValues={initValues}
              />
            ),
          },
        ]}
      />
    );
  }
);
AbstractSearchPanel.displayName = 'AbstractSearchPanel';
export default AbstractSearchPanel;
