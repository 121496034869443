export const getColumnWithSort = (columns) => {
  const paramsUrl = new URLSearchParams(window.location.search);
  const paramSort = paramsUrl.get('sort');

  const formatColumn = [...columns];
  if (paramSort) {
    const [field, order] = paramSort.split(',');

    for (const idx in formatColumn) {
      if (formatColumn[idx].dataIndex === field) {
        if (formatColumn[idx].children) {
          const idxChildren = formatColumn[idx].children.findIndex(
            (el) => el.dataIndex === field
          );
          if (idxChildren >= 0) {
            if (order === 'asc') {
              formatColumn[idx].children[idxChildren].defaultSortOrder =
                'ascend';
            }
            if (order === 'desc') {
              formatColumn[idx].children[idxChildren].defaultSortOrder =
                'descend';
            }
          }
        } else {
          if (order === 'asc') {
            formatColumn[idx].defaultSortOrder = 'ascend';
          }
          if (order === 'desc') {
            formatColumn[idx].defaultSortOrder = 'descend';
          }
        }
      } else {
        if (formatColumn[idx] && formatColumn[idx]?.defaultSortOrder) {
          delete formatColumn[idx].defaultSortOrder;
        }
      }
    }
  }

  return formatColumn;
};
