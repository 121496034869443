import React from 'react';
import FormRecord from './components/EditRecord';
import { OnceRecordProps } from './types';
import AbstractElementF from '../AbstractElement/AbstractElementF';

/**
 * Отдельная запись
 */
class OnceRecord extends React.Component<OnceRecordProps, any> {
  /**
   * @return {JSX}
   */
  render() {
    const { descriptions, editComponent, formFields, permission } = this.props;
    const descriptionsDefault = [
      {
        label: 'Название',
        fieldName: this.props.name,
        span: 8,
      },
      {
        label: 'Описание',
        fieldName: 'description',
        span: 16,
      },
    ];

    return (
      <AbstractElementF
        apiMethod={this.props.apiMethod}
        dataListName={this.props.dataListName}
        editPermission={permission.update}
        editComponent={
          editComponent || (
            // @ts-ignore
            <FormRecord
              formFields={formFields ?? []}
              idName={this.props.idName}
              name={this.props.name}
              type="edit"
            />
          )
        }
        descriptions={descriptions || descriptionsDefault}
        pageHeaderList={{ pass: this.props.name, title: '' }}
        editModalWidth={60}
      />
    );
  }
}

export default OnceRecord;
