import React from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from 'antd';
import dayjs from 'dayjs';
import ruRu from 'antd/lib/locale/ru_RU';

type Props = {
  onFinish: (values: any) => void;
  item: any;
};

export default class EditForm extends React.Component<Props, any> {
  render() {
    const { item } = this.props;

    return (
      <Form
        layout="vertical"
        onFinish={(values) => {
          let date = new Date(values.time);
          let userTimezoneOffset = date.getTimezoneOffset() * 60000;
          values.time = new Date(date.getTime() - userTimezoneOffset);

          values.id = item.id;
          values.interviewerId = item.interviewer.id;
          values.accountId = item.account.id;
          values.date =
            dayjs(values.date).format().split('T')[0] +
            'T' +
            dayjs(values.time).format().split('T')[1];
          delete values.time;
          this.props.onFinish(values);
        }}
        fields={[
          { name: 'type', value: item.type },
          { name: 'text', value: item.text },
          { name: 'date', value: dayjs(item.date) },
          { name: 'time', value: dayjs(item.date) },
        ]}
      >
        <Form.Item
          name="type"
          label="Как связаться?"
          rules={[{ required: true, message: 'Поле не заполнено' }]}
        >
          <Select allowClear>
            <Select.Option value="Call">Звонок</Select.Option>
            <Select.Option value="Letter">Письмо</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="text"
          label="Текст уведомления"
          rules={[{ required: true, message: 'Поле не заполнено' }]}
        >
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <ConfigProvider locale={ruRu}>
              <Form.Item
                name="date"
                label="Дата уведомления"
                rules={[{ required: true, message: 'Поле не заполнено' }]}
              >
                <DatePicker style={{ width: '100%' }} format={'DD.MM.YYYY'} />
              </Form.Item>
            </ConfigProvider>
          </Col>
          <Col span={12}>
            <ConfigProvider locale={ruRu}>
              <Form.Item
                name="time"
                label="Время уведомления"
                rules={[{ required: true, message: 'Поле не заполнено' }]}
              >
                <TimePicker style={{ width: '100%' }} format={'HH:mm'} />
              </Form.Item>
            </ConfigProvider>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить изменения
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
