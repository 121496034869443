import { ResponseData } from '../../utils/API/APITypes';
import { InterviewRequest } from '../../components/Pages/InterviewRequest/type';

const SET_DATA_INTERVIEW_REQUEST = 'SET_DATA_INTERVIEW_REQUEST';
const SET_CONTENT_INTERVIEW_REQUEST = 'SET_CONTENT_INTERVIEW_REQUEST';

export declare type DataInterviewRequest = {
  success: boolean;
  interviewerRequestList: ResponseData<InterviewRequest>;
};

export declare type InterviewRequestReducer = {
  data: DataInterviewRequest | null;
  content: Array<InterviewRequest> | null;
};

/**
 * @param {DataInterviewRequest} data
 * @return {any}
 */
export function setDataInterviewRequest(data) {
  return {
    type: SET_DATA_INTERVIEW_REQUEST,
    payload: data,
  };
}

/**
 * @param {Array<DataInterviewRequest>} data
 * @return {any}
 */
export function setContentInterviewRequest(data) {
  return {
    type: SET_CONTENT_INTERVIEW_REQUEST,
    payload: data,
  };
}

const initialState: InterviewRequestReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function interviewRequestReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_INTERVIEW_REQUEST:
      return { ...state, data: action.payload };
    case SET_CONTENT_INTERVIEW_REQUEST:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
