import React from 'react';
import { Spin } from 'antd/es';

export const SpinPage = () => (
  <div
    style={{
      display: 'flex',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spin size={'large'} />
  </div>
);
