import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_CITIZENSHIP = 'SET_DATA_CITIZENSHIP';
const SET_CONTENT_CITIZENSHIP = 'SET_CONTENT_CITIZENSHIP';

export type Citizenship = Dictionary;

export declare type DataCitizenship = {
  success: boolean;
  citizenshipDataList: ResponseData<Citizenship>;
};

export declare type CitizenshipReducer = {
  data: DataCitizenship | null;
  content: Array<Citizenship> | null;
};

/**
 * @param {DataCitizenship} data
 * @return {any}
 */
export function setDataCitizenship(data) {
  return {
    type: SET_DATA_CITIZENSHIP,
    payload: data,
  };
}

/**
 * @param {Array<DataCitizenship>} data
 * @return {any}
 */
export function setContentCitizenship(data) {
  return {
    type: SET_CONTENT_CITIZENSHIP,
    payload: data,
  };
}

const initialState: CitizenshipReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function citizenshipReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_CITIZENSHIP:
      return { ...state, data: action.payload };
    case SET_CONTENT_CITIZENSHIP:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
