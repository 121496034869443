import { DictionaryData, OnceRecordProps } from '../types';
import { getPopupList } from '../../Popup/PopupExtra';
import React from 'react';
import { getSearchFields } from '../DictionaryExtra';
import {
  setContentWorkStatus,
  setDataWorkStatus,
} from '../../../../store/reducers/WorkStatus';
import WorkStatusForm from '../components/WorkStatusForm';
import { Tag } from 'antd/es';
import { PermissionPage } from '../../../../AppTypes';

const idName = 'id';
const api = 'api/references/work-status';
const name = 'description';

const description = [
  {
    label: 'Название',
    fieldName: 'title',
    span: 1,
  },
  {
    label: 'Описание',
    fieldName: 'description',
    span: 2,
  },
  {
    label: 'Цвет',
    fieldName: 'color',
    span: 2,
    render: (item) => (
      <div
        style={{
          height: 20,
          width: 80,
          backgroundColor: item,
          borderRadius: 4,
        }}
      ></div>
    ),
  },
];

const getInitialColumns = () => [
  {
    title: 'Наименование',
    dataIndex: 'title',
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    visible: true,
  },
  {
    title: 'Действующий сотрудник',
    dataIndex: 'isWorkingStatus',
    key: 'isWorkingStatus',
    align: 'center',
    visible: true,
    render: (item) => (
      <Tag color={item ? 'green' : 'red'}>{item ? 'Да' : 'Нет'}</Tag>
    ),
  },
];

export const workStatusPermission: PermissionPage = {
  create: 'createWorkStatus',
  all: 'getAllWorkStatus',
  delete: 'deleteWorkStatus',
  update: 'updateWorkStatus',
  one: 'getWorkStatus',
};

export const workStatusDictionaryData: DictionaryData = {
  apiMethod: api,
  dataListName: 'workStatusList',
  idName: 'id',
  pageHeaderTitle: 'Статусы работ',
  tabNameField: name,
  reducer: 'workStatusReducer',
  columnsName: 'WorkStatusTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList(workStatusPermission),
  setData: setDataWorkStatus,
  setContent: setContentWorkStatus,
  addComponent: <WorkStatusForm type={'add'} idName={idName} />,
  editComponent: <WorkStatusForm type={'edit'} idName={idName} />,
  permission: workStatusPermission,
};

export const onceWorkStatusProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'workStatus',
  name: name,
  descriptions: description,
  editComponent: <WorkStatusForm type={'edit'} idName={idName} />,
  permission: workStatusPermission,
};
