import { DictionaryData, OnceRecordProps } from '../types';
import { API_URLS } from '../../../API/urls';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import {
  setContentPositions,
  setDataPositions,
} from '../../../../store/reducers/Positions';
import { PermissionPage } from '../../../../AppTypes';

const tabNameField = 'title';

export const positionPermission: PermissionPage = {
  all: 'getAllPosition',
  one: 'getPosition',
  create: 'createPosition',
  update: 'updatePosition',
  delete: 'deletePosition',
};

export const positionDictionaryData: DictionaryData = {
  apiMethod: API_URLS.POSITIONS,
  dataListName: 'positionsList',
  idName: 'id',
  pageHeaderTitle: 'Должности',
  tabNameField: tabNameField,
  reducer: 'positionsReducer',
  columnsName: 'PositionTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: tabNameField,
  popupList: getPopupList(positionPermission),
  setData: setDataPositions,
  setContent: setContentPositions,
  permission: positionPermission,
};

export const oncePositionsProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: API_URLS.POSITIONS,
  dataListName: 'position',
  name: tabNameField,
  permission: positionPermission,
};
