import React, { useState } from 'react';
import { Button, Form, Select } from 'antd';
import { useOnCustomSelect } from '../../../../utils/Base/hooks/useCustomSelect';
import { setToDeviceData } from '../SetToDevices/setToDevicesData';
import API from '../../../../utils/API/API';
import { API_URLS } from '../../../../utils/API/urls';

const FormEmployeeDevices = React.memo(
  ({
    employeeId,
    onFinishAdd,
  }: {
    employeeId: number;
    onFinishAdd: () => void;
  }) => {
    const [loading, setLoading] = useState(false);
    const { selectProps } = useOnCustomSelect(
      setToDeviceData.apiMethod + '?number=',
      setToDeviceData.dataListName,
      'id',
      'number'
    );
    const onFinish = (values) => {
      setLoading(true);
      const data = { ...values, employeeId };
      const request = API.post(API_URLS.DEVICE_TO_EMPLOYEE, data);

      request
        .then(() => onFinishAdd())
        .finally(() => {
          setLoading(false);
        });
    };
    return (
      <Form layout={'vertical'} onFinish={onFinish}>
        <Form.Item name={'setOfDeviceId'} label={'Набор устройств'}>
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={loading}>
            Добавить набор
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
FormEmployeeDevices.displayName = 'FormEmployeeDevices';
export default FormEmployeeDevices;
