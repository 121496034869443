import React from 'react';
import { Form, Input } from 'antd';
import { REQUIRED_RULES } from '../../../../../utils/Base/constants/requiredRule';
import TableSearchForm from '../../../../../utils/Components/TableSearch/TableSearchForm';
import { API_URLS } from '../../../../../utils/API/urls';
import { NewFormProps } from '../../../../../AppTypes';
import { Role } from '../../Accounts/type';
import { useForm } from 'antd/es/form/Form';
import { Key } from 'antd/es/table/interface';

const RoleForm = React.memo(
  ({ onFinishEdit, onFinishAdd, type, currentRow }: NewFormProps<Role>) => {
    const [form] = useForm();
    const onFinish = (permissionIdList: Key[]) => {
      const formValues = form.getFieldsValue();
      const data = { permissionIdList, ...formValues };
      return type === 'add'
        ? onFinishAdd!(data)
        : onFinishEdit!({ ...data, id: currentRow?.id });
    };
    const initValue = currentRow?.permissionSet.map((el) => ({
      ...el,
      permissionId: el.id,
    }));

    return (
      <>
        <Form
          layout={'vertical'}
          form={form}
          initialValues={currentRow ? { ...currentRow } : undefined}
        >
          <Form.Item
            name={'title'}
            label={'Наименование'}
            rules={REQUIRED_RULES}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'description'}
            label={'Описание'}
            rules={REQUIRED_RULES}
          >
            <Input />
          </Form.Item>
        </Form>
        <TableSearchForm
          api={API_URLS.PERMISSIONS + '?'}
          onFinish={onFinish}
          dataListName={'permissionDataList'}
          idName={'permissionId'}
          columns={() => [
            {
              title: 'Наименование',
              dataIndex: 'title',
              align: 'center',
            },
          ]}
          initValue={initValue}
          searchFields={[
            {
              component: 'input',
              label: 'Наименование',
              name: 'permissionTitle',
              placeholder: '',
              required: false,
              span: 12,
            },
          ]}
          isQuantity={false}
        />
      </>
    );
  }
);
RoleForm.displayName = 'RoleForm';
export default RoleForm;
