import { TabType } from '../../LayoutTypes';
import { setInitialTab } from '../../../../store/reducers/WorkplaceTabs';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../store/rootReducer';
import { useDispatch } from 'react-redux';
import { MAIN_TAB } from './mainTab';

export const useSetInitialTabs = (name: string) => {
  const location = useLocation();
  const { profile } = useAppSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const initialTabs: Array<TabType> = [MAIN_TAB];

  return () => {
    const link = location.pathname;
    const { accountProfileData } = profile!;
    let rawLocalTabs: Array<TabType>;
    if (!accountProfileData?.tabsSettings) {
      rawLocalTabs = initialTabs;
      if (link !== '/') {
        rawLocalTabs.push({
          title: name,
          key: link,
          closable: true,
        });
      }
    } else {
      rawLocalTabs = JSON.parse(accountProfileData.tabsSettings);
      if (link !== '/') {
        if (!rawLocalTabs.find((tab) => tab.key === link)) {
          rawLocalTabs.push({
            title: name,
            key: link,
            closable: link !== '/',
          });
        }
      }
    }
    if (rawLocalTabs) dispatch(setInitialTab(rawLocalTabs));
  };
};
