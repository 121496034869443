import axios from 'axios';
import { KeycloakApp } from '../keycloak/KeycloakApp';

declare module 'axios' {
  export interface AxiosRequestConfig {
    notNotification?: boolean;
  }
}

const API = axios.create({
  baseURL: process.env.REACT_APP_SASS_URL,
});

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.request.use(
  async function (config) {
    if (KeycloakApp?.isTokenExpired?.(5)) {
      await KeycloakApp.updateToken(5);
    }
    const AUTH_TOKEN = KeycloakApp?.token;
    config.headers = {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default API;
