import React from 'react';
import { Form, Input, InputNumber } from 'antd/es';

const FromToInput = React.memo(({ item }: { item: any }) => {
  return (
    <Form.Item label={item.label}>
      <Input.Group compact>
        <Form.Item
          name={[item.name, 'from']}
          noStyle
          rules={[{ required: item.required, message: '' }]}
        >
          <InputNumber
            placeholder="от"
            controls={false}
            min={item.min}
            max={item.max}
            step={1}
          />
        </Form.Item>
        <Form.Item
          name={[item.name, 'to']}
          rules={[{ required: item.required, message: '' }]}
          noStyle
        >
          <InputNumber
            placeholder="до"
            controls={false}
            min={item.min}
            max={item.max}
            step={1}
          />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
});
FromToInput.displayName = 'FromToInput';
export default FromToInput;
