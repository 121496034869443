import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { renderDate } from '../../../../utils/Base/helpers/renderDate';
import { SearchFieldsType } from '../../../../utils/Components/SearchPanel/types/SearchPanelTypes';

export const getInitialColumns = (): AbstractColumns<undefined> => {
  return [
    {
      title: 'Наименование',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
      visible: true,
    },
    {
      title: 'Серийный номер',
      key: 'serialNumber',
      dataIndex: 'serialNumber',
      align: 'center',
      visible: true,
    },
    {
      title: 'Год производства',
      key: 'yearOfProduction',
      dataIndex: 'yearOfProduction',
      align: 'center',
      visible: true,
    },
    {
      title: 'Рабочее состояние',
      key: 'workingCondition',
      dataIndex: 'workingCondition',
      align: 'center',
      visible: true,
    },
    {
      title: 'Компания производитель',
      key: 'brand',
      dataIndex: ['brand', 'title'],
      align: 'center',
      visible: true,
    },
    {
      title: 'Дата создания',
      key: 'createDate',
      dataIndex: ['createDate'],
      align: 'center',
      visible: true,
      render: renderDate,
    },
    {
      title: 'Описание',
      key: 'description',
      dataIndex: ['description'],
      align: 'center',
      visible: false,
    },
    {
      title: 'Комментарий',
      key: 'comment',
      dataIndex: ['comment'],
      align: 'center',
      visible: false,
    },
  ];
};

export const getSearchFields = (brandsOptions): SearchFieldsType[] => {
  return [
    {
      component: 'input',
      label: 'Наименование',
      name: 'name',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'input',
      label: 'Серийный номер',
      name: 'serialNumber',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'input',
      label: 'Модель',
      name: 'model',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'input',
      label: 'Рабочее состояние',
      name: 'workingCondition',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'input',
      label: 'Год производства',
      name: 'yearOfProduction',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'select',
      label: 'Компания производитель',
      name: 'brandTitle',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: brandsOptions,
    },
  ];
};
