import { NewPageData } from '../../../../AppTypes';
import { API_URLS } from '../../../../utils/API/urls';

export const setToDeviceData: NewPageData = {
  apiMethod: API_URLS.SET_TO_DEVICES,
  dataListName: 'SetOfDevicePage',
  idName: 'id',
  pageHeaderTitle: 'Наборы устройств',
  tabNameField: 'fullName',
  columnsName: 'SetToDeviceTable',
};
