import React, { useEffect, useMemo, useState } from 'react';
import {
  Location,
  Params,
  useLocation,
  useNavigationType,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ConfigProvider, Layout, theme } from 'antd/es';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/rootReducer';
import {
  updateAvatar,
  updateBackground,
} from '../../store/reducers/AuthReducer';
import { getFileImage } from '../../utils/Base/helpers/getFileImage';
import { setActiveKey } from '../../store/reducers/WorkplaceTabs';
import { isDesktop, isMobile } from 'react-device-detect';
import { routerProps } from '../../AppTypes';
import WorkplaceTabs from './Components/WorkplaceTabs';
import Note from './Components/Note';
import MobileLayout from './Components/MobileLayout';
import NotificationList from '../Notification/NotificationList';
import Footer from './Components/Footer';
import ruRu from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru';
import { useChangeTab } from './Components/helpers/useChangeTab';
import WorkplaceMenuF from './Components/WorkplaceMenuF';

export const RouterProvider = React.createContext({});

const { darkAlgorithm, defaultAlgorithm, compactAlgorithm } = theme;

/**
 * Функция отрисовки основного окружения сайта
 * @param {string} name
 * @param {any} permission
 * @constructor
 */
export default function WorkspaceLayout({ name, children }: any) {
  const { Content, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const location: Location = useLocation();
  const params: Params = useParams();
  const [_, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const navigate = useChangeTab();
  const urlFile = useAppSelector((state) => state.authReducer.backgroundUrl);
  const userBackgroundId = useAppSelector(
    (state) => state.authReducer.profile?.accountProfileData
  )?.userBackgroundId;
  const userAvatarId = useAppSelector(
    (state) => state.authReducer.profile?.accountProfileData
  )?.userAvatarId;
  const { darkTheme, bigMode } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (navigationType === 'POP') {
      dispatch(setActiveKey(location.pathname));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userBackgroundId) {
      getFileImage(userBackgroundId, updateBackground);
    }
    if (userAvatarId) {
      getFileImage(userAvatarId, updateAvatar);
    }
  }, [userBackgroundId, userAvatarId]);

  const theme = useMemo(() => {
    const result: any[] = [];
    if (darkTheme) {
      result.push(darkAlgorithm);
    } else {
      result.push(defaultAlgorithm);
    }
    if (!bigMode) {
      result.push(compactAlgorithm);
    }
    return result;
  }, [darkTheme, bigMode]);

  const routerProps: routerProps = {
    navigate,
    location,
    params,
    setSearchParams,
  };
  if (location?.state?.name) name = location.state.name;
  return (
    <RouterProvider.Provider value={routerProps}>
      <ConfigProvider
        locale={ruRu}
        theme={{
          algorithm: theme,
        }}
      >
        {isDesktop ? (
          <Layout>
            <Sider
              collapsible
              collapsed={collapsed}
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
              }}
              onCollapse={setCollapsed}
            >
              <div
                style={{
                  width: 80,
                  height: 44,
                  display: 'flex',
                  padding: 8,
                  alignSelf: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/favicon-32x32.png" alt={'logo'} />
              </div>
              <WorkplaceMenuF
                collapsed={collapsed}
                name={name}
                router={routerProps}
              />
            </Sider>
            <Layout
              className="site-layout"
              style={{
                minHeight: '100vh',
                backgroundImage: urlFile ? `url(${urlFile})` : 'none',
                backgroundSize: 'cover',
                marginLeft: isDesktop ? (collapsed ? 80 : 200) : 0,
                transition: 'all 0.2s',
              }}
            >
              <Content
                className={darkTheme ? 'site-layout-dark' : 'site-layout'}
                style={{ padding: '0 5px', marginTop: isMobile ? 50 : 0 }}
              >
                <WorkplaceTabs name={name}>{children}</WorkplaceTabs>
              </Content>
              <Footer />
              <Note />
            </Layout>
          </Layout>
        ) : (
          <MobileLayout routerProps={routerProps}>{children}</MobileLayout>
        )}
        <NotificationList navigate={navigate} />
      </ConfigProvider>
    </RouterProvider.Provider>
  );
}
