import React from 'react';
import { Button, Form, Select } from 'antd';
import { useOnCustomSelect } from '../../../../../utils/Base/hooks/useCustomSelect';
import { setToDeviceData } from '../../SetToDevices/setToDevicesData';
import { REQUIRED_RULES } from '../../../../../utils/Base/constants/requiredRule';
import { employeeDevicesData } from '../../employeeDevicesData';
import { NewFormProps } from '../../../../../AppTypes';
import { DeviceToEmployee } from '../../SetToDevices/DeviceToEmployee.type';

const DeviceToEmployeeForm = React.memo(
  ({
    onFinishAdd,
    isFetching,
  }: NewFormProps<DeviceToEmployee> & { type: 'add' }) => {
    const { selectProps } = useOnCustomSelect(
      setToDeviceData.apiMethod + '?number=',
      setToDeviceData.dataListName,
      'id',
      'number'
    );
    const { selectProps: selectPropsEmployee } = useOnCustomSelect(
      employeeDevicesData.apiMethod + '?fullName=',
      employeeDevicesData.dataListName,
      'id',
      'fullName'
    );
    const onFinish = (values) => {
      onFinishAdd!(values);
    };
    return (
      <Form layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'employeeId'}
          label={'Сотрудник'}
          rules={REQUIRED_RULES}
        >
          <Select {...selectPropsEmployee} placeholder={'ФИО'} />
        </Form.Item>
        <Form.Item
          name={'setOfDeviceId'}
          label={'Набор устройств'}
          rules={REQUIRED_RULES}
        >
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            Создать
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
DeviceToEmployeeForm.displayName = 'DeviceToEmployeeForm';
export default DeviceToEmployeeForm;
