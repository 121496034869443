import React from 'react';
import { Button, Result } from 'antd';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useChangeTab } from '../../../components/Layouts/Components/helpers/useChangeTab';
import { ResultStatusType } from 'antd/es/result';

const AbstractError = React.memo(
  ({ error }: { error: FetchBaseQueryError | SerializedError | undefined }) => {
    const errorStatusList = [500, 404, 403];

    const errorStatus = error
      ? 'status' in error && typeof error.status === 'number'
        ? errorStatusList.includes(error.status)
          ? error.status
          : error.status === 400
          ? 404
          : 'error'
        : 'error'
      : 'error';
    const changeTab = useChangeTab();

    const getDescription = () => {
      switch (errorStatus) {
        case 403:
          return 'Извините, у вас нет доступа к данному контенту.';
        case 404:
          return 'Извините, страница, которую вы посетили, не существует';
        case 400:
          return 'Извините, страница, которую вы посетили, не существует';
        default:
          return 'Произошла непредвиденная ошибка. Обратитесь к разработчикам';
      }
    };
    return (
      <Result
        status={errorStatus as ResultStatusType}
        title={errorStatus}
        subTitle={getDescription()}
        extra={
          <Button type="primary" onClick={() => changeTab('/')}>
            Вернуться домой
          </Button>
        }
      />
    );
  }
);
AbstractError.displayName = 'AbstractError';
export default AbstractError;
