import React, { useCallback, useEffect, useState } from 'react';
import { AbstractPageProps } from './type';
import { FloatButton, Form, Modal } from 'antd';
import { useOnRow } from '../../Base/hooks/useOnRow';
import { useFilterSearchFields } from './helpers/useFilterSearchFields';
import { getColumnWithSort } from './helpers/getColumnWithSort';
import DragNDropDrawer from '../DragNDropDrawer/DragNDropDrawer';
import AbstractDuplicate from './compenents/AbstractDuplicate';
import { getCurrentLink } from './helpers/getCurrentLink';
import { useGetAbstractDataQuery } from '../../../store/api/abstract.api';
import PageHeader from './compenents/PageHeader';
import AbstractSearchPanel from './compenents/AbstractSearchPanel';
import AbstractAdd from './compenents/AbstractAdd';
import AbstractEdit from './compenents/AbstractEdit';
import AbstractTable from './compenents/AbstractTable';
import get from 'lodash/get';
import { useChangeTab } from '../../../components/Layouts/Components/helpers/useChangeTab';
import ExcelUploadAbstractClass from '../ExcelUpload/ExcelUploadAbstractClass';
import AbstractError from '../AbstractError/AbstractError';

const AbstractPage = React.memo(
  ({
    pageHeaderTitle,
    apiMethod,
    pageHeaderExtraComponents,
    pageHeaderExtra,
    totalElements,
    rowSelect,
    additionalContent,
    searchFields,
    dataListName,
    expandable,
    rowClassName,
    idName,
    columns: initialColumns,
    EditComponent,
    DuplicateElement,
    AddComponent,
    createModalWidth,
    popupList,
    columnsName,
    uploadSettings,
    editModalWidth,
    formFields,
    scrollX,
    filterSearchFields,
    isListMutation,
    tabNameField,
    dbClickRoute = true,
  }: AbstractPageProps) => {
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isMultiUpload, setIsMultiUpload] = useState(false);
    const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const columns = getColumnWithSort(initialColumns);
    const changeTab = useChangeTab();
    const route =
      typeof dbClickRoute === 'string'
        ? dbClickRoute
        : window.location.pathname;
    const { popup, currentRow, onRow } = useOnRow<any>(
      dbClickRoute
        ? (record) =>
            changeTab(`${route}/${record?.[idName]}`, {
              state: {
                name:
                  typeof tabNameField === 'function'
                    ? tabNameField(currentRow)
                    : get(currentRow, tabNameField),
              },
            })
        : undefined
    );
    const path = getCurrentLink(apiMethod).replace('api/', '');
    const { data, refetch, error } = useGetAbstractDataQuery(`${path}`);
    const [dataSource, setDataSource] = useState<any[]>(
      data?.[dataListName]?.content
    );
    const [isAddRow, setIsAddRow] = useState(false);
    const [form] = Form.useForm();
    const [editRow, setEditRow] = useState<any>(null);
    const [modal, contextHolder] = Modal.useModal();

    const { getFilteredSearchFields, formatSearchFields } =
      useFilterSearchFields(searchFields, apiMethod);

    useEffect(() => {
      setDataSource(data?.[dataListName]?.content);
    }, [data]);

    const onSearchFinish = useCallback(() => {
      rowSelect?.onSelectRows([], []);
    }, []);

    const onAddRow = () => {
      setIsAddRow(true);
      form.resetFields();
      setDataSource((prevState) => [{}, ...prevState]);
      setEditRow(null);
    };

    const addRow = () => {
      if (editRow) {
        modal.confirm({
          title: 'Внимание!',
          content: 'У вас остались не сохраненные изменения. Продолжить?',
          onOk: onAddRow,
        });
      } else {
        onAddRow();
      }
    };

    const cancelAddRow = () => {
      setIsAddRow(false);
      setDataSource((prevState) => prevState.slice(1));
    };

    return error ? (
      <AbstractError error={error} />
    ) : (
      <>
        <PageHeader
          pageHeaderTitle={pageHeaderTitle}
          setIsAdd={setIsAdd}
          setIsEdit={setIsEdit}
          setIsMultiUpload={setIsMultiUpload}
          setIsVisibleDrawer={setIsVisibleDrawer}
          apiMethod={apiMethod}
          pageHeaderExtraComponents={pageHeaderExtraComponents}
          pageHeaderExtra={pageHeaderExtra}
          totalElements={totalElements ? data?.totalElements : undefined}
          dataListName={dataListName}
          isAddRow={isAddRow}
          addRow={formFields ? addRow : undefined}
        />
        {!!searchFields.length && (
          <div style={{ marginBottom: 8 }}>
            <AbstractSearchPanel
              onSearchFinish={onSearchFinish}
              apiMethod={apiMethod}
              searchFields={formatSearchFields}
              setIsAddRow={setIsAddRow}
              getFilteredSearchFields={
                filterSearchFields ? getFilteredSearchFields : undefined
              }
            />
            {additionalContent &&
              additionalContent?.map((item, index) => {
                return <div key={index}>{item}</div>;
              })}
          </div>
        )}
        <AbstractTable
          isListMutation={isListMutation}
          apiMethod={apiMethod}
          dataListName={dataListName}
          idName={idName}
          rowSelect={rowSelect}
          expandable={expandable}
          scrollX={scrollX}
          rowClassName={rowClassName}
          columns={columns}
          initialColumns={initialColumns}
          setIsEdit={setIsEdit}
          setIsDuplicate={setIsDuplicate}
          onRow={isAddRow || editRow ? undefined : onRow}
          currentRow={currentRow}
          popup={popup}
          popupList={popupList}
          dataSource={dataSource}
          isAddRow={isAddRow}
          cancelAddRow={cancelAddRow}
          columnsName={columnsName}
          formFields={formFields}
          form={form}
          setIsAddRow={setIsAddRow}
          editRow={editRow}
          setEditRow={setEditRow}
          getFilteredSearchFields={
            filterSearchFields ? getFilteredSearchFields : undefined
          }
        />
        <FloatButton.BackTop />
        <AbstractEdit
          isEdit={isEdit}
          currentRow={currentRow}
          idName={idName}
          setIsEdit={setIsEdit}
          EditComponent={EditComponent}
          editModalWidth={editModalWidth}
          apiMethod={apiMethod}
          getFilteredSearchFields={
            filterSearchFields ? getFilteredSearchFields : undefined
          }
        />
        <AbstractAdd
          isAdd={isAdd}
          setIsAdd={setIsAdd}
          createModalWidth={createModalWidth}
          apiMethod={apiMethod}
          getFilteredSearchFields={
            filterSearchFields ? getFilteredSearchFields : undefined
          }
        >
          {AddComponent}
        </AbstractAdd>
        <AbstractDuplicate
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
          width={editModalWidth}
          apiMethod={apiMethod}
          currentRow={currentRow}
          getFilteredSearchFields={
            filterSearchFields ? getFilteredSearchFields : undefined
          }
        >
          {DuplicateElement}
        </AbstractDuplicate>
        <DragNDropDrawer
          isVisibleDrawer={isVisibleDrawer}
          setIsVisibleDrawer={() => setIsVisibleDrawer(false)}
          initialColumns={initialColumns}
          columnsName={columnsName}
        />
        <ExcelUploadAbstractClass
          isVisible={isMultiUpload}
          setIsVisible={(item) => {
            setIsMultiUpload(item);
          }}
          fileName={uploadSettings?.fileName ?? ''}
          uri={uploadSettings?.uri ?? ''}
          afterDone={refetch}
          customRequest={uploadSettings?.customRequest}
        />
        {contextHolder}
      </>
    );
  }
);
AbstractPage.displayName = 'AbstractPage';
export default AbstractPage;
