import API from '../../../../utils/API/API';
import { API_URLS } from '../../../../utils/API/urls';
import { useState } from 'react';
import { useInvalidateAbstractData } from '../../../../utils/Base/hooks/useInvalidateAbstractData';
import { employeeDevicesData } from '../employeeDevicesData';
import { deviceToEmployeeData } from '../DeviceToEmployee/DeviceToEmployeeData';

export type SendInArchive = (values: {
  id: number;
  employeeId: number;
  isArchived: boolean;
}) => void;

export const useSendInArchiveDeviceToEmployee = () => {
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const invalidate = useInvalidateAbstractData();
  const invalidateEmployee = (id: number) =>
    invalidate(employeeDevicesData.apiMethod, id);
  const invalidateDeviceToEmployee = (id: number) =>
    invalidate(deviceToEmployeeData.apiMethod, id);

  const sendInArchive: SendInArchive = (values) => {
    setIsArchiveLoading(true);
    API.put(API_URLS.DEVICE_TO_EMPLOYEE, values)
      .then(() => {
        invalidateEmployee(values.employeeId);
        invalidateDeviceToEmployee(values.id);
      })
      .finally(() => setIsArchiveLoading(false));
  };

  return { sendInArchive, isArchiveLoading };
};
