import { SearchFieldsType } from '../../../utils/Components/SearchPanel/types/SearchPanelTypes';
import { AbstractColumns } from '../../../utils/Components/AbstractClass/AbstractClassTypes';
import { HistoryResume } from './HistoryResume.type';
import dayjs from 'dayjs';

export const searchFields: SearchFieldsType[] = [
  {
    component: 'input',
    label: 'ФИО',
    name: 'fullName',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'rangepicker',
    label: 'Дата',
    name: 'dateTime',
    placeholder: '',
    required: false,
    span: 4,
    isoString: true,
  },
];

export const initialColumns: AbstractColumns<HistoryResume> = [
  {
    title: 'Сотрудник',
    key: 'fullName',
    dataIndex: ['account', 'fullName'],
    align: 'center',
    visible: true,
  },
  {
    title: 'Кандидат',
    key: 'interviewer',
    dataIndex: ['interviewer', 'fullName'],
    align: 'center',
    visible: true,
  },
  {
    title: 'Дата',
    key: 'dateTime',
    dataIndex: 'dateTime',
    align: 'center',
    visible: true,
    render: (value) =>
      dayjs(value).utc(true).local().format('DD.MM.YYYY HH:mm'),
  },
];
