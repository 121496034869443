import { ResponseData } from '../../utils/API/APITypes';
import {
  EmployeeReportCard,
  StatisticReportCard,
} from '../../components/Pages/EmployeeRepostCard/types';

const SET_DATA_EMPLOYEE_REPORT_CARD = 'SET_DATA_EMPLOYEE_REPORT_CARD';
const SET_CONTENT_EMPLOYEE_REPORT_CARD = 'SET_CONTENT_EMPLOYEE_REPORT_CARD';
const SET_TABLE_DATA_EMPLOYEE_REPORT_CARD =
  'SET_TABLE_DATA_EMPLOYEE_REPORT_CARD';
const SET_STATISTIC_EMPLOYEE_REPORT_CARD = 'SET_STATISTIC_EMPLOYEE_REPORT_CARD';

export declare type DataEmployeeReportCard = {
  success: boolean;
  employeeReportCardDataList: ResponseData<EmployeeReportCard>;
};

export declare type EmployeeReportCardReducer = {
  data: DataEmployeeReportCard | null;
  content: Array<EmployeeReportCard> | null;
  tableData: any;
  statistic: StatisticReportCard | null;
};

/**
 * @param {DataEmployeeReportCard} data
 * @return {any}
 */
export function setDataEmployeeReportCard(data) {
  return {
    type: SET_DATA_EMPLOYEE_REPORT_CARD,
    payload: data,
  };
}

/**
 * @param {Array<DataEmployeeReportCard>} data
 * @return {any}
 */
export function setContentEmployeeReportCard(data) {
  return {
    type: SET_CONTENT_EMPLOYEE_REPORT_CARD,
    payload: data,
  };
}

/**
 * @param {any} data
 * @return {any}
 */
export function setTableDataEmployeeReportCard(data) {
  return {
    type: SET_TABLE_DATA_EMPLOYEE_REPORT_CARD,
    payload: data,
  };
}

export function setStatisticReportCard(data) {
  return {
    type: SET_STATISTIC_EMPLOYEE_REPORT_CARD,
    payload: data,
  };
}

const initialState: EmployeeReportCardReducer = {
  data: null,
  content: null,
  tableData: null,
  statistic: null,
};

export function employeeReportCardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_EMPLOYEE_REPORT_CARD:
      return { ...state, data: action.payload };
    case SET_CONTENT_EMPLOYEE_REPORT_CARD:
      return { ...state, content: action.payload };
    case SET_TABLE_DATA_EMPLOYEE_REPORT_CARD:
      return { ...state, tableData: action.payload };
    case SET_STATISTIC_EMPLOYEE_REPORT_CARD:
      return { ...state, statistic: action.payload };
    default:
      return state;
  }
}
