import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KeycloakApp } from '../utils/keycloak/KeycloakApp';

const uri = process.env.REACT_APP_PRODUCTION_URL;

// Define a service using a base URL and expected endpoints
export const commonApi = createApi({
  reducerPath: 'commonApi ',
  tagTypes: ['AbstractData', 'SearchParams'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${uri}/api`,
    prepareHeaders: async (headers) => {
      const keycloak = KeycloakApp;
      if (keycloak?.isTokenExpired?.(5)) {
        await keycloak.updateToken(5);
      }
      headers.set('Authorization', 'Bearer ' + keycloak.token);
    },
  }),
  endpoints: (_) => ({}),
  keepUnusedDataFor: 300,
});
