import React, { useEffect, useState } from 'react';
import { FloatButton, Popover } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const Note = React.memo(() => {
  const [open, setOpen] = useState(true);
  const [noteText, setNoteText] = useState('');

  const color = '#ffffb8';

  useEffect(() => {
    if (open) {
      const text = localStorage.getItem('note_text') ?? '';
      setNoteText(text);
    } else {
      localStorage.setItem('note_text', noteText ?? '');
    }
  }, [open]);

  return (
    <Popover
      placement="left"
      title="Заметка"
      trigger={'click'}
      onOpenChange={setOpen}
      content={
        <TextArea
          style={{ height: 100, width: 250, resize: 'none' }}
          bordered={false}
          maxLength={100}
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          placeholder="Текст..."
        />
      }
      style={{ marginBottom: 20 }}
      arrow={false}
      color={color}
    >
      <FloatButton badge={{ dot: !!noteText }} />
    </Popover>
  );
});
Note.displayName = 'Note';
export default Note;
