import { Form, Select } from 'antd/es';
import React from 'react';
import { useDictionarySelectOptions } from '../../Base/hooks/useDictionarySelectOptions';
import { SelectProps } from 'antd/es/select';
import { DictionaryData } from '../Dictionary/types';
import { SearchFieldsType } from './types/SearchPanelTypes';

const SelectOptionDictionary = React.memo(
  ({
    selectProps,
    dictionaryData,
    selectSettings,
  }: {
    selectProps: SelectProps;
    dictionaryData: DictionaryData;
    selectSettings: SearchFieldsType;
  }) => {
    const dictionaryOptions = useDictionarySelectOptions(dictionaryData);
    return (
      <Form.Item label={selectSettings.label} name={selectSettings.name}>
        <Select
          {...selectProps}
          filterOption={true}
          optionFilterProp={'label'}
          optionLabelProp={'label'}
          options={dictionaryOptions.map((option) => ({
            ...option,
            value: option.value.toString(),
          }))}
        />
      </Form.Item>
    );
  }
);
SelectOptionDictionary.displayName = 'SelectOptionDictionary';
export default SelectOptionDictionary;
