import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
  Switch,
} from 'antd';
import {
  SearchFieldsType,
  SearchPanelPropsTypes,
  SearchPanelState,
} from './types/SearchPanelTypes';
import type { FormInstance } from 'antd/es';
import dayjs, { Dayjs } from 'dayjs';
import FromToInput from './FromToInput';
import { connect } from 'react-redux';
import SelectSearchPanel from './SelectSearchPanel';
import API from '../../API/API';

/**
 * @class
 * @extends React.Component
 */
class SearchPanel extends React.PureComponent<
  SearchPanelPropsTypes,
  SearchPanelState
> {
  formRef = React.createRef<FormInstance>();
  searchRef = React.createRef<HTMLElement>();

  /**
   * @param {SearchPanelPropsTypes} props
   */
  constructor(props: SearchPanelPropsTypes) {
    super(props);

    this.state = {
      isFetching: false,
      rangeName: props.searchFields
        .filter((el) => el.component.toLowerCase() === 'rangepicker')
        .map((el) => el.name),
      dateName: props.searchFields
        .filter((el) => el.component.toLowerCase() === 'datepicker')
        .map((el) => el.name),
      dateTimeName: props.searchFields
        .filter((el) => el.component.toLowerCase() === 'datetimepicker')
        .map((el) => el.name),
      loading: false,
      selectItems: [],
      allValues: {},
      formFields: [],
    };
  }

  /**
   * Получение параметров поиска
   */
  componentDidMount() {
    const { initialValues } = this.props;
    this.setFormFields(initialValues);
  }

  /**
   * Обработка данных из формы
   * @param {any} value
   */
  onFinish = async (value) => {
    const self = this;
    const { searchFields, link } = this.props;
    const { dateName, rangeName, dateTimeName } = this.state;
    dateName.map((item) => {
      if (value && item && value[item]) {
        value[item] = dayjs(value[item]).format('YYYY-MM-DD');
      }
    });

    dateTimeName.map((item) => {
      if (value && item && value[item]) {
        value[item] = dayjs(value[item]).format('YYYY-MM-DD');
      }
    });
    rangeName.forEach((item) => {
      if (value && item && value[item]) {
        if (searchFields.find((field) => field.name === item)!.isoString) {
          const from = value[item][0]
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .toISOString();
          const to = value[item][1]
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .toISOString();
          value[item] = [from, to];
        } else {
          value[item] = [
            value[item][0].format('YYYY-MM-DD'),
            value[item][1].format('YYYY-MM-DD'),
          ];
        }
      }
    });
    const searchParams = new URLSearchParams();

    // eslint-disable-next-line guard-for-in
    for (const item in value) {
      if (value[item] !== null && value[item] !== undefined) {
        const currentField = searchFields.find((field) => field.name === item);
        if (typeof value[item] === 'string') {
          value[item].trim();
          if (value[item].length !== 0) {
            searchParams.set(item, `${value[item]}`);
          }
        } else if (currentField?.falseIsNull) {
          value[item] && searchParams.set(item, value[item]);
        } else if (typeof value[item] === 'object' && 'value' in value[item]) {
          if (value[item].title?.length)
            searchParams.set(item, `${value[item]['value']}`);
        } else if (typeof value[item] === 'object' && 'from' in value[item]) {
          const newValue: number[] = Object.values(value[item]);
          const rawValue: number[] = [];
          if (newValue[0] == null && newValue[1] == null) {
            continue;
          }
          if (newValue[0] == null && newValue[1] != null) {
            rawValue.push(currentField!.min!, newValue[1]);
          } else if (newValue[1] == null && newValue[0] != null) {
            rawValue.push(newValue[0], currentField!.max!);
          }
          searchParams.set(item, `${rawValue.length ? rawValue : newValue}`);
        } else if (value[item].length !== 0) {
          searchParams.set(item, `${value[item]}`);
        }
      }
    }
    await this.props.onLoad(searchParams);
    if (link) {
      this.setState({ isFetching: true });
      self.sendResponse(searchParams);
    }
  };

  /**
   * @param {URLSearchParams} searchParams
   */
  sendResponse = (searchParams) => {
    const { link, onFinish, getCurrentLink } = this.props;
    const self = this;

    API.get(
      `${
        getCurrentLink
          ? getCurrentLink()
          : `${link!.includes('?') ? link : link + '?'}${searchParams}`
      }`.replaceAll('+', ' ')
    )
      .then(function (response) {
        onFinish?.(response);
        self.setState({ isFetching: false });
      })
      .catch(function (error) {
        self.setState({ isFetching: false });
        onFinish?.(error);
      });
  };

  /**
   * Блокировка недоступных дат
   * @param {any} current
   * @return {any}
   */
  disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  /**
   * Рендер полей поиска
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  inputMethod = (item, index) => {
    const { allValues } = this.state;
    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
        >
          <Input
            placeholder={item.placeholder}
            style={{ width: '100%' }}
            disabled={item.disabled ? item.disabled(allValues) : false}
          />
        </Form.Item>
      </Col>
    );
  };

  /**
   * Обработка результата числового ввода
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  inputNumberMethod = (item, index) => {
    const { allValues } = this.state;

    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
        >
          <InputNumber
            disabled={item.disabled ? item.disabled(allValues) : false}
            placeholder={item.placeholder}
            parser={(value) => value?.replace(',', '.')}
            min={item.min ? item.min : null}
            max={item.max ? item.max : null}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );
  };

  /**
   * Метод диапозона дат
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  rangePickerMethod = (item, index) => {
    const { RangePicker } = DatePicker;
    const { allValues } = this.state;

    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
        >
          <RangePicker
            disabledDate={this.disabledDate}
            format={'DD.MM.YYYY'}
            style={{ width: '100%' }}
            disabled={item.disabled ? item.disabled(allValues) : undefined}
          />
        </Form.Item>
      </Col>
    );
  };

  /**
   * Выбор даты
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  datePickerMethod = (item, index) => {
    const { allValues } = this.state;

    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
        >
          <DatePicker
            disabledDate={this.disabledDate}
            disabled={item.disabled ? item.disabled(allValues) : false}
            format={'DD.MM.YYYY'}
            style={{ width: '100%' }}
            picker={item.picker}
          />
        </Form.Item>
      </Col>
    );
  };

  /**
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  dateTimePickerMethod = (item, index) => {
    const { allValues } = this.state;

    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
        >
          <DatePicker
            disabled={item.disabled ? item.disabled(allValues) : false}
            disabledDate={this.disabledDate}
            format={'DD.MM.YYYY'}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );
  };

  /**
   * Чекбокс
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  checkBoxMethod = (item, index) => {
    const { allValues } = this.state;
    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
        >
          <Checkbox disabled={item.disabled ? item.disabled(allValues) : false}>
            {item.label}
          </Checkbox>
        </Form.Item>
      </Col>
    );
  };

  /**
   * Переключатель
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  switchMethod = (item, index) => {
    const { allValues } = this.state;

    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <Form.Item
          label={item.label}
          name={item.name}
          rules={[{ required: item.required, message: '' }]}
          key={index + item.label}
          valuePropName="checked"
        >
          <Switch disabled={item.disabled ? item.disabled(allValues) : false} />
        </Form.Item>
      </Col>
    );
  };

  /**
   * Выбор варианта
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  selectMethod = (item, index) => {
    const { allValues } = this.state;
    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <SelectSearchPanel
          item={item}
          disabled={item.disabled ? item.disabled(allValues) : false}
        />
      </Col>
    );
  };
  /**
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  inputGroupMethod(item, index) {
    return (
      <Col xs={24} md={item.span ? item.span : 6} key={index + item.name}>
        <FromToInput item={item} />
      </Col>
    );
  }

  /**
   * Выбор типа поля ввода
   * @param {any} item
   * @param {number} index
   * @return {JSX}
   */
  inputVariable = (item: SearchFieldsType, index: number) => {
    switch (item.component.toLowerCase()) {
      case 'inputnumber':
        return this.inputNumberMethod(item, index);
      case 'rangepicker':
        return this.rangePickerMethod(item, index);
      case 'datepicker':
        return this.datePickerMethod(item, index);
      case 'datetimepicker':
        return this.dateTimePickerMethod(item, index);
      case 'checkbox':
        return this.checkBoxMethod(item, index);
      case 'switch':
        return this.switchMethod(item, index);
      case 'select':
        return this.selectMethod(item, index);
      case 'inputgroup':
        return this.inputGroupMethod(item, index);
      default:
        return this.inputMethod(item, index);
    }
  };

  clearForm = async () => {
    await this.formRef.current!.resetFields();
    await this.searchRef.current!.click();
  };

  /**
   * Метод для сохранения изменений формы
   * @param {Object} allValues
   */
  setFormFields = (allValues?) => {
    const { searchFields } = this.props;

    const formFields: {
      name: string | string[];
      value: string | number | Dayjs;
    }[] = [];

    for (const i in searchFields) {
      if (searchFields[i] !== null && searchFields[i] !== undefined) {
        const { name } = searchFields[i];

        const componentName = searchFields[i].component.toLowerCase();
        const searchFieldValue = allValues?.[name];
        if (componentName === 'inputgroup') {
          const [from, to] = searchFieldValue
            ? typeof searchFieldValue === 'string'
              ? searchFieldValue.split(',')
              : Object.values(searchFieldValue)
            : [null, null];

          formFields.push(
            // @ts-ignore
            { name: [name, 'from'], value: from },
            { name: [name, 'to'], value: to }
          );
          continue;
        }
        if (
          searchFields[i].selectMode === 'multiple' ||
          searchFields[i].selectMode === 'tags'
        ) {
          formFields.push({
            name: name,
            value:
              typeof searchFieldValue === 'string'
                ? searchFieldValue.split(',')
                : searchFieldValue,
          });
        } else if (componentName === 'rangepicker') {
          formFields.push({
            name: name,
            value:
              typeof searchFieldValue === 'string'
                ? searchFieldValue.split(',').map((item) => {
                    return dayjs(item);
                  })
                : searchFieldValue,
          });
        } else if (
          componentName === 'datepicker' ||
          componentName === 'datetimepicker'
        ) {
          if (searchFields[i].picker) {
            formFields.push({
              name,
              value:
                typeof searchFieldValue === 'string'
                  ? dayjs(searchFieldValue).get(searchFields[i].picker!)
                  : searchFieldValue,
            });
          } else {
            formFields.push({
              name: name,
              value:
                typeof searchFieldValue === 'string'
                  ? dayjs(searchFieldValue)
                  : searchFieldValue,
            });
          }
        } else {
          formFields.push({
            name: name,
            value: searchFieldValue,
          });
        }
      }
    }

    this.setState({ formFields: formFields });
  };

  /**
   * Рендер компонента
   * @return {JSX}
   */
  render() {
    const { searchFields, loadingBtn } = this.props;
    const { loading, formFields } = this.state;

    return (
      <Spin spinning={loading}>
        <Form
          name="basic"
          onFinish={this.onFinish}
          layout="vertical"
          fields={formFields}
          ref={this.formRef}
          onValuesChange={(changedValues, allValues) => {
            this.setState({ allValues: allValues }, () => {
              this.setFormFields(allValues);
            });
          }}
        >
          <Row key={'row'} gutter={16}>
            {searchFields.map((item, index) => {
              return this.inputVariable(item, index);
            })}
          </Row>

          <Row gutter={16} justify="end" align={'middle'}>
            <Col>
              <Button htmlType="reset" onClick={this.clearForm}>
                Сброс
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.isFetching || loadingBtn}
                // @ts-ignore
                ref={this.searchRef}
              >
                Поиск
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTab: state.workplaceTabsReducer.currentTab,
});

export default connect(mapStateToProps)(SearchPanel);
