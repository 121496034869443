import React from 'react';
import { Button, List, Popconfirm, Spin, Tooltip } from 'antd';
import {
  openList,
  setCurrentNotification,
  setVisibleNotification,
} from '../../../store/reducers/NotificationsReducer';
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons/es/icons';
import { connect } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import { notificationPermission } from '../Notification.permission';

class NotificationListComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      notificationsList: null,
    };
  }

  render() {
    const { notificationsList, loading, permissionList } = this.props;
    return (
      <Spin spinning={loading}>
        <List
          itemLayout={isDesktop ? 'horizontal' : 'vertical'}
          dataSource={notificationsList}
          size="large"
          renderItem={(item: any) => {
            let rawDate = dayjs(item.date).valueOf();
            let currentDate = dayjs(new Date()).valueOf();

            return (
              <List.Item
                actions={
                  permissionList.includes(notificationPermission.all)
                    ? [
                        <Tooltip
                          title={`Перейти к ${item.interviewer.firstName} ${item.interviewer.lastName}`}
                        >
                          <Button
                            icon={<UserOutlined />}
                            onClick={() => {
                              this.props.dispatch(openList(false));
                              this.props.navigate(
                                `/candidates/${item.interviewer.id}`
                              );
                            }}
                          />
                        </Tooltip>,
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                            this.props.successEdit(true, item);
                          }}
                        />,
                        <Popconfirm
                          title="Вы уверены, что хотите удалить это напоминание?"
                          okText="Да"
                          cancelText="Отмена"
                          onConfirm={() => {
                            this.props.onDelete(item.id);
                          }}
                          disabled={currentDate > rawDate}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            disabled={currentDate > rawDate}
                          />
                        </Popconfirm>,
                        <>
                          {currentDate > rawDate &&
                            (isDesktop &&
                            permissionList.includes(
                              notificationPermission.one
                            ) ? (
                              <Button
                                type={'primary'}
                                onClick={() => {
                                  this.props.dispatch(
                                    setCurrentNotification(item)
                                  );
                                  this.props.dispatch(
                                    setVisibleNotification(true)
                                  );
                                }}
                              >
                                Открыть напоминание
                              </Button>
                            ) : (
                              <Button
                                icon={<EyeOutlined />}
                                onClick={() => {
                                  this.props.dispatch(
                                    setCurrentNotification(item)
                                  );
                                  this.props.dispatch(
                                    setVisibleNotification(true)
                                  );
                                }}
                                type={'primary'}
                              />
                            ))}
                        </>,
                      ]
                    : undefined
                }
              >
                <List.Item.Meta
                  avatar={
                    item.type === 'Call' ? (
                      <h1>
                        <PhoneOutlined />
                      </h1>
                    ) : (
                      <h1>
                        <MailOutlined />
                      </h1>
                    )
                  }
                  title={item.text}
                  description={`${dayjs(item.date).format(
                    'DD.MM.YYYY HH:mm'
                  )} | ${item.interviewer.firstName} ${
                    item.interviewer.lastName
                  } | ${item.interviewer.phoneNumber}`}
                />
              </List.Item>
            );
          }}
        />
      </Spin>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    visibleList: state.notificationsReducer.visibleList,
    notificationsList: state.notificationsReducer.notificationsList,
    profile: state.authReducer.profile,
    permissionList: state.authReducer.permissionList,
  };
};

export default connect(mapStateToProps)(NotificationListComponent);
