import { Tag } from 'antd/es';
import React from 'react';
import { Employee } from '../type';

export const descriptions = [
  { label: 'ФИО', fieldName: 'fullName', span: 4 },
  {
    label: 'Табельный номер',
    fieldName: 'serviceNumber',
    span: 4,
    render: (value: string) => <Tag>{value}</Tag>,
  },
  {
    label: 'Телефон',
    fieldName: 'phoneNumber',
    span: 4,
  },
  {
    label: 'Email',
    fieldName: 'email',
    span: 4,
  },
  {
    label: 'Организация',
    fieldName: 'organization',
    span: 4,
    render: (value) => value?.title ?? '',
  },
  {
    label: 'Должность',
    fieldName: 'position',
    span: 4,
    render: (value) => value?.title ?? '',
  },
  {
    label: 'Место работы',
    fieldName: 'workplace',
    span: 4,
    render: (value) => value?.title ?? '',
  },
  {
    label: 'Проекты',
    fieldName: 'projectSet',
    span: 4,
    render: (value) => (value ? value.map((el) => el.title).join(', ') : ''),
  },
  {
    label: 'Отдел',
    fieldName: 'departmentsDirectionWorkBond',
    span: 4,
    render: (value: Employee['departmentsDirectionWorkBond']) =>
      value?.department?.title ?? '',
  },
  {
    label: 'Направление',
    fieldName: 'departmentsDirectionWorkBond',
    span: 4,
    render: (value: Employee['departmentsDirectionWorkBond']) =>
      value?.workDirection?.title ?? '',
  },
  {
    label: 'Поднаправление',
    fieldName: 'departmentsDirectionWorkBond',
    span: 4,
    render: (value: Employee['departmentsDirectionWorkBond']) =>
      value?.subWorkDirection?.title ?? '',
  },
  {
    label: 'Статус работы',
    fieldName: 'workStatus',
    span: 4,
    render: (value) => value?.description ?? '',
  },
  {
    label: 'Метод работы',
    fieldName: 'workingMethod',
    span: 4,
    render: (value) => value?.title ?? '',
  },
  {
    label: 'Гражданство',
    fieldName: 'citizenship',
    span: 4,
    render: (value) => value?.title ?? '',
  },
];
