export const getSearchParams = (searchParams: URLSearchParams, sorter) => {
  let params = searchParams.toString();

  if (sorter?.order === 'ascend' || sorter?.order === 'descend') {
    params += `sort=${sorter.columnKey}${
      sorter.order === 'ascend' ? '%2Casc' : '%2Cdesc'
    }`;
  }
  return params;
};
