import API from '../../API/API';
import store from '../../../store/store';
import { setImgSrc } from '../../../store/reducers/AuthReducer';

export const getFileImage = (id, update) => {
  API.get(`api/file/download/${id}`, {
    responseType: 'blob',
  }).then((res) => {
    const file: Blob = res.data;
    const fileUrl = URL.createObjectURL(file);
    store.dispatch(update(fileUrl));
    store.dispatch(setImgSrc([id, fileUrl]));
  });
};
