import isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';
import { useGetAbstractDataQuery } from '../../../../store/api/abstract.api';
import { getCurrentLink } from './getCurrentLink';

export const usePaginationSetting = (
  apiMethod: string,
  dataListName: string
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const path = getCurrentLink(apiMethod).replace('api/', '');
  const { data } = useGetAbstractDataQuery(`${path}`);

  const content = data?.[dataListName];

  const paramsUrl = new URLSearchParams(searchParams);

  const paramPage = paramsUrl.get('page');
  const paramSize = paramsUrl.get('size');

  const pagination = {
    showSizeChanger: true,
    // @ts-ignore
    total: content?.totalElements,
    current: paramPage ? +paramPage + 1 : 1,
    pageSize: paramSize ? +paramSize : 10,
    pageSizeOptions: [10, 20, 50, 100, 250],
  };

  const onChangePagination = (pagination) => {
    const param = new URLSearchParams(paramsUrl);
    param.set('page', (pagination.current - 1).toString());
    param.set('size', pagination.pageSize.toString());

    setSearchParams(param);
  };

  const onChangeSorter = (sorter) => {
    const params = new URLSearchParams(searchParams);

    if (sorter.order) {
      params.set(
        'sort',
        `${sorter.columnKey},${sorter.order === 'ascend' ? 'asc' : 'desc'}`
      );
    } else {
      params.delete('sort');
    }

    setSearchParams(params);
  };

  const onChangeTable = (data) => {
    const params = new URLSearchParams(searchParams);
    const page = params.get('page');
    const size = params.get('size');

    if (
      (!isEmpty(data.pagination) &&
        data.pagination.current - 1 !== (page ? +page : null)) ||
      data.pagination.pageSize !== (size ? +size : null)
    ) {
      onChangePagination(data.pagination);
    }
    if (!isEmpty(data.sorter)) onChangeSorter(data.sorter);
  };

  const onChange = (pagination, filers, sorter) => {
    onChangeTable({ pagination: pagination, sorter: sorter });
  };

  return { pagination, onChange };
};
