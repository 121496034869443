import { DictionaryType } from './Dictionary';
import {
  setContentOrganization,
  setDataOrganization,
} from '../../../store/reducers/Organization';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../AbstractClass/AbstractClassTypes';
import { PlusOutlined } from '@ant-design/icons/es/icons';
import React from 'react';
import { getPopupList } from '../Popup/PopupExtra';
import {
  setContentWorkingMethod,
  setDataWorkingMethod,
} from '../../../store/reducers/WorkingMethod';
import {
  citizenshipReducer,
  setContentCitizenship,
  setDataCitizenship,
} from '../../../store/reducers/Citizenship';
import {
  setContentSubworkDirection,
  setDataSubworkDirection,
} from '../../../store/reducers/SubworkDirection';
import { DictionaryData, OnceRecordProps } from './types';
import ColorForm from './components/ColorForm';
import {
  setInterviewDecision,
  setPositions,
  setProjects,
  setRefusals,
  setWorkplace,
} from '../../../store/reducers/Dictionaries';
import { PermissionPage } from '../../../AppTypes';

export const pageHeaderExtraDefault = (
  addPermmision: string
): Array<AbstractClassPageHeaderExtraButtonItem> => [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    permission: addPermmision,
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
];

export const getSearchFields = (name = 'title') => [
  {
    component: 'Input',
    label: 'Наименование',
    name: name,
    placeholder: '',
    required: false,
    span: 6,
  },
];

export const getInitialColumns = (name = 'title'): AbstractColumns => [
  {
    title: 'Наименование',
    dataIndex: name,
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

export const description = [
  {
    label: 'Название',
    fieldName: 'title',
    span: 1,
  },
  {
    label: 'Описание',
    fieldName: 'description',
    span: 2,
  },
  {
    label: 'Цвет',
    fieldName: 'color',
    span: 2,
    render: (item) => (
      <div
        style={{
          height: 20,
          width: 80,
          backgroundColor: item,
          borderRadius: 4,
        }}
      ></div>
    ),
  },
];

export const projectsDictionaryProps: DictionaryType = {
  fields: [
    {
      name: 'projectName',
      label: 'Наименование',
    },
  ],
  columns: [
    {
      key: 'projectName',
      dataIndex: 'projectName',
      align: 'center',
      title: 'Проекты',
      visible: true,
    },
  ],
  reducerName: 'projects',
  api: '/api/projects',
  dataListName: 'projectList',
  pageHeaderTitle: 'Проекты',
  setData: setProjects,
};

export const workplacesDictionaryProps: DictionaryType = {
  fields: [
    {
      name: 'workplaceName',
      label: 'Наименование',
    },
  ],
  columns: [
    {
      key: 'role',
      dataIndex: 'workplaceName',
      align: 'center',
      title: 'Место работы',
      visible: true,
    },
  ],
  reducerName: 'workplace',
  api: '/api/references/workplace',
  dataListName: 'workplaceList',
  pageHeaderTitle: 'Места работы',
  setData: setWorkplace,
};

export const positionsDictionaryProps: DictionaryType = {
  fields: [
    {
      name: 'positionsName',
      label: 'Наименование',
    },
  ],
  columns: [
    {
      key: 'role',
      dataIndex: 'positionsName',
      align: 'center',
      title: 'Должность',
      visible: true,
    },
  ],
  reducerName: 'positions',
  api: '/api/references/positions',
  dataListName: 'positionsList',
  pageHeaderTitle: 'Должности',
  setData: setPositions,
};

export const refusalsDictionaryProps: DictionaryType = {
  fields: [
    {
      name: 'refusalName',
      label: 'Наименование',
    },
  ],
  columns: [
    {
      key: 'refusalName',
      dataIndex: 'refusalName',
      align: 'center',
      title: 'Причина отказа',
      visible: true,
    },
  ],
  reducerName: 'refusals',
  api: '/api/references/refusals',
  dataListName: 'refusalList',
  pageHeaderTitle: 'Причины отказа',
  setData: setRefusals,
};

export const resultInterviewDictionaryProps: DictionaryType = {
  fields: [
    {
      name: 'title',
      label: 'Наименование',
    },
    {
      name: 'description',
      label: 'Описание',
    },
  ],
  columns: [
    {
      key: 'title',
      dataIndex: 'title',
      align: 'center',
      title: 'Наименование',
      visible: true,
    },
    {
      key: 'description',
      dataIndex: 'description',
      align: 'center',
      title: 'Описание',
      visible: true,
    },
  ],
  reducerName: 'interviewDecision',
  api: 'api/references/interviewersDecision',
  dataListName: 'interviewersDecisionData',
  pageHeaderTitle: 'Результаты интервью',
  setData: setInterviewDecision,
};

export const organizationPermission: PermissionPage = {
  all: 'getAllOrganization',
  create: 'createOrganization',
  update: 'updateOrganization',
  one: 'getOrganization',
  delete: 'deleteOrganization',
};

export const organizationDictionaryData: DictionaryData = {
  apiMethod: 'api/organizations',
  dataListName: 'organizationList',
  idName: 'id',
  pageHeaderTitle: 'Организации',
  tabNameField: 'title',
  reducer: 'organizationReducer',
  columnsName: 'OrganizationTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList(organizationPermission),
  setData: setDataOrganization,
  setContent: setContentOrganization,
  permission: organizationPermission,
};

export const onceOrganizationProps = {
  idName: 'id',
  apiMethod: 'api/organizations',
  dataListName: 'organization',
  name: 'title',
  permission: organizationPermission,
};

export const workingMethodPermission: PermissionPage = {
  create: 'createWorkingMethod',
  delete: 'deleteWorkingMethod',
  one: 'getWorkingMethod',
  update: 'updateWorkingMethod',
  all: 'getAllWorkingMethod',
};

export const workingMethodDictionaryData = {
  apiMethod: 'api/working-method',
  dataListName: 'workingMethodList',
  idName: 'id',
  pageHeaderTitle: 'Методы работы',
  tabNameField: 'title',
  reducer: 'workingMethodReducer',
  columnsName: 'WorkingMethodsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList(workingMethodPermission),
  setData: setDataWorkingMethod,
  setContent: setContentWorkingMethod,
  permission: workingMethodPermission,
};

export const onceWorkingMethodProps = {
  idName: 'id',
  apiMethod: 'api/working-method',
  dataListName: 'workingMethod',
  name: 'title',
  permission: workingMethodPermission,
};

export const citizenshipPermission: PermissionPage = {
  update: 'updateCitizenship',
  delete: 'deleteCitizenship',
  one: 'getCitizenship',
  create: 'createCitizenship',
  all: 'getAllCitizenship',
};

export const citizenshipDictionaryData = {
  apiMethod: 'api/citizenship',
  dataListName: 'citizenshipList',
  idName: 'id',
  pageHeaderTitle: 'Список гражданств',
  tabNameField: 'title',
  reducer: 'citizenshipReducer',
  columnsName: 'CitizenshipTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList(citizenshipPermission),
  setData: setDataCitizenship,
  setContent: setContentCitizenship,
  permission: citizenshipPermission,
};

export const onceCitizenshipProps = {
  idName: 'id',
  apiMethod: 'api/citizenship',
  dataListName: 'citizenship',
  name: 'title',
  permission: citizenshipPermission,
};

export const subWorkDirectionPermission: PermissionPage = {
  all: 'getAllDepartmentDirectionWorkBond',
  one: 'getDepartmentDirectionWorkBond',
  update: 'updateDepartmentDirectionWorkBond',
  create: 'createDepartmentDirectionWorkBond',
  delete: 'deleteDepartmentDirectionWorkBond',
};

export const subWorkDirectionDictionaryData: DictionaryData = {
  apiMethod: 'api/references/sub-work-direction',
  dataListName: 'subWorkDirectionList',
  idName: 'id',
  pageHeaderTitle: 'Поднаправления работ',
  tabNameField: 'title',
  reducer: 'subworkDirectionReducer',
  columnsName: 'SubworkDirectionTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList(subWorkDirectionPermission),
  setData: setDataSubworkDirection,
  setContent: setContentSubworkDirection,
  addComponent: <ColorForm type={'add'} idName={'subWorkDirectionId'} />,
  editComponent: <ColorForm type={'edit'} idName={'subWorkDirectionId'} />,
  permission: subWorkDirectionPermission,
};

export const onceSubWorkDirectionProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: 'api/references/sub-work-direction',
  dataListName: 'subWorkDirection',
  name: 'title',
  descriptions: description,
  editComponent: <ColorForm type={'edit'} idName={'subWorkDirectionId'} />,
  permission: subWorkDirectionPermission,
};

export const getMobileItem = (
  content: any,
  dictionaryProps: DictionaryData
) => ({
  title: content[dictionaryProps.name],
  descriptions: [{ title: '', text: content.description }],
});
