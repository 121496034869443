import { Dictionary } from '../../AppTypes';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_KEY_SKILLS = 'SET_DATA_KEY_SKILLS';
const SET_CONTENT_KEY_SKILLS = 'SET_CONTENT_KEY_SKILLS';

export declare type DataKeySkills = {
  success: boolean;
  DataList: ResponseData<Dictionary>;
};

export declare type KeySkillsReducer = {
  data: DataKeySkills | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {DataKeySkills} data
 * @return {any}
 */
export function setDataKeySkills(data) {
  return {
    type: SET_DATA_KEY_SKILLS,
    payload: data,
  };
}

/**
 * @param {Array<DataKeySkills>} data
 * @return {any}
 */
export function setContentKeySkills(data) {
  return {
    type: SET_CONTENT_KEY_SKILLS,
    payload: data,
  };
}

const initialState: KeySkillsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function keySkillsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_KEY_SKILLS:
      return { ...state, data: action.payload };
    case SET_CONTENT_KEY_SKILLS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
