import { ResponseData } from '../../utils/API/APITypes';
import { Questionnaire } from '../../components/Pages/Questionnaire/QuestionnaireType';

const SET_DATA_QUESTIONNAIRE = 'SET_DATA_QUESTIONNAIRE';
const SET_CONTENT_QUESTIONNAIRE = 'SET_CONTENT_QUESTIONNAIRE';

export declare type DataQuestionnaire = {
  success: boolean;
  questionnaireResponse: ResponseData<Questionnaire>;
};

export declare type QuestionnaireReducer = {
  data: DataQuestionnaire | null;
  content: Array<Questionnaire> | null;
};

/**
 * @param {DataQuestionnaire} data
 * @return {any}
 */
export function setDataQuestionnaire(data) {
  return {
    type: SET_DATA_QUESTIONNAIRE,
    payload: data,
  };
}

/**
 * @param {Array<DataQuestionnaire>} data
 * @return {any}
 */
export function setContentQuestionnaire(data) {
  return {
    type: SET_CONTENT_QUESTIONNAIRE,
    payload: data,
  };
}

const initialState: QuestionnaireReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function questionnaireReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_QUESTIONNAIRE:
      return { ...state, data: action.payload };
    case SET_CONTENT_QUESTIONNAIRE:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
