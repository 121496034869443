import React, { useMemo } from 'react';
import { Card, Space, Typography } from 'antd/es';
import { BlockProps } from 'antd/es/typography/Base';

const { Text } = Typography;

export declare type MobileListItemProps = {
  listItem?: (content) => {
    title: string | React.ReactNode;
    extra?: string | React.ReactNode;
    descriptions: Array<{
      title: string;
      text: string | React.ReactNode;
      textProps?: BlockProps;
    }>;
  };
  content: any;
};

const MobileListItem = React.memo(
  ({ listItem, content }: MobileListItemProps) => {
    const listItemResult = useMemo(() => {
      return listItem?.(content);
    }, [listItem, content]);

    return (
      <Card
        size={'small'}
        title={
          <div style={{ whiteSpace: 'break-spaces' }}>
            {listItemResult?.title}
          </div>
        }
        extra={listItemResult?.extra}
      >
        <Space direction={'vertical'}>
          {listItemResult?.descriptions.map((descriptionItem) => {
            return (
              <Space key={descriptionItem.title}>
                <Text strong>{descriptionItem.title}</Text>
                <Text {...descriptionItem.textProps}>
                  {descriptionItem.text ?? '-'}
                </Text>
              </Space>
            );
          })}
        </Space>
      </Card>
    );
  }
);
MobileListItem.displayName = 'MobileListItem';
export default MobileListItem;
