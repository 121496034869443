import { API_URLS } from '../../../API/urls';
import { DictionaryData, OnceRecordProps } from '../types';
import {
  refusalsReducer,
  setContentRefusals,
  setDataRefusals,
} from '../../../../store/reducers/Refusals';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import { PermissionPage } from '../../../../AppTypes';

const tabNameField = 'title';

export const refusalPermission: PermissionPage = {
  all: 'getAllRefusal',
  create: 'createRefusal',
  update: 'updateRefusal',
  delete: 'deleteRefusal',
  one: 'getRefusal',
};

export const refusalDictionaryData: DictionaryData = {
  apiMethod: API_URLS.REFUSALS,
  dataListName: 'refusalList',
  idName: 'id',
  pageHeaderTitle: 'Причины отказа',
  tabNameField: tabNameField,
  reducer: 'refusalsReducer',
  columnsName: 'RefusalTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: tabNameField,
  popupList: getPopupList(refusalPermission),
  setData: setDataRefusals,
  setContent: setContentRefusals,
  permission: refusalPermission,
};

export const onceRefusalProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: API_URLS.REFUSALS,
  dataListName: 'refusalsData',
  name: tabNameField,
  permission: refusalPermission,
};
