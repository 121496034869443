import React from 'react';
import { ErrorBlock, InfiniteScroll, List, SwipeAction } from 'antd-mobile';
import { Spin } from 'antd/es';
import { useDispatch } from 'react-redux';
import { additionalValues } from '../../../../store/reducers/WorkplaceTabs';
import { useAppSelector } from '../../../../store/rootReducer';
import { AbstractClassProps } from '../AbstractClassTypes';
import MobileListItem from '../../MobileListItem';
import { useSearchParams } from 'react-router-dom';
import { PopupList } from '../../Popup/PopupTypes';
import { Action } from 'antd-mobile/es/components/swipe-action/swipe-action';

export declare type MobileListProps = {
  data: any;
  content: Array<any>;
  getListItem?: AbstractClassProps['getListItemMobile'];
  getData: Function;
  popupList: Array<
    Omit<PopupList, 'title'>[number] & {
      title: string;
      onClick: Function | undefined;
    }
  >;
  gotoRecord: Function;
  setCurrentRow: (row) => void;
  loading: boolean;
};

const MobileList = React.memo(
  ({
    data,
    content,
    getListItem,
    getData,
    popupList,
    gotoRecord,
    setCurrentRow,
    loading,
  }: MobileListProps) => {
    const hasMore = data && data.pageNumber + 1 < data.totalPages;
    const dispatch = useDispatch();
    const { currentTab } = useAppSelector(
      (state) => state.workplaceTabsReducer
    );
    const [_, setSearchParams] = useSearchParams();
    const { permissionList } = useAppSelector((state) => state.authReducer);
    const filteredPopup = popupList.filter((pop) =>
      pop.permission ? permissionList.includes(pop.permission) : true
    );

    const rightActions: Action[] = filteredPopup
      ?.filter(
        (pop) => pop.position === 'right' || !pop.hasOwnProperty('position')
      )
      .map((popup) => ({
        key: popup.type + popup.title,
        text: popup.icon ?? popup.title,
        color: popup?.color ?? 'primary',
        onClick: () => popup.onClick?.(),
      }));

    const leftActions: Action[] = filteredPopup
      ?.filter((pop) => pop.position === 'left')
      .map((popup) => ({
        key: popup.type + popup.title,
        text: popup.icon ?? popup.title,
        color: popup?.color ?? 'warning',
        onClick: () => popup.onClick?.(),
      }));

    const loadMore = () => {
      if (loading) return false;
      const params = new URLSearchParams(currentTab?.searchParams);

      const page = params.get('page');
      params.delete('sort');
      params.delete('size');
      params.set('page', `${page ? +page + 1 : 1}`);
      setSearchParams(params);
      dispatch(
        additionalValues([
          { action: 'add', key: 'searchParams', value: params.toString() },
        ])
      );
      return getData();
    };

    return (
      <>
        {content?.length === 0 && (
          <ErrorBlock status="empty" title={'Нет данных'} description={''} />
        )}
        <List style={{ backgroundColor: 'transparent' }} mode={'card'}>
          {content?.map((item, index) => (
            <SwipeAction
              key={index}
              rightActions={rightActions}
              leftActions={leftActions}
              onActionsReveal={() => {
                setCurrentRow(item);
              }}
            >
              <List.Item onClick={() => gotoRecord(item)} clickable={false}>
                <MobileListItem listItem={getListItem} content={item} />
              </List.Item>
            </SwipeAction>
          ))}
        </List>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          <Spin spinning={hasMore} tip={'Загрузка...'} />
        </InfiniteScroll>
      </>
    );
  }
);
MobileList.displayName = 'MobileList';
export default MobileList;
