import { PermissionCUD, PermissionPage } from '../../../AppTypes';

export const employeePermission: PermissionPage & {} = {
  all: 'getAllEmployee',
  delete: 'deleteEmployee',
  one: 'getEmployee',
  create: 'createEmployee',
  update: 'updateEmployee',
};

export const vacationPermission: PermissionCUD = {
  create: 'createVacation',
  update: 'updateVacation',
  delete: 'deleteVacation',
};

export const betweenWatchPermission: PermissionCUD = {
  create: 'createDatesBetweenWatch',
  update: 'updateDatesBetweenWatch',
  delete: 'deleteDatesBetweenWatch',
};

export const incentivePermission: PermissionCUD = {
  create: 'createIncentive',
  update: 'updateIncentive',
  delete: 'deleteIncentive',
};

export const commentEmployeePermission: PermissionCUD = {
  create: 'createEmployeeComment',
  update: 'updateEmployeeComment',
  delete: 'deleteEmployeeComment',
};
