import React from 'react';
import { PopupProps } from './PopupTypes';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

/**
 * Контекстное меню
 */
class Popup extends React.Component<PopupProps, any> {
  private popupRef = React.createRef<HTMLUListElement>();
  /**
   * @param {PopupProps} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listNew: null,
      pageY: null,
    };
  }

  /**
   * Проверка на принадлежность к тестовой компании
   * @param {PopupProps} prevProps
   */
  componentDidUpdate(prevProps) {
    const { profile, list, currentRow, pageY } = this.props;
    if (prevProps.pageY !== pageY) this.checkWindowSize();
    if (
      prevProps.currentRow !== currentRow &&
      profile?.organizationPositionData?.organizationDepartmentData.organizationData.title
        .toLowerCase()
        .includes('тест')
    ) {
      this.setState({
        listNew: list.filter((pop) => pop.type === 'checkout'),
      });
    }
  }

  /**
   * Проверка размеров попапа для отображения в окне
   */
  checkWindowSize = () => {
    const windowSize = document.documentElement.clientHeight;
    const popUp = this.popupRef;
    const popSize = popUp.current?.clientHeight || 0;
    if (popSize + (this.props.pageY || 0) > windowSize) {
      this.setState({ pageY: windowSize - popSize - 20 });
    } else this.setState({ pageY: this.props.pageY });
  };
  /**
   * Метод отрисовки контекстного меню
   * @return {JSX}
   */
  render() {
    const { confirm } = Modal;
    const { pageY } = this.state;
    const { pageX, visible, list, setDeleteRow, permissionList, currentRow } =
      this.props;
    const { listNew } = this.state;
    const rawList = (listNew || list)?.filter((el) =>
      el.permission ? permissionList?.includes(el.permission) : true
    );
    const darkTheme = localStorage.getItem('darkTheme');

    return (
      <ul
        className={darkTheme ? 'popup_dark popup' : 'popup'}
        ref={this.popupRef}
        style={{
          top: `${pageY}px`,
          left: `${pageX}px`,
          display: `${visible ? 'block' : 'none'}`,
          position: 'fixed',
          zIndex: '9999',
        }}
      >
        {Array.isArray(rawList) &&
          rawList.map((item, index) => {
            const showConfirm = () => {
              setDeleteRow?.(currentRow);
              confirm({
                title:
                  item.type === 'delete'
                    ? 'Вы действительно хотите удалить этот элемент?'
                    : 'Вы действительно хотите удалить эти элементы?',
                icon: <ExclamationCircleOutlined />,
                okText: 'Да',
                okType: 'danger',
                cancelText: 'Отмена',
                onOk() {
                  item.onClick();
                },
                onCancel() {
                  setDeleteRow?.(null);
                },
              });
            };
            // @ts-ignore
            if (item.type === 'delete' || item.type === 'massDelete') {
              return (
                <li
                  key={index + item.title}
                  style={{ display: 'flex' }}
                  onClick={showConfirm}
                >
                  <div style={{ marginRight: '10px' }}>{item.icon}</div>
                  <div>
                    {typeof item.title === 'function'
                      ? item.title(currentRow)
                      : item.title}
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  key={index + item.title}
                  style={{ display: 'flex' }}
                  onClick={item.onClick}
                >
                  <div style={{ marginRight: '10px' }}>{item.icon}</div>
                  <div>
                    {typeof item.title === 'function'
                      ? item.title(currentRow)
                      : item.title}
                  </div>
                </li>
              );
            }
          })}
      </ul>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    profile: state.authReducer.profile,
    permissionList: state.authReducer.permissionList,
  };
};
export default connect(mapStateToProps)(Popup);
