import React from 'react';
import AbstractPage from '../../../../utils/Components/AbstractPage/AbstractPage';
import { setToDeviceData } from './setToDevicesData';
import { initialColumns } from './SetToDeviceExtra';
import SetToDeviceForm from './components/SetToDeviceForm';
import { getPageHeaderExtra } from '../../Employee/EmployeeExtra';
import { getPopupList } from '../../../../utils/Components/Popup/PopupExtra';
import { setToDevicePermission } from './SetToDevice.permission';

const SetToDevices = React.memo(() => {
  return (
    <AbstractPage
      searchFields={[]}
      columns={initialColumns}
      {...setToDeviceData}
      AddComponent={<SetToDeviceForm type={'add'} />}
      EditComponent={<SetToDeviceForm type={'edit'} />}
      editModalWidth={'90vw'}
      createModalWidth={'90vw'}
      pageHeaderExtra={getPageHeaderExtra(setToDevicePermission.create)}
      popupList={getPopupList(setToDevicePermission)}
    />
  );
});
SetToDevices.displayName = 'SetToDevices';
export default SetToDevices;
