import React from 'react';
import {
  ExcelUploadAbstractClassProps,
  ExcelUploadAbstractClassState,
} from './ExcelUploadAbstractClassTypes';
import { InboxOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import CollapsibleModal from '../../CollapsibleModal/CollapsibleModal';
import API from '../../API/API';
import { KeycloakApp } from '../../keycloak/KeycloakApp';

/**
 * @class
 * @extends React.Component
 */
class ExcelUploadAbstractClass extends React.Component<
  ExcelUploadAbstractClassProps,
  ExcelUploadAbstractClassState
> {
  /**
   * @param {ExcelUploadAbstractClassProps} props
   */
  constructor(props: ExcelUploadAbstractClassProps) {
    super(props);
    this.state = {
      additionalData: null,
      isAdditionalData: false,
    };
  }

  /**
   * @param {boolean} value
   */
  setVisibleAdditionalData = (value: boolean) => {
    this.setState({ isAdditionalData: value });
  };

  /**
   * @return {JSX}
   */
  render() {
    const {
      isVisible,
      setIsVisible,
      fileName,
      uri,
      extraFormData,
      customRequest,
      title,
    } = this.props;
    const { isAdditionalData, additionalData } = this.state;
    const { Dragger } = Upload;

    const uploadSettings = {
      name: fileName,
      multiple: true,
      headers: {
        Authorization: `Bearer ${KeycloakApp.token}`,
      },
      action: uri,
      customRequest:
        extraFormData || customRequest
          ? (info) => {
              const formData = new FormData();
              formData.append(fileName, info.file);

              // Для загрузки файлов в Explorer
              if (extraFormData) {
                for (const formDatum in extraFormData) {
                  if (extraFormData.hasOwnProperty(formDatum)) {
                    const uploadFileRequest = new Blob(
                      [JSON.stringify(extraFormData[formDatum])],
                      {
                        type: 'application/json',
                      }
                    );
                    formData.append(formDatum, uploadFileRequest);
                  }
                }
              }
              API.post(uri, formData)
                .then((response) => {
                  if (customRequest) {
                    customRequest(response);
                  }
                })
                .then(() => {
                  setIsVisible(false);
                })
                .catch(() => {
                  info.file.status = 'error';
                  setIsVisible(false);
                });
            }
          : undefined,
    };
    return (
      <div>
        <Modal
          footer={false}
          title={title ?? 'Загрузка данных'}
          onCancel={() => setIsVisible(false)}
          open={isVisible}
        >
          <Dragger
            {...uploadSettings}
            onChange={(info) => {
              if (info.file.status === 'done') {
                this.props.afterDone?.();
              }
            }}
          >
            <p className="ant-upload-text">
              Перетащите документы сюда <InboxOutlined />
            </p>
          </Dragger>
        </Modal>

        {isAdditionalData && (
          <CollapsibleModal
            title={'Дополнительно'}
            onCancel={() => this.setVisibleAdditionalData(false)}
            visible={isAdditionalData}
          >
            {additionalData.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </CollapsibleModal>
        )}
      </div>
    );
  }
}

export default ExcelUploadAbstractClass;
