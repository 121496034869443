import React, {useState} from 'react';
import {Badge, Button, Layout, Modal, Popover, Space, Tooltip} from 'antd';
import { HeartTwoTone } from '@ant-design/icons/es/icons';
import {
  MdFullscreen,
  MdFullscreenExit,
  MdOutlineAutoAwesome,
  MdOutlineDarkMode,
  MdOutlineWbSunny,
} from 'react-icons/md';
import { useAppSelector } from '../../../store/rootReducer';
import { useDispatch } from 'react-redux';
import {
  setBigMode,
  setDarkTheme as setDarkThemeReducer,
} from '../../../store/reducers/AuthReducer';
import { isDesktop } from 'react-device-detect';
import FeedbackForm from "./FeedbackForm";

const { Footer: FooterA } = Layout;

const Footer = React.memo(() => {
  const { isSocketConnect } = useAppSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { darkTheme, bigMode } = useAppSelector((state) => state.authReducer);
  const [isFeedbackForm, setIsFeedbackForm] = useState(false);

  const onChangeTheme = (value: boolean) => {
    dispatch(setDarkThemeReducer(value));
    value
      ? localStorage.setItem('darkTheme', 'true')
      : localStorage.removeItem('darkTheme');
  };

  const onChangeCompactMode = () => {
    !bigMode
      ? localStorage.setItem('bigMode', 'true')
      : localStorage.removeItem('bigMode');
    dispatch(setBigMode(!bigMode));
  };

  const size = bigMode ? 18 : 14;

  return (
    <FooterA>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
          <div>
            <Badge
              status="processing"
              style={{ marginRight: 4 }}
              color={isSocketConnect ? 'green' : 'red'}
            />
            Made with <HeartTwoTone twoToneColor="#eb2f96" /> by YAMATA
          </div>

        </div>
        {isDesktop && (
          <Space>
            <Popover
              arrow={false}
              content={
                <Space direction={'vertical'} size={'small'}>
                  <Tooltip title="Сменить тему" placement={'left'}>
                    <Button
                      type={'text'}
                      onClick={() => onChangeTheme(!darkTheme)}
                      shape={'circle'}
                      style={{ fontSize: size }}
                      icon={
                        darkTheme ? (
                          <MdOutlineWbSunny size={size} />
                        ) : (
                          <MdOutlineDarkMode size={size} />
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip title={'Изменить размер'} placement={'left'}>
                    <Button
                      type={'text'}
                      onClick={onChangeCompactMode}
                      shape={'circle'}
                      style={{ fontSize: size }}
                      icon={
                        !bigMode ? (
                          <MdFullscreen size={size} />
                        ) : (
                          <MdFullscreenExit size={size} />
                        )
                      }
                    />
                  </Tooltip>
                </Space>
              }
            >
              <Button
                type={'text'}
                shape={'circle'}
                style={{ fontSize: size }}
                icon={<MdOutlineAutoAwesome size={size} />}
              />
            </Popover>
          </Space>
        )}
      </div>
      <Modal
          open={isFeedbackForm}
          onCancel={() => setIsFeedbackForm(false)}
          destroyOnClose
          footer={false}
          title={'Связаться с нами'}
      >
        <FeedbackForm
            onFinishEnd={() => setIsFeedbackForm(false)}
        />
      </Modal>
    </FooterA>
  );
});
Footer.displayName = 'Footer';
export default Footer;
