import React, { useEffect, useState } from 'react';
import { ElementProps } from './AbstractElementTypes';
import { useLocation, useParams } from 'react-router-dom';
import { notification, Skeleton, Spin } from 'antd';
import AbstractElementViewer from './Components/AbstractElementViewer';
import {
  useGetAbstractDataQuery,
  useUpdateAbstractDataMutation,
} from '../../../store/api/abstract.api';
import { setCurrentTabName } from '../../../store/reducers/WorkplaceTabs';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useRouterProps } from '../AbstractPage/helpers/useRouterProps';
import { useAppSelector } from '../../../store/rootReducer';
import AbstractError from '../AbstractError/AbstractError';

const AbstractElementF = ({
  apiMethod,
  dataListName,
  pageHeaderList,
  avatar,
  editPermission,
  editModalWidth,
  editComponent: EditComponent,
  pageExtra,
  getRecord,
  destroyInactiveTabPane,
  descriptions,
  tabs,
  alert,
}: ElementProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const path = apiMethod.replace('api/', '');
  const {
    isLoading: loading,
    data,
    isFetching,
    error,
  } = useGetAbstractDataQuery(`${path}/${id}`);
  const [update, { isLoading: editLoading }] = useUpdateAbstractDataMutation();
  const content = data?.[dataListName];
  const dispatch = useDispatch();
  const location = useLocation();
  const routerProps = useRouterProps();
  const { permissionList } = useAppSelector((state) => state.authReducer);
  const editMode = editPermission
    ? permissionList.includes(editPermission)
    : false;

  useEffect(() => {
    if (content) {
      const tabName = Array.isArray(pageHeaderList.pass)
        ? pageHeaderList.pass.reduce(
            (prev, curr) => prev + ' ' + get(content, curr),
            ''
          )
        : typeof pageHeaderList.pass === 'function'
        ? pageHeaderList.pass(content)
        : get(content, pageHeaderList.pass);
      dispatch(setCurrentTabName([location.pathname, tabName]));
    }
  }, [content]);

  const onFinishEdit = async (value: any) => {
    return update({ path, body: value, id: id! }).then((res) => {
      if ('error' in res) {
        // @ts-ignore
        notification.error({ message: res.error.data?.error ?? '' });
        // @ts-ignore
        throw Error(res.error.data?.error);
      }
      setIsEdit(false);
      return res;
    });
  };

  const editComponent = (children) => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        // @ts-ignore
        currentRow: content,
        onFinishEdit: onFinishEdit,
        routerProps: routerProps,
        isFetching: editLoading,
      });
    }
  };

  return (
    <Skeleton loading={loading}>
      <Spin spinning={isFetching}>
        {error ? (
          <AbstractError error={error} />
        ) : (
          <AbstractElementViewer
            content={content}
            isEdit={isEdit}
            openEdit={(item) => {
              setIsEdit(item);
            }}
            avatar={avatar}
            router={routerProps}
            editMode={editMode}
            apiMethod={apiMethod}
            editComponent={editComponent(EditComponent)}
            descriptions={descriptions}
            tabs={tabs ?? null}
            pageHeaderList={pageHeaderList}
            editModalWidth={editModalWidth}
            pageExtra={pageExtra}
            getRecord={getRecord}
            alert={alert}
            destroyInactiveTabPane={destroyInactiveTabPane}
          />
        )}
      </Spin>
    </Skeleton>
  );
};
AbstractElementF.displayName = 'AbstractElementF';
export default AbstractElementF;
