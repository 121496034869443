import { NewPageData } from '../../../../AppTypes';
import { API_URLS } from '../../../../utils/API/urls';

export const rolesData: NewPageData = {
  apiMethod: API_URLS.ROLES,
  dataListName: 'rolelist',
  idName: 'id',
  pageHeaderTitle: 'Роли',
  tabNameField: 'title',
  columnsName: 'RolesTable',
};
