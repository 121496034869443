import { DictionaryData, OnceRecordProps } from '../types';
import { API_URLS } from '../../../API/urls';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import {
  setContentWorkplaces,
  setDataWorkplaces,
} from '../../../../store/reducers/Workplaces';
import { PermissionPage } from '../../../../AppTypes';

const tabNameField = 'title';

export const workplacePermission: PermissionPage = {
  one: 'getWorkplace',
  all: 'getAllWorkplace',
  update: 'updateWorkplace',
  delete: 'deleteWorkplace',
  create: 'createWorkplace',
};

export const workplaceDictionaryData: DictionaryData = {
  apiMethod: API_URLS.WORKPLACES,
  dataListName: 'workplaceList',
  idName: 'id',
  pageHeaderTitle: 'Места работы',
  tabNameField: tabNameField,
  reducer: 'workplacesReducer',
  columnsName: 'WorkplaceTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: tabNameField,
  popupList: getPopupList(workplacePermission),
  setData: setDataWorkplaces,
  setContent: setContentWorkplaces,
  permission: workplacePermission,
};

export const onceWorkplaceProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: API_URLS.WORKPLACES,
  dataListName: 'workplace',
  name: tabNameField,
  permission: workplacePermission,
};
