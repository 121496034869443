import { ResponseData } from '../../utils/API/APITypes';
import { ProfileType } from '../../components/Pages/Admin/Accounts/type';

const SET_DATA_ACCOUNTS = 'SET_DATA_ACCOUNTS';
const SET_CONTENT_ACCOUNTS = 'SET_CONTENT_ACCOUNTS';

export declare type DataAccounts = {
  success: boolean;
  accountsDataPage: ResponseData<ProfileType>;
};

export declare type AccountsReducer = {
  data: DataAccounts | null;
  content: Array<any> | null;
};

/**
 * @param {DataAccounts} data
 * @return {any}
 */
export function setDataAccounts(data) {
  return {
    type: SET_DATA_ACCOUNTS,
    payload: data,
  };
}

/**
 * @param {Array<DataAccounts>} data
 * @return {any}
 */
export function setContentAccounts(data) {
  return {
    type: SET_CONTENT_ACCOUNTS,
    payload: data,
  };
}

const initialState: AccountsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ACCOUNTS:
      return { ...state, data: action.payload };
    case SET_CONTENT_ACCOUNTS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
