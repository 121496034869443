import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import API from '../../API/API';
import { useAppSelector } from '../../../store/rootReducer';
import { useDispatch } from 'react-redux';
import { DictionaryData } from '../../Components/Dictionary/types';

type Options = {
  value: string | number;
  item: string;
  label: string;
};

export const useDictionarySelectOptions = <T,>(
  dictionaryData: DictionaryData,
  loadCounter?: Dispatch<SetStateAction<number>>
): Array<Options & { data: T }> => {
  const dictionaryList =
    useAppSelector((state) => state[dictionaryData.reducer].content) ?? [];
  const typeSelectOptions = useMemo<Array<Options & { data: T }>>(
    () =>
      dictionaryList.map((dictionary: Object) => ({
        value: dictionary[dictionaryData.idName],
        item: dictionary[dictionaryData.name],
        label: dictionary[dictionaryData.name],
        data: dictionary,
      })),
    [dictionaryList]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dictionaryList.length) {
      loadCounter?.((prevState) => prevState + 1);
      const api = `${dictionaryData.apiMethod}?size=999`;
      API.get(api)
        .then((res) => {
          dispatch(
            dictionaryData.setContent(
              res.data[dictionaryData.dataListName].content
            )
          );
        })
        .finally(() => {
          loadCounter?.((prevState) => prevState - 1);
        });
    }
  }, []);

  return typeSelectOptions;
};
