const WORKPLACE = 'WORKPLACE';
const POSITIONS = 'POSITIONS';
const DEPARTMENT = 'DEPARTMENT';
const REFUSALS = 'REFUSALS';
const IS_LOADING = 'IS_LOADING';
const ACCOUNTS = 'ACCOUNTS';
const PROJECTS = 'PROJECTS';
const INTERVIEW_DECISION = 'INTERVIEW_DECISION';

export function setWorkplace(data) {
  return {
    type: WORKPLACE,
    payload: data,
  };
}

export function setPositions(data) {
  return {
    type: POSITIONS,
    payload: data,
  };
}

export function setDepartment(data) {
  return {
    type: DEPARTMENT,
    payload: data,
  };
}

export function setRefusals(data) {
  return {
    type: REFUSALS,
    payload: data,
  };
}

export function setLoading(data) {
  return {
    type: IS_LOADING,
    payload: data,
  };
}

export function setAccounts(data) {
  return {
    type: ACCOUNTS,
    payload: data,
  };
}

export function setProjects(data) {
  return {
    type: PROJECTS,
    payload: data,
  };
}

export function setInterviewDecision(data) {
  return {
    type: INTERVIEW_DECISION,
    payload: data,
  };
}

const initial = {
  workplace: null,
  positions: null,
  departments: null,
  refusals: null,
  isLoadingData: false,
  accounts: null,
  projects: null,
  interviewDecision: null,
};

export function dictionariesReducer(state = initial, action) {
  switch (action.type) {
    case WORKPLACE:
      return { ...state, workplace: action.payload };
    case POSITIONS:
      return { ...state, positions: action.payload };
    case DEPARTMENT:
      return { ...state, departments: action.payload };
    case REFUSALS:
      return { ...state, refusals: action.payload };
    case IS_LOADING:
      return { ...state, isLoadingData: action.payload };
    case ACCOUNTS:
      return { ...state, accounts: action.payload };
    case PROJECTS:
      return { ...state, projects: action.payload };
    case INTERVIEW_DECISION:
      return { ...state, interviewDecision: action.payload };
    default:
      return state;
  }
}
