import React from 'react';
import { Button, Tooltip } from 'antd';
import { useAppSelector } from '../../../../store/rootReducer';
import { CloseOutlined } from '@ant-design/icons';
import { AbstractClassPageHeaderExtraButtonItem } from '../../AbstractClass/AbstractClassTypes';

const DownloadBtn = React.memo(
  ({
    item,
    onPageHeaderExtraClick,
  }: {
    item: AbstractClassPageHeaderExtraButtonItem;
    onPageHeaderExtraClick: Function;
  }) => {
    const { downloadList } = useAppSelector(
      (state) => state.abstractClassDataReducer
    );

    const currentController = downloadList[item.onClick.extra];

    const btnLoading = item.loading ? !!currentController : false;

    const bthProps = {
      type: item.type,
      block: item.block ? item.block : false,
      key: item.onClick.extra + item.onClick.func + 'load',
      ghost: item.ghost ? item.ghost : false,
      danger: item.danger ? item.danger : false,
      shape: item.shape ? item.shape : false,
    };

    return (
      <Tooltip
        title={
          btnLoading ? (
            <Button
              size={'small'}
              onClick={() => currentController.abort()}
              type={'text'}
              icon={<CloseOutlined />}
              style={{ color: 'white' }}
              className={item.className}
            >
              Отменить загрузку
            </Button>
          ) : (
            item.title
          )
        }
      >
        <Button
          {...bthProps}
          icon={item.icon}
          loading={btnLoading}
          disabled={item.disabled ? item.disabled : false}
          className={item.className}
          onClick={() => onPageHeaderExtraClick(item)}
        >
          {item?.text}
        </Button>
      </Tooltip>
    );
  }
);
DownloadBtn.displayName = 'DownloadBtn';
export default DownloadBtn;
