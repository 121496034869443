import React from 'react';
import { Button, Form, Input, InputNumber } from 'antd/es';
import { EditRecordProps } from '../types';

/**
 * Редактирование записи
 */
export default class FormRecord extends React.Component<EditRecordProps, any> {
  /**
   * @param {string} nameComponent
   * @return {JSX}
   */
  getComponent = (nameComponent) => {
    switch (nameComponent.toLowerCase()) {
      case 'inputnumber':
        return <InputNumber style={{ width: '100%' }} />;
      default:
        return <Input />;
    }
  };

  /**
   * @param {any} values
   */
  onFinish = (values) => {
    const {
      type,
      onFinishEdit,
      idName,
      currentRow,
      createArrayName,
      onFinishAdd,
    } = this.props;
    if (type === 'edit') {
      values[idName!] = currentRow[idName!] || currentRow.id;
      onFinishEdit(values);
    } else {
      createArrayName
        ? onFinishAdd!({ [`${createArrayName}`]: [values] })
        : onFinishAdd!(values);
    }
  };
  /**
   * @return {JSX}
   */
  render() {
    const { currentRow, name, isFetching, formFields } = this.props;
    const fields = [
      {
        name: name,
        value: currentRow?.[name] ? currentRow[name] : null,
      },
      {
        name: 'description',
        value: currentRow?.description ? currentRow.description : null,
      },
    ];

    formFields?.forEach((field) =>
      fields.push({
        name: field.name,
        value: currentRow?.[field.name] ? currentRow[field.name] : null,
      })
    );
    const oldValue = {};
    fields.forEach((item) => (oldValue[item.name] = item.value));

    return (
      <Form layout="vertical" onFinish={this.onFinish} fields={fields}>
        <Form.Item label="Название" name={name} key={name}>
          <Input />
        </Form.Item>
        {formFields?.map((field) => (
          <Form.Item label={field.label} name={field.name} key={field.name}>
            {this.getComponent(field.component)}
          </Form.Item>
        ))}
        <Form.Item label="Описание" name="description" key="description">
          <Input />
        </Form.Item>
        <Form.Item key="button">
          <Button htmlType="submit" type="primary" disabled={isFetching}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
