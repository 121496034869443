import { ResponseData } from '../../utils/API/APITypes';
import { Refusal } from '../../components/Pages/Сandidates/Types/Candidates';

const SET_DATA_REFUSALS = 'SET_DATA_REFUSALS';
const SET_CONTENT_REFUSALS = 'SET_CONTENT_REFUSALS';

export declare type DataRefusals = {
  success: boolean;
  DataList: ResponseData<Refusal>;
};

export declare type RefusalsReducer = {
  data: DataRefusals | null;
  content: Array<Refusal> | null;
};

/**
 * @param {DataRefusals} data
 * @return {any}
 */
export function setDataRefusals(data) {
  return {
    type: SET_DATA_REFUSALS,
    payload: data,
  };
}

/**
 * @param {Array<DataRefusals>} data
 * @return {any}
 */
export function setContentRefusals(data) {
  return {
    type: SET_CONTENT_REFUSALS,
    payload: data,
  };
}

const initialState: RefusalsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function refusalsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_REFUSALS:
      return { ...state, data: action.payload };
    case SET_CONTENT_REFUSALS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
