import { DictionaryData, OnceRecordProps } from '../types';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import { API_URLS } from '../../../API/urls';
import {
  brandsReducer,
  setContentBrands,
  setDataBrands,
} from '../../../../store/reducers/Brands';
import { PermissionPage } from '../../../../AppTypes';

const idName = 'id';
const api = API_URLS.BRANDS;
const name = 'title';

export const brandPermission: PermissionPage = {
  update: 'updateBrand',
  all: 'getAllBrand',
  delete: 'deleteBrand',
  one: 'getBrand',
  create: 'createBrand',
};

export const brandsData: DictionaryData = {
  apiMethod: api,
  dataListName: 'brandsList',
  idName: idName,
  pageHeaderTitle: 'Компании производители',
  tabNameField: name,
  reducer: 'brandsReducer',
  columnsName: 'BrandsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList(brandPermission),
  setData: setDataBrands,
  setContent: setContentBrands,
  permission: brandPermission,
};

export const onceBrandsProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'brand',
  name: name,
  permission: brandPermission,
};
