import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_PROJECTS = 'SET_DATA_PROJECTS';
const SET_CONTENT_PROJECTS = 'SET_CONTENT_PROJECTS';

export declare type DataProjects = {
  success: boolean;
  DataList: ResponseData<Dictionary>;
};

export declare type ProjectsReducer = {
  data: DataProjects | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {DataProjects} data
 * @return {any}
 */
export function setDataProjects(data) {
  return {
    type: SET_DATA_PROJECTS,
    payload: data,
  };
}

/**
 * @param {Array<DataProjects>} data
 * @return {any}
 */
export function setContentProjects(data) {
  return {
    type: SET_CONTENT_PROJECTS,
    payload: data,
  };
}

const initialState: ProjectsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_PROJECTS:
      return { ...state, data: action.payload };
    case SET_CONTENT_PROJECTS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
