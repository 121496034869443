import { DictionaryData, OnceRecordProps } from '../types';
import { API_URLS } from '../../../API/urls';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import {
  setContentDepartments,
  setDataDepartments,
} from '../../../../store/reducers/Departments';
import { PermissionPage } from '../../../../AppTypes';

const tabNameField = 'title';

export const departmentPermission: PermissionPage = {
  update: 'updateDepartment',
  create: 'createDepartment',
  one: 'getDepartment',
  all: 'getAllDepartment',
  delete: 'deleteDepartment',
};

export const departmentDictionaryData: DictionaryData = {
  apiMethod: API_URLS.DEPARTMENTS,
  dataListName: 'departmentsList',
  idName: 'id',
  pageHeaderTitle: 'Отделы',
  tabNameField: tabNameField,
  reducer: 'departmentsReducer',
  columnsName: 'DepartmentsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: tabNameField,
  popupList: getPopupList(departmentPermission),
  setData: setDataDepartments,
  setContent: setContentDepartments,
  permission: departmentPermission,
};

export const onceDepartmentProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: API_URLS.DEPARTMENTS,
  dataListName: 'department',
  name: tabNameField,
  permission: departmentPermission,
};
