import { Workplace } from '../../components/Pages/Сandidates/Types/Candidates';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_WORKPLACES = 'SET_DATA_WORKPLACES';
const SET_CONTENT_WORKPLACES = 'SET_CONTENT_WORKPLACES';

export declare type DataWorkplaces = {
  success: boolean;
  DataList: ResponseData<Workplace>;
};

export declare type WorkplacesReducer = {
  data: DataWorkplaces | null;
  content: Array<Workplace> | null;
};

/**
 * @param {DataWorkplaces} data
 * @return {any}
 */
export function setDataWorkplaces(data) {
  return {
    type: SET_DATA_WORKPLACES,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkplaces>} data
 * @return {any}
 */
export function setContentWorkplaces(data) {
  return {
    type: SET_CONTENT_WORKPLACES,
    payload: data,
  };
}

const initialState: WorkplacesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function workplacesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WORKPLACES:
      return { ...state, data: action.payload };
    case SET_CONTENT_WORKPLACES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
