import API from '../../utils/API/API';
import { setNotificationsList } from '../../store/reducers/NotificationsReducer';
import dayjs from 'dayjs';

export default class NotificationUtils {
  private jti;
  private dispatch;
  private successEdit;

  constructor(props) {
    this.jti = props.id;
    this.dispatch = props.dispatch;
    this.successEdit = props.successEdit;
  }

  getData = () => {
    const self = this;
    API.get(`api/notification?accountId=${this.jti}`).then(function (response) {
      self.dispatch(setNotificationsList(response.data.notificationList));
    });
  };

  onEdit = (values) => {
    values.date = dayjs(values.date).toISOString();

    const self = this;
    API.put('api/notification', values)
      .then(function () {
        self.successEdit(false, null);
        self.getData();
      })
      .catch(function () {
        self.successEdit(false, null);
      });
  };

  onDelete = (id) => {
    const self = this;
    API.delete(`api/notification/${id}`).then(function () {
      self.getData();
    });
  };
}
