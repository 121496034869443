import { Position } from '../../components/Pages/Сandidates/Types/Candidates';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_POSITIONS = 'SET_DATA_POSITIONS';
const SET_CONTENT_POSITIONS = 'SET_CONTENT_POSITIONS';

export declare type DataPositions = {
  success: boolean;
  DataList: ResponseData<Position>;
};

export declare type PositionsReducer = {
  data: DataPositions | null;
  content: Array<Position> | null;
};

/**
 * @param {DataPositions} data
 * @return {any}
 */
export function setDataPositions(data) {
  return {
    type: SET_DATA_POSITIONS,
    payload: data,
  };
}

/**
 * @param {Array<DataPositions>} data
 * @return {any}
 */
export function setContentPositions(data) {
  return {
    type: SET_CONTENT_POSITIONS,
    payload: data,
  };
}

const initialState: PositionsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function positionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_POSITIONS:
      return { ...state, data: action.payload };
    case SET_CONTENT_POSITIONS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
