import { Tag } from 'antd';
import React from 'react';
import { AbstractColumns } from '../../../utils/Components/AbstractClass/AbstractClassTypes';
import { Employee } from '../Employee/type';
import { SearchFieldsType } from '../../../utils/Components/SearchPanel/types/SearchPanelTypes';
import { workplaceDictionaryData } from '../../../utils/Components/Dictionary/extra/workplaceData';

export const initialColumns: AbstractColumns<Employee> = [
  {
    title: 'ФИО',
    key: 'fullName',
    dataIndex: 'fullName',
    align: 'center',
    visible: true,
  },
  {
    title: 'Табельный номер',
    key: 'serviceNumber',
    dataIndex: 'serviceNumber',
    align: 'center',
    visible: true,
    render: (value) => <Tag>{value}</Tag>,
  },
  {
    title: 'Телефон и почта',
    key: 'email',
    dataIndex: 'email',
    align: 'center',
    visible: true,
    render: (value, record) => (
      <>
        <div>{record?.phoneNumber}</div>
        <div>{value}</div>
      </>
    ),
  },
  {
    title: 'Проекты',
    key: 'projectSet',
    dataIndex: 'projectSet',
    align: 'center',
    visible: true,
    render: (value) => value?.map((el) => el.title).join(', '),
  },
];

export const getSearchFields = (): SearchFieldsType[] => [
  {
    component: 'input',
    label: 'ФИО',
    name: 'fullName',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Input',
    label: 'Табельный номер',
    name: 'serviceNumber',
    placeholder: '',
    required: false,
    span: 2,
  },
  {
    component: 'select',
    label: 'Места работы',
    name: 'workplaceId',
    placeholder: '',
    required: false,
    selectItem: [],
    span: 4,
    dictionaryData: workplaceDictionaryData,
  },
];
