import { NewPageData } from '../../../../AppTypes';
import { API_URLS } from '../../../../utils/API/urls';

export const devicesData: NewPageData = {
  apiMethod: API_URLS.DEVICES,
  dataListName: 'deviceList',
  idName: 'id',
  pageHeaderTitle: 'Устройства',
  tabNameField: 'name',
  columnsName: 'DevicesTable',
};
