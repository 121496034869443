import { descriptionsList } from '../../Components/AbstractElement/AbstractElementTypes';
import { Col, Row, Typography } from 'antd';
import get from 'lodash/get';
import React from 'react';
import { AbstractColumns } from '../../Components/AbstractClass/AbstractClassTypes';

export const getDescriptionItems = (
  columns: AbstractColumns
): descriptionsList =>
  columns.reduce<descriptionsList>((previousValue, currentValue) => {
    const next = currentValue.children
      ? {
          fieldName: 'lol',
          span: 12,
          label: currentValue.title as string,
          render: (value, record, index) => {
            return (
              <Row>
                {currentValue.children?.map((el) => (
                  <Col span={8} key={index}>
                    <Typography.Text strong>
                      {typeof el.title === 'string' ? el.title : ''}:
                    </Typography.Text>{' '}
                    <Typography.Text>
                      {el.render
                        ? el.render(get(record, el.dataIndex), record, index)
                        : get(record, el.dataIndex)}
                    </Typography.Text>
                  </Col>
                ))}
              </Row>
            );
          },
        }
      : {
          fieldName: currentValue.dataIndex as string,
          label: currentValue.title as string,
          span: currentValue.span ?? 4,
          render: currentValue.render,
        };
    return [...previousValue, next];
  }, []);
