import React, { Suspense, useEffect, useRef, useState } from 'react';
import { TabBar } from 'antd-mobile';
import {
  ArrowUpOutlined,
  ContactsOutlined,
  HomeOutlined,
  MenuOutlined,
} from '@ant-design/icons/es/icons';
import { PageProps } from '../../../AppTypes';
import { Alert, Drawer, FloatButton, Layout, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setActiveKey,
  setInitialTab,
} from '../../../store/reducers/WorkplaceTabs';
import { useAppSelector } from '../../../store/rootReducer';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import WorkplaceMenuF from './WorkplaceMenuF';
import { TabType } from '../LayoutTypes';

const MobileLayout = React.memo(
  ({
    children,
    routerProps,
  }: PageProps & { children: React.ReactChildren }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeKey, tabs } = useAppSelector(
      (state) => state.workplaceTabsReducer
    );
    const [menuVisible, setMenuVisible] = useState(false);
    const contentRef = useRef<HTMLElement>(null);
    const [isVisibleTopScroll, setIsVisibleTopScroll] =
      useState<boolean>(false);

    const changeInitPage = (path) => {
      const findTab = tabs.find((tab) => tab.key === path);
      const newInitTabs: TabType[] = findTab
        ? tabs
        : [
            ...tabs,
            {
              title: path,
              key: path,
              closable: true,
              permission: [],
            },
          ];

      dispatch(setInitialTab(newInitTabs));
    };

    const setRouteActive = (value: string) => {
      value !== '/menu' && navigate(value);
      if (value && value !== '/menu') {
        dispatch(setActiveKey(value));
      }

      if (value === '/menu') {
        setMenuVisible(true);
      }
    };

    useEffect(() => {
      setMenuVisible(false);
      changeInitPage(routerProps?.location.pathname);
    }, [routerProps?.location.pathname]);

    /**
     * Метод добавления пропсов в отрисовываемый компонент.
     * @param {React.ReactNode} children
     * @return {React.ReactNode}
     */
    const propsToChildren = (children) => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          // @ts-ignore
          routerProps: routerProps,
        });
      }
    };

    const menuTabs = [
      {
        key: '/',
        title: 'Главная страница',
        icon: <HomeOutlined />,
      },
      {
        key: '/candidates',
        title: 'Кандидаты',
        icon: <ContactsOutlined />,
      },
      {
        key: '/menu',
        title: 'Меню',
        icon: <MenuOutlined />,
      },
    ];

    return (
      <Layout
        className="site-layout"
        style={{
          height: '100dvh',
          backgroundSize: 'cover',
        }}
      >
        <Content
          className="site-layout"
          onScroll={(e) => {
            setIsVisibleTopScroll((e.target as HTMLElement).scrollTop > 400);
          }}
          ref={contentRef}
          style={{ overflow: 'auto' }}
        >
          <Suspense
            fallback={
              <div
                style={{
                  display: 'flex',
                  width: '100dvw',
                  height: '100dvh',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin tip="Загрузка данных..." />
              </div>
            }
          >
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <Alert
                  type="error"
                  message="Ошибка"
                  style={{ margin: 12 }}
                  description={error.message}
                />
              )}
            >
              <>{propsToChildren(children)}</>
            </ErrorBoundary>
          </Suspense>
        </Content>
        {isVisibleTopScroll && (
          <FloatButton
            style={{ marginBottom: 16 }}
            icon={<ArrowUpOutlined />}
            onClick={() => contentRef.current?.scrollTo(0, 0)}
          />
        )}
        <TabBar
          style={{ borderTop: 'solid 1px #d9d9d9' }}
          activeKey={activeKey}
          onChange={setRouteActive}
        >
          {menuTabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
        <Drawer
          open={menuVisible}
          title={'Меню'}
          bodyStyle={isMobile ? { padding: 0 } : undefined}
          width={250}
          onClose={() => setMenuVisible(false)}
        >
          <WorkplaceMenuF name={''} router={routerProps!} />
        </Drawer>
      </Layout>
    );
  }
);
MobileLayout.displayName = 'MobileLayout';
export default MobileLayout;
