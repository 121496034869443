import { NewPageData } from '../../../AppTypes';
import { API_URLS } from '../../../utils/API/urls';

export const HistoryResumeData: NewPageData = {
  apiMethod: API_URLS.RESUME_HISTORY,
  dataListName: 'resumeDownloadHistoryList',
  idName: 'id',
  pageHeaderTitle: 'История просмотра резюме',
  tabNameField: 'fullName',
  columnsName: 'HistoryResumeTable',
};
