import React, { ReactNode, Suspense, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/rootReducer';
import { fetchAccountTabs } from '../../../store/reducers/WorkplaceTabs';
import {
  Params,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useChangeTab } from './helpers/useChangeTab';
import { routerProps } from '../../../AppTypes';
import { Alert, Button, Layout, Spin, Tabs, theme, Tooltip } from 'antd';
import { isDesktop } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CloseOutlined } from '@ant-design/icons';
import { useSetInitialTabs } from './helpers/useSetInitialTabs';
import { useTabActions } from './helpers/useTabActions';
import ErrorAction from './ErrorAction';
import { errorRequest } from './helpers/errorRequest';
import { TabType } from '../LayoutTypes';
import { KeycloakApp } from '../../../utils/keycloak/KeycloakApp';

const { useToken } = theme;

const WorkplaceTabsF = React.memo(
  ({ name, children }: { name: string; children: ReactNode }) => {
    const { tabs, activeKey } = useAppSelector(
      (state) => state.workplaceTabsReducer
    );

    const { profile, darkTheme } = useAppSelector((state) => state.authReducer);
    const location = useLocation();
    const changeTab = useChangeTab();
    const params: Params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const router: routerProps = {
      navigate: changeTab,
      location,
      params,
      setSearchParams: (params) => setSearchParams(params),
    };
    const { Content } = Layout;
    const { token } = useToken();
    const accountProfileId = profile?.accountProfileData?.accountProfileId;

    const dispatch = useAppDispatch();

    const dispatchTabs = (rawTabs: TabType[]) => {
      accountProfileId &&
        dispatch(fetchAccountTabs({ tabs: rawTabs, accountProfileId }));
    };

    /**
     * Определение начальных табов
     */
    const setInitialTabs = useSetInitialTabs(name);
    const {
      remove,
      tabChange,
      closeAllTabs,
      handleMouseDown,
      onDragEnd,
      handleKeyDown,
    } = useTabActions();

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
      setInitialTabs();
    }, []);

    useEffect(() => {
      if (
        tabs.length &&
        !tabs.find((item) => item.key === location?.pathname)
      ) {
        const newTab = {
          title: name,
          key: location.pathname,
          closable: true,
        };
        dispatchTabs([...tabs, newTab]);
      }
    }, [children]);

    const extra = (
      <Tooltip title={'Закрыть все вкладки'} mouseEnterDelay={0.3}>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => closeAllTabs()}
        />
      </Tooltip>
    );

    const getItemStyle = (_, draggableStyle) => ({
      userSelect: 'none',
      ...draggableStyle,
    });

    const items = useMemo(() => {
      return tabs?.map((tab, idx) => {
        return {
          key: tab.key,
          index: idx,
          label: (
            <Tooltip
              title={tab.title}
              overlayInnerStyle={{ maxWidth: '75ch' }}
              mouseEnterDelay={0.3}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  maxWidth: 100,
                  overflow: 'hidden',
                }}
              >
                {tab.title}
              </p>
            </Tooltip>
          ),
          closable: tab.closable,
        };
      });
    }, [tabs, children]);

    const propsToChildren = useMemo(() => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          // @ts-ignore
          routerProps: router,
          searchParams,
        });
      }
    }, [activeKey]);

    return (
      <div style={{ margin: 16 }}>
        <Tabs
          hideAdd
          items={items}
          type="editable-card"
          className="site-layout-background"
          destroyInactiveTabPane={true}
          onChange={tabChange}
          activeKey={activeKey}
          onEdit={remove}
          tabBarStyle={{
            backgroundColor: darkTheme ? undefined : '#f0f2f5',
            margin: 0,
          }}
          tabBarExtraContent={extra}
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableTab" direction={'horizontal'}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <DefaultTabBar {...tabBarProps}>
                      {(node) => (
                        <Draggable
                          {...node.props}
                          key={node.key}
                          draggableId={node.key}
                          index={tabs?.findIndex((tab) => tab.key === node.key)}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              onMouseDown={(e) => handleMouseDown(e, node.key)}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {node}
                            </div>
                          )}
                        </Draggable>
                      )}
                    </DefaultTabBar>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        />
        <Content
          style={{
            margin: '0',
            padding: '0 16px 16px',
            minHeight: isDesktop ? 'calc(100vh - 177px)' : '73vh',
            height: '100%',
            backgroundColor: token.colorBgContainer,
          }}
          key={activeKey}
        >
          <ErrorBoundary
            onError={(error, info) =>
              process.env.NODE_ENV === 'production' &&
              !error.message.includes('Loading chunk') &&
              errorRequest({
                values: {
                  title: error.name,
                  description: info.componentStack,
                },
                message: `${error.message} \n ${error.stack}`,
                personFullName: profile?.fullName,
                token: KeycloakApp?.token,
              })
            }
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div style={{ paddingTop: 16 }}>
                <Alert
                  type={
                    error.message.includes('Loading chunk') ? 'info' : 'error'
                  }
                  banner
                  message={
                    error.message.includes('Loading chunk')
                      ? 'Для сайта доступно обновление'
                      : 'Error'
                  }
                  description={
                    !error.message.includes('Loading chunk') && error.message
                  }
                  action={
                    error.message.includes('Loading chunk') ? (
                      <Button
                        type={'primary'}
                        onClick={() => window.location.reload()}
                      >
                        Обновить
                      </Button>
                    ) : (
                      <ErrorAction
                        resetErrorBoundary={resetErrorBoundary}
                        message={`${error.message} \n ${error.stack}`}
                      />
                    )
                  }
                />
              </div>
            )}
          >
            <Suspense fallback={<Spin />}>
              <aside>{propsToChildren}</aside>
            </Suspense>
          </ErrorBoundary>
        </Content>
      </div>
    );
  }
);
WorkplaceTabsF.displayName = 'WorkplaceTabsF';
export default WorkplaceTabsF;
