import { useMemo, useState } from 'react';
import { useGetSearchParamsQuery } from '../api/searchParams.api';
import { SearchFieldsType } from '../../SearchPanel/types/SearchPanelTypes';

export const useFilterSearchFields = (
  searchFields: SearchFieldsType[],
  apiMethod: string
) => {
  const [searchParams, setSearchParams] = useState<string | null>(null);
  const path = `${apiMethod.replace('api/', '')}/searchParams?${searchParams}`;
  const { data, isLoading } = useGetSearchParamsQuery(path, {
    skip: searchParams === null,
  });

  const prepareSearchField = (
    searchField: SearchFieldsType,
    loading: boolean,
    selectItems?: string[]
  ) => {
    if (loading) {
      return {
        ...searchField,
        loading: true,
        selectItem: searchField.loading === false ? [] : searchField.selectItem,
      };
    }
    if (selectItems) {
      return {
        ...searchField,
        component: 'select',
        selectItem: selectItems.map((el) => ({ item: el, value: el })),
        selectMode: 'multiple' as SearchFieldsType['selectMode'],
        loading: false,
      };
    }
    return searchField;
  };

  const formatSearchFields: SearchFieldsType[] = useMemo(() => {
    if (isLoading) {
      return searchFields.map((searchField) =>
        prepareSearchField(searchField, true)
      );
    } else if (data) {
      return searchFields.map((searchField) => {
        const { name } = searchField;
        const searchItemList = data.searchParams[name];
        return prepareSearchField(searchField, false, searchItemList);
      });
    } else {
      return searchFields;
    }
  }, [data, searchFields]);

  const getFilteredSearchFields = (searchParams: string) => {
    setSearchParams(searchParams);
  };
  return { formatSearchFields, getFilteredSearchFields };
};
