import React from 'react';
import { createRoot } from 'react-dom/client';
import store from './store/store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from './components/Routes/Router';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ebd108e1717cb5c0c18eaf2e165befdc@o4508267805409280.ingest.de.sentry.io/4508323132670032',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

dayjs.extend(relativeTime);
dayjs.extend(utc);

dayjs.locale('ru');
const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

serviceWorkerRegistration.register();
