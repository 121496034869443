import React from 'react';
import { Form, Input } from 'antd';
import { REQUIRED_RULES } from '../../../../../utils/Base/constants/requiredRule';
import TableSearchForm from '../../../../../utils/Components/TableSearch/TableSearchForm';
import { API_URLS } from '../../../../../utils/API/urls';
import { NewFormProps } from '../../../../../AppTypes';
import { devicesData } from '../../Devices/devicesData';
import { getInitialColumns, getSearchFields } from '../../Devices/DevicesExtra';
import { useDictionarySelectOptions } from '../../../../../utils/Base/hooks/useDictionarySelectOptions';
import { brandsData } from '../../../../../utils/Components/Dictionary/extra/brandData';
import { useForm } from 'antd/es/form/Form';
import { SetToDevice } from '../SetToDevice.type';
import { Key } from 'antd/es/table/interface';

const SetToDeviceForm = React.memo(
  ({
    type,
    onFinishAdd,
    onFinishEdit,
    currentRow,
  }: NewFormProps<SetToDevice>) => {
    const brandsOptions = useDictionarySelectOptions({
      ...brandsData,
      idName: 'title',
    });
    const onFinish = async (deviceIdList: Key[]) => {
      await form.validateFields();
      const data = form.getFieldsValue();
      return type === 'add'
        ? onFinishAdd!({ ...data, deviceIdList })
        : onFinishEdit!({ ...data, deviceIdList, id: currentRow?.id });
    };
    const [form] = useForm();

    return (
      <>
        <Form
          layout={'vertical'}
          form={form}
          initialValues={currentRow ? { ...currentRow } : undefined}
        >
          <Form.Item name={'number'} label={'Номер'} rules={REQUIRED_RULES}>
            <Input />
          </Form.Item>
        </Form>
        <TableSearchForm
          api={API_URLS.DEVICES + '?'}
          onFinish={onFinish}
          dataListName={devicesData.dataListName}
          idName={devicesData.idName}
          columns={getInitialColumns}
          searchFields={getSearchFields(brandsOptions)}
          isQuantity={false}
          initValue={currentRow?.deviceSet}
          disabledCreate={(selectedRows) => !selectedRows.length}
        />
      </>
    );
  }
);
SetToDeviceForm.displayName = 'SetToDeviceForm';
export default SetToDeviceForm;
