import { DictionaryData, OnceRecordProps } from '../types';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import { API_URLS } from '../../../API/urls';
import {
  projectsReducer,
  setContentProjects,
  setDataProjects,
} from '../../../../store/reducers/Projects';
import { PermissionPage } from '../../../../AppTypes';

const tabNameField = 'title';

export const projectPermission: PermissionPage = {
  all: 'getAllProject',
  one: 'getProject',
  create: 'createProject',
  delete: 'deleteProject',
  update: 'updateProject',
};

export const projectDictionaryData: DictionaryData = {
  apiMethod: API_URLS.PROJECTS,
  dataListName: 'projectList',
  idName: 'id',
  pageHeaderTitle: 'Проекты',
  tabNameField: tabNameField,
  reducer: 'projectsReducer',
  columnsName: 'ProjectTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: tabNameField,
  popupList: getPopupList(projectPermission),
  setData: setDataProjects,
  setContent: setContentProjects,
  permission: projectPermission,
};

export const onceProjectProps: OnceRecordProps = {
  idName: 'id',
  apiMethod: API_URLS.PROJECTS,
  dataListName: 'project',
  name: tabNameField,
  permission: projectPermission,
};
