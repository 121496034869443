import React from 'react';
import AbstractPage from '../../../../utils/Components/AbstractPage/AbstractPage';
import { rolesData } from './RolesData';
import { roleColumns, searchFields } from './RolesExtra';
import { getPageHeaderExtra } from '../../Employee/EmployeeExtra';
import RoleForm from './components/RoleForm';
import { getPopupList } from '../../../../utils/Components/Popup/PopupExtra';
import { rolesPermission } from './Roles.permission';

const Roles = React.memo(() => {
  return (
    <AbstractPage
      searchFields={searchFields}
      columns={roleColumns}
      pageHeaderExtra={getPageHeaderExtra(rolesPermission.create)}
      AddComponent={<RoleForm type={'add'} />}
      EditComponent={<RoleForm type={'edit'} />}
      popupList={getPopupList(rolesPermission)}
      {...rolesData}
    />
  );
});
Roles.displayName = 'Roles';
export default Roles;
