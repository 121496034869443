import { getPopupList } from '../../../../utils/Components/Popup/PopupExtra';
import {
  setContentWorkDirection,
  setDataWorkDirection,
} from '../../../../store/reducers/WorkDirection';
import ColorForm from '../../../../utils/Components/Dictionary/components/ColorForm';
import React from 'react';
import {
  description,
  getInitialColumns,
  getSearchFields,
  workingMethodPermission,
} from '../../../../utils/Components/Dictionary/DictionaryExtra';
import { PermissionPage } from '../../../../AppTypes';
import { DictionaryData } from '../../../../utils/Components/Dictionary/types';

export const workDirectionPermission: PermissionPage = {
  delete: 'deleteWorkDirection',
  create: 'createWorkDirection',
  all: 'getAllWorkDirection',
  one: 'getWorkDirection',
  update: 'updateWorkDirection',
};

export const workDirectionDictionaryData: DictionaryData = {
  apiMethod: 'api/references/work-direction',
  dataListName: 'workDirectionList',
  idName: 'id',
  pageHeaderTitle: 'Направления работ',
  tabNameField: 'title',
  reducer: 'workDirectionReducer',
  columnsName: 'WorkDirectionTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList(workDirectionPermission),
  setData: setDataWorkDirection,
  setContent: setContentWorkDirection,
  addComponent: <ColorForm type={'add'} idName={'workDirectionId'} />,
  editComponent: <ColorForm type={'edit'} idName={'workDirectionId'} />,
  permission: workDirectionPermission,
};

export const onceWorkDirectionProps = {
  idName: 'id',
  apiMethod: 'api/references/work-direction',
  dataListName: 'workDirection',
  name: 'title',
  descriptions: description,
  permission: workingMethodPermission,
  editComponent: <ColorForm type={'edit'} idName={'workDirectionId'} />,
};
