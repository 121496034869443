export const API_URLS = {
  EMPLOYEE: 'api/employee',
  INTERVIEW_REQUEST: 'api/interviews-request',
  QUESTIONNAIRE: 'api/questionnaire',
  ACCOUNTS: 'api/accounts',
  PROJECTS: 'api/projects',
  POSITIONS: 'api/references/positions',
  DEPARTMENTS: 'api/references/department',
  WORKPLACES: 'api/references/workplace',
  REFUSALS: 'api/references/refusals',
  RESULTS_INTERVIEW: 'api/references/interviewers-decision',
  DEVICES: 'api/device',
  BRANDS: 'api/references/brand',
  COMPONENTS: 'api/references/components',
  SET_TO_DEVICES: 'api/set-of-device',
  DEVICE_TO_EMPLOYEE: 'api/device-to-employee',
  ROLES: 'api/role',
  PERMISSIONS: 'api/permission',
  RESUME_HISTORY: 'api/resume-download-history',
};
