import { DictionaryData, OnceRecordProps } from '../types';
import { getInitialColumns, getSearchFields } from '../DictionaryExtra';
import { getPopupList } from '../../Popup/PopupExtra';
import {
  setContentKeySkills,
  setDataKeySkills,
} from '../../../../store/reducers/KeySkills';
import { PermissionPage } from '../../../../AppTypes';

const idName = 'id';
const api = 'api/key-skill';
const name = 'title';

export const keySkillsPermission: PermissionPage = {
  all: 'getAllKeySkill',
  delete: 'deleteKeySkill',
  update: 'updateKeySkill',
  create: 'createKeySkill',
  one: 'getKeySkill',
};

export const keySkillsData: DictionaryData = {
  apiMethod: api,
  dataListName: 'keySkillList',
  idName: idName,
  pageHeaderTitle: 'Ключевые навыки',
  tabNameField: name,
  reducer: 'keySkillsReducer',
  columnsName: 'KeySkillsTableTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList(keySkillsPermission),
  setData: setDataKeySkills,
  setContent: setContentKeySkills,
  permission: keySkillsPermission,
};

export const onceKeySkillsProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'keySkillData',
  name: name,
  permission: keySkillsPermission,
};
