import React from 'react';
import { AbstractClassProps, AbstractClassState } from './AbstractClassTypes';
import API from '../../API/API';
import { notification, Spin } from 'antd/es';
import store from '../../../store/store';
import dayjs from 'dayjs';
import { additionalValues } from '../../../store/reducers/WorkplaceTabs';
import AbstractClassViewer from './Components/AbstractClassViewer';
import { isDesktop, isMobile } from 'react-device-detect';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import AbstractError from '../AbstractError/AbstractError';

/**
 * Класс для отображения универсальной таблицы
 * @class
 */
export default class AbstractClass extends React.PureComponent<
  AbstractClassProps,
  AbstractClassState
> {
  private apiMethod;
  private routerProps;
  private dataListName;
  private idName;
  private tabNameField;
  private currentRoute;

  /**
   * Конструктор класса AbstractClass
   * @param {AbstractClassProps} props
   */
  constructor(props) {
    super(props);

    this.apiMethod = props.apiMethod;
    this.routerProps = props.routerProps;
    this.dataListName = props.dataListName;
    this.idName = props.idName;
    this.tabNameField = props.tabNameField;
    this.currentRoute = props?.currentRoute ? props.currentRoute : null;

    this.state = {
      loading: false,
      currentPage: props.currentTab?.page ?? 0,
      currentSize: props.currentTab?.size ?? 10,
      sorter: [],
      searchParamsPanel: new URLSearchParams(),
      isAdd: false,
      isEdit: false,
      isDownload: false,
      index: 0,
      isFetching: false,
      isMultiUpload: false,
      isDuplicate: false,
      error: undefined,
    };
  }

  /**
   * componentDidMount - метод жизненного цикла класса,
   * наследуемого от React.Component
   * Срабатывает только в случае вмонтирования и отрисовки класса в DOM.
   * Вызыываем метод получения данных на данном этапе потому,
   * что this.setState срабатывает только на отрисованных компонентах.
   */
  componentDidMount() {
    const { data, content } = this.props;

    if (!data || !content) {
      this.getData();
    }
  }
  /**
   * Удаление данных из redux при закрытии таба
   */
  componentWillUnmount() {
    const { setData, setContent, routerProps } = this.props;
    const { tabs } = store.getState().workplaceTabsReducer;
    const isDeleteTab = !tabs.find(
      (tab) => tab.key === routerProps?.location.pathname
    );
    if (isDeleteTab) {
      store.dispatch(setData(null));
      store.dispatch(setContent(null));
    }
  }

  /**
   * Метод поднимающий index в state на 1
   */
  incrementIndex = () => {
    this.setState((state) => {
      return { index: state.index + 1 };
    });
  };
  /**
   * Метод для управлением состоянием кнопки при отправке формы
   * @param {boolean} value
   */
  setFetching = (value) => {
    this.setState({ isFetching: value });
  };

  /**
   * Метод для получения данных.
   * Обрабатывает данные из поля класса, наследуемого
   * из React.Component - state и добавляет к параметрам поиска.
   *
   * Обрабатываемые поля:
   *   из панели серверного поиска данных.
   *
   * Метод записывает данные в state методом {@link setData}
   * @param {boolean} resetContent
   */
  getData = (resetContent?) => {
    const self = this;
    const currentLink = this.getCurrentLink();
    const { setSearchParams } = this.props.routerProps!;

    const searchParams = currentLink.split('?')[1];

    store.dispatch(
      additionalValues([
        { action: 'add', key: 'searchParams', value: searchParams.toString() },
      ])
    );
    setSearchParams(searchParams);

    self.setState({ loading: true }, () => {
      API.get(currentLink)
        .then((response) => {
          self.setData(response, resetContent);
        })
        .catch(function (error) {
          if (error.response.status) {
            self.setState({ error: error.response });
          }
          self.setState({ loading: false });
        });
    });
  };
  /**
   * Отвечает за видимость окна загрузки
   * @param {boolean} item
   */
  setIsMultiUpload = (item) => {
    this.setState({ isMultiUpload: item });
  };

  /**
   * Метод формирует текущую ссылку с учетем сортировки, поиска, страницы.
   * @param {boolean} isReturn
   * @return {string}
   */
  getCurrentLink = (isReturn = false) => {
    const params = new URLSearchParams(window.location.search);

    if (!params.has('page')) {
      params.append('page', '0');
    }

    if (!params.has('size')) {
      params.append('size', '10');
    }

    if (isReturn) params.append('isReturn', 'true');

    return this.props.apiMethod.split('?')[0] + `?${params}`;
  };

  /**
   * Метод для записи данных в state.
   * @param {any} response - данные полученные из запроса.
   * @param resetContent
   */
  setData = (response, resetContent?) => {
    const { content } = this.props;
    const responseData = response.data;
    const responseContent =
      isDesktop || resetContent
        ? responseData[this.dataListName].content
        : [...(content ?? []), ...responseData[this.dataListName].content];
    const { setData, setContent } = this.props;
    store.dispatch(setData(responseData[this.dataListName]));
    store.dispatch(setContent(responseContent));
    this.setState({ loading: false });
  };

  /**
   * Метод для получения данных из панели поиска
   * и делигирования данных для записи в state
   * @param {any} response - данные полученные из запроса,
   * отправленные панелью поиска.
   */
  onSearchFinish = (response) => {
    const { rowSelect } = this.props;
    rowSelect?.onSelectRows([], []);
    this.setData(response, true);
  };

  /**
   * Метод для записи в state полей и их значений,
   * а также для блокировки интерфейса пользователя во время запроса данных.
   * @param {URLSearchParams} searchParams - поля и значения из панели поиска.
   */
  onLoadSearch = (searchParams: URLSearchParams) => {
    const { routerProps } = this.props;
    this.setState(() => {
      const params = new URLSearchParams(searchParams);
      const lastUrl = new URLSearchParams(window.location.search);

      params.set('page', '0');
      params.set('size', lastUrl.get('size') ?? '10');
      lastUrl.get('sort') && params.set('sort', lastUrl.get('sort')!);

      routerProps?.setSearchParams(params);

      store.dispatch(
        additionalValues([
          { action: 'add', key: 'searchParams', value: params.toString() },
        ])
      );
      return {
        searchParamsPanel: searchParams,
        loading: true,
      };
    });
  };

  /**
   * Метод для создания записи.
   * @param {any} value - принимает любое значение для отправки на сервер.
   */
  onFinishAdd = async (value: any) => {
    const self = this;
    const { isAdd } = this.state;

    this.setFetching(true);
    try {
      const res = await API.post(this.getCurrentLink(true), value);
      isAdd ? self.setIsAdd(false) : self.setIsDuplicate(false);
      self.setFetching(false);
      this.setData(res);
      return res;
    } catch {
      self.setFetching(false);
      throw Error;
    }
  };

  /**
   * Метод для редактирования записи.
   * @param {any} value - принимает любое значение для отправки на сервер
   */
  onFinishEdit = async (value: any) => {
    const self = this;

    self.setFetching(true);
    return new Promise((res, reject) => {
      API.put(this.getCurrentLink(isDesktop), value)
        .then((response) => {
          isMobile ? self.getData(isMobile) : self.setData(response);
          self.setIsEdit(false);
          self.setFetching(false);
          return res(response);
        })
        .catch((error) => {
          self.setFetching(false);
          return reject(error);
        });
    });
  };

  /**
   * Метод для удаления записи.
   * @param {any} value - принимает ID для удаления запси.
   */
  onFinishDelete = (value: number) => {
    const apiMethod = this.props.apiMethod.split('?')[0];
    const url = `${apiMethod}/${value}${window.location.search}&isReturn=true`;
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') ? +params.get('page')! : 0;

    const { content } = this.props;

    if (content?.length === 1) {
      const prevPage = page > 0 ? page - 1 : 0;
      params.set('page', prevPage.toString());
      if (page)
        store.dispatch(
          additionalValues([
            { action: 'add', key: 'searchParams', value: params.toString() },
          ])
        );
    }

    return API.delete(url).then((response) => {
      this.setData(response);
    });
  };
  /**
   * Метод для массового удаления
   * @param {Array<number>} ids
   */
  onFinishMassDelete = (ids: Array<number>) => {
    const self = this;
    const { rowSelect } = this.props;
    const arrFetch: any = [];
    ids.forEach((id) =>
      arrFetch.push(API.delete(`${this.apiMethod}?${this.idName}=${id}`))
    );
    Promise.all(arrFetch).then(() => {
      self.getData();
      rowSelect?.onSelectRows([], []);
      notification['success']({
        message: 'Удаление записей выполнено успешно',
      });
    });
  };

  /**
   * Метод для работы с пагинацией и сортировкой.
   * @param {any} data - данные отправленные из таблицы <Table>
   *   от antd методом onChange.
   * После проверки объектов пагинации и сортировки вызывает отдельные методы.
   * Проверка стоит, потому что могут прилтеть пустые объекты.
   * Для пагинации: {@link onChangePagination}
   * Для сортировки: {@link onChangeSorter}
   */
  onChangeTable = (data) => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');
    const size = params.get('size');

    if (
      (!isEmpty(data.pagination) &&
        data.pagination.current - 1 !== (page ? +page : null)) ||
      data.pagination.pageSize !== (size ? +size : null)
    ) {
      this.onChangePagination(data.pagination);
    }
    if (!isEmpty(data.sorter)) this.onChangeSorter(data.sorter);
  };

  /**
   * Метод для изменения параметров пагинации таблицы для запросов.
   * @param {any} pagination - объект пагинации присланный
   * из метода {@link onChangeTable}
   */
  onChangePagination = (pagination) => {
    const { routerProps } = this.props;

    const paramsUrl = new URLSearchParams(window.location.search);

    const param = new URLSearchParams(paramsUrl);
    param.set('page', (pagination.current - 1).toString());
    param.set('size', pagination.pageSize.toString());

    routerProps?.setSearchParams(param);

    this.getData();
  };

  /**
   * Метод для изменения параметров сортировки таблицы для запросов.
   * @param {any} sorter - объект пагинации присланный
   * из метода {@link onChangeTable}
   */
  onChangeSorter = (sorter) => {
    const { routerProps } = this.props;
    const params = new URLSearchParams(window.location.search);

    if (sorter.order) {
      params.set(
        'sort',
        `${sorter.columnKey},${sorter.order === 'ascend' ? 'asc' : 'desc'}`
      );
    } else {
      params.delete('sort');
    }

    routerProps?.setSearchParams(params);

    this.getData();
  };

  /**
   * Метод открытия и закрытия окна редактирования
   * @param {any} value
   */
  setIsEdit = (value) => {
    this.setState({ isEdit: value });
  };

  /**
   * Метод открытия и закрытия окна создания
   * @param {any} value
   */
  setIsAdd = (value) => {
    this.setState({ isAdd: value });
  };

  /**
   * Метод открытия и закрытия окна дублирования
   * @param {any} value
   */
  setIsDuplicate = (value) => {
    this.setState({ isDuplicate: value });
  };

  /**
   * Метод перехода к записи
   * @param {any} currentRow
   */
  gotoRecord = (currentRow) => {
    const { routerProps, gotoRecordLink, tabNameField, currentRoute } =
      this.props;
    const mainRoute = gotoRecordLink ?? currentRoute;
    const route = mainRoute ?? routerProps!.location.pathname;
    const tabName =
      typeof tabNameField === 'function'
        ? tabNameField(currentRow)
        : get(currentRow, tabNameField);
    routerProps!.navigate(`${route}/${currentRow[this.idName]}`, {
      replace: true,
      state: { name: tabName },
    });
  };
  /**
   * @param {any} currentRow
   */
  goToPermissions = (currentRow) => {
    const { setCurrentRecord } = this.props;
    setCurrentRecord!(currentRow);
  };

  /**
   * Метод для получение записи
   * @param {any} currentRow
   * @return {any}
   */
  getRecord = (currentRow: any) => {
    return currentRow;
  };

  /**
   * @param {ArrayBuffer} buffer
   * @param {string} fileName
   */
  saveData = (buffer, fileName) => {
    const { toggleDownload } = this.props;
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a['style'] = 'display: none';
    const blob = new Blob([buffer]);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    toggleDownload!(false);
  };
  /**
   * Метод для работы с основным бэком
   * @param {string} path
   * @param {string} format
   */
  downloadFile = ([path, format]) => {
    const self = this;
    const { pageHeaderTitle } = this.props;
    const { toggleDownload } = this.props;
    const currentTab = store.getState().workplaceTabsReducer.currentTab;
    const searchParams = currentTab?.searchParams;
    const urlSeachParams = new URLSearchParams();
    // eslint-disable-next-line guard-for-in
    for (const searchParamsKey in searchParams) {
      urlSeachParams.append(searchParamsKey, searchParams[searchParamsKey]);
    }
    toggleDownload!(true);
    API.get(path, {
      responseType: 'blob',
      params: urlSeachParams,
    })
      .then((response) => {
        toggleDownload!(false);
        self.saveData(
          response.data,
          `${pageHeaderTitle} ${dayjs().format('DD.MM.YY HH.mm')}.${format}`
        );
      })
      .catch(() => {
        toggleDownload!(false);
      });
  };

  /**
   * Добавить метод render к колонкам из локального хранилища
   * @param {any} columns
   * @return {any}
   */
  renderColumns = (columns) => {
    const initialColumns = this.props.columns;
    return columns
      .filter((col) => col.visible)
      .reduce((prev, col) => {
        const initCol = initialColumns.find(
          (initCol) => initCol.key === col.key
        );
        return initCol
          ? [...prev, initCol.render ? { ...col, render: initCol.render } : col]
          : prev;
      }, []);
  };

  /**
   * Стандартный метод рендера компонента
   * @return {JSX}
   */
  render() {
    const {
      searchFields,
      routerProps,
      pageHeaderTitle,
      pageHeaderExtra,
      columns,
      EditComponent,
      AddComponent,
      popupList,
      rowSelect,
      editModalWidth,
      createModalWidth,
      excelDownload,
      columnsName,
      pageHeaderExtraComponents,
      additionalContent,
      data,
      content,
      uploadSettings,
      rowClassName,
      expandable,
      notGotRecord,
      kanban,
      mobileColumns,
      getListItemMobile,
      setCurrentRow,
      DuplicateComponent,
      onRow,
    } = this.props;
    const {
      loading,
      isDuplicate,
      isEdit,
      isAdd,
      isDownload,
      isFetching,
      isMultiUpload,
      error,
    } = this.state;
    const initialColumns = cloneDeep(columns);
    const tablesSettings = store.getState().abstractColumnsSlice.settings;
    const currentColum = tablesSettings?.[columnsName!] || null;
    const rawColumns = currentColum
      ? this.renderColumns(currentColum!)
      : initialColumns;

    return (
      <Spin tip={'Загрузка...'} spinning={loading} style={{ marginTop: 32 }}>
        {error && <AbstractError error={error} />}
        {(data || isMobile) && (
          <AbstractClassViewer
            apiMethod={this.getCurrentLink()}
            idName={this.idName}
            pageHeaderTitle={pageHeaderTitle}
            data={data}
            content={content}
            routerProps={routerProps!}
            setIsAdd={this.setIsAdd}
            isAdd={isAdd}
            AddComponent={AddComponent}
            onFinishAdd={this.onFinishAdd}
            onFinishDelete={this.onFinishDelete}
            onFinishMassDelete={this.onFinishMassDelete}
            onFinishEdit={this.onFinishEdit}
            setIsEdit={this.setIsEdit}
            isEdit={isEdit}
            setIsDuplicate={this.setIsDuplicate}
            isDuplicate={isDuplicate}
            DuplicateComponent={DuplicateComponent}
            EditComponent={EditComponent}
            onChangeTable={this.onChangeTable}
            onSearchFinish={this.onSearchFinish}
            onLoadSearch={this.onLoadSearch}
            searchFields={searchFields}
            pageHeaderExtra={pageHeaderExtra}
            columns={rawColumns}
            initialColumns={initialColumns}
            gotoRecord={
              !routerProps?.location?.pathname.includes('permissions')
                ? this.gotoRecord
                : this.goToPermissions
            }
            popupList={popupList}
            // @ts-ignore
            rowSelect={rowSelect}
            editModalWidth={editModalWidth}
            createModalWidth={createModalWidth}
            downloadFile={this.downloadFile}
            isDownload={isDownload}
            excelDownload={excelDownload}
            getData={this.getData}
            getRecord={this.getRecord}
            key={routerProps?.location?.key}
            getCurrentLink={this.getCurrentLink}
            totalElements={this.props.totalElements}
            scrollX={this.props?.scrollX ? this.props.scrollX : null}
            columnsName={columnsName}
            incrementIndex={this.incrementIndex}
            pageHeaderExtraComponents={pageHeaderExtraComponents}
            additionalContent={additionalContent}
            isFetching={isFetching}
            uploadSettings={uploadSettings}
            setIsMultiUpload={this.setIsMultiUpload}
            isMultiUpload={isMultiUpload}
            rowClassName={rowClassName}
            expandable={expandable}
            notGotRecord={notGotRecord}
            kanban={kanban}
            mobileColumns={mobileColumns}
            getListItemMobile={getListItemMobile}
            loading={loading}
            setCurrentRow={setCurrentRow}
            onRow={onRow}
          />
        )}
      </Spin>
    );
  }
}
