import React from 'react';
import AbstractElementF from '../../../../../utils/Components/AbstractElement/AbstractElementF';
import { rolesData } from '../RolesData';
import RoleForm from '../components/RoleForm';
import { Role } from '../../Accounts/type';
import { tabsList } from '../../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { Table } from 'antd';
import { rolesPermission } from '../Roles.permission';

const OnceRoles = React.memo(() => {
  const tabs: tabsList = [
    {
      title: 'Список прав',
      component: (content: Role) => (
        <Table
          dataSource={content.permissionSet}
          columns={[
            { title: 'Наименование', dataIndex: 'title', align: 'center' },
          ]}
          size={'small'}
          pagination={false}
        />
      ),
      extra: null,
    },
  ];
  return (
    <AbstractElementF
      apiMethod={rolesData.apiMethod}
      dataListName={'role'}
      editPermission={rolesPermission.update}
      editComponent={<RoleForm type={'edit'} />}
      tabs={tabs}
      pageHeaderList={{
        pass: (record: Role) => `${record.title} ${record.description}`,
        title: 'Роль',
      }}
    />
  );
});
OnceRoles.displayName = 'OnceRoles';
export default OnceRoles;
