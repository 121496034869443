import { createBrowserRouter, useNavigate } from 'react-router-dom';
import React, { lazy } from 'react';
import App from '../../App';
import WorkspaceLayout from '../Layouts/PageLayout';
import {
  citizenshipDictionaryData,
  citizenshipPermission,
  onceCitizenshipProps,
  onceOrganizationProps,
  onceSubWorkDirectionProps,
  onceWorkingMethodProps,
  organizationDictionaryData,
  organizationPermission,
  subWorkDirectionDictionaryData,
  subWorkDirectionPermission,
  workingMethodDictionaryData,
  workingMethodPermission,
} from '../../utils/Components/Dictionary/DictionaryExtra';
import DictionaryComponentNew from '../../utils/Components/Dictionary/DictionaryComponentNew';
import OnceRecord from '../../utils/Components/Dictionary/OnceRecord';
import {
  onceWorkStatusProps,
  workStatusDictionaryData,
  workStatusPermission,
} from '../../utils/Components/Dictionary/extra/workStatus';
import {
  locationEmployeeData,
  locationEmployeePermission,
  onceLocationEmployeeProps,
} from '../../utils/Components/Dictionary/extra/locationEmployee';
import { useAppSelector } from '../../store/rootReducer';
import { routerPermissionSetter } from '../../utils/Base/helpers/permissionHelpers';
import { Button, Result } from 'antd/es';
import { useDispatch } from 'react-redux';
import {
  setActiveKey,
  setInitialTab,
} from '../../store/reducers/WorkplaceTabs';
import {
  onceWorkDirectionProps,
  workDirectionPermission,
} from '../Pages/Dictionary/WorkDirection/WorkDirectionExtra';
import Redirect from '../Pages/Redirect/Redirect';
import {
  keySkillsData,
  keySkillsPermission,
  onceKeySkillsProps,
} from '../../utils/Components/Dictionary/extra/keySkillsData';
import {
  onceProjectProps,
  projectDictionaryData,
  projectPermission,
} from '../../utils/Components/Dictionary/extra/projectData';
import {
  oncePositionsProps,
  positionDictionaryData,
  positionPermission,
} from '../../utils/Components/Dictionary/extra/positionsData';
import {
  departmentDictionaryData,
  departmentPermission,
  onceDepartmentProps,
} from '../../utils/Components/Dictionary/extra/departmentData';
import {
  onceWorkplaceProps,
  workplaceDictionaryData,
  workplacePermission,
} from '../../utils/Components/Dictionary/extra/workplaceData';
import {
  onceRefusalProps,
  refusalDictionaryData,
  refusalPermission,
} from '../../utils/Components/Dictionary/extra/refusalData';
import {
  onceResultInterviewProps,
  resultInterviewDictionaryData,
  resultInterviewPermission,
} from '../../utils/Components/Dictionary/extra/resultInterviewData';
import { Alert } from 'antd';
import EmployeeDevices from '../Pages/EmpoyeeDevices/EmployeeDevices';
import OnceEmployeeDevices from '../Pages/EmpoyeeDevices/OnceEmployeeDevices/OnceEmployeeDevices';
import {
  brandPermission,
  brandsData,
  onceBrandsProps,
} from '../../utils/Components/Dictionary/extra/brandData';
import Devices from '../Pages/EmpoyeeDevices/Devices/Devices';
import ComponentDevice from '../Pages/EmpoyeeDevices/ComponentsDevice/ComponentDevice';
import OnceComponentDevices from '../Pages/EmpoyeeDevices/ComponentsDevice/OnceComponentsDevice/OnceComponentDevices';
import OnceDevices from '../Pages/EmpoyeeDevices/Devices/OnceDevices/OnceDevices';
import SetToDevices from '../Pages/EmpoyeeDevices/SetToDevices/SetToDevices';
import OnceSetToDevice from '../Pages/EmpoyeeDevices/SetToDevices/OnceSetToDevice/OnceSetToDevice';
import DeviceToEmployee from '../Pages/EmpoyeeDevices/DeviceToEmployee/DeviceToEmployee';
import OnceDeviceToEmployee from '../Pages/EmpoyeeDevices/DeviceToEmployee/OnceDeviceToEmployee/OnceDeviceToEmployee';
import Roles from '../Pages/Admin/Roles/Roles';
import OnceRoles from '../Pages/Admin/Roles/OnceRoles/OnceRoles';
import { candidatesPermissions } from '../Pages/Сandidates/Candidates.permissions';
import { questionnairePermission } from '../Pages/Questionnaire/Questionnaire.permission';
import { completedQuestionnairePermission } from '../Pages/CompletedQuestionnaire/CompletedQuestionnaire.permission';
import { employeePermission } from '../Pages/Employee/Employee.permission';
import { employeeRepostCardPermission } from '../Pages/EmployeeRepostCard/EmployeeRepostCard.permission';
import { interviewRequestPermission } from '../Pages/InterviewRequest/InterviewRequest.permission';
import { devicePermission } from '../Pages/EmpoyeeDevices/Devices/Device.permission';
import { componentDevicePermission } from '../Pages/EmpoyeeDevices/ComponentsDevice/ComponentDevice.permission';
import { setToDevicePermission } from '../Pages/EmpoyeeDevices/SetToDevices/SetToDevice.permission';
import { deviceToEmployeePermission } from '../Pages/EmpoyeeDevices/DeviceToEmployee/DeviceToEmployee.permission';
import { rolesPermission } from '../Pages/Admin/Roles/Roles.permission';
import HistoryResume from '../Pages/HistoryResume/HistoryResume';
import { historyResumePermission } from '../Pages/HistoryResume/HistoryResume.permission';

const OnceEmployee = lazy(
  () => import('../Pages/Employee/OnceEmployee/OnceEmployee')
);
const Employee = lazy(() => import('../Pages/Employee/Employee'));
const EmployeeReportCard = React.lazy(
  () => import('../Pages/EmployeeRepostCard/EmployeeReportCard')
);
const OnceEmployeeReportCard = React.lazy(
  () =>
    import(
      '../Pages/EmployeeRepostCard/OnceEmployeeReportCard/OnceEmployeeReportCard'
    )
);
const DepartmentDirectionBond = React.lazy(
  () => import('../Pages/DepartmentDirectionBond/DepartmentDirectionBond')
);
const OnceDepartmentDirectionBond = React.lazy(
  () =>
    import(
      '../Pages/DepartmentDirectionBond/OnceDepartmentDirectionBond/OnceDepartmentDirectionBond'
    )
);
const TableReportCard = React.lazy(
  () => import('../Pages/EmployeeRepostCard/TableReportCard')
);
const InterviewRequest = React.lazy(
  () => import('../Pages/InterviewRequest/InterviewRequest')
);
const OnceInterviewRequest = React.lazy(
  () =>
    import(
      '../Pages/InterviewRequest/OnceInterviewRequest/OnceInterviewRequest'
    )
);
const WorkDirection = React.lazy(
  () => import('../Pages/Dictionary/WorkDirection/WorkDirection')
);
const OnceAccount = lazy(() => import('../Pages/Profile/OnceAccount'));
// const Logs = lazy(() => import('../Pages/Logs/Logs'));
const Candidates = lazy(() => import('../Pages/Сandidates/Candidates'));
const Questionnaire = lazy(
  () => import('../Pages/Questionnaire/Questionnaire')
);
const OnceQuestionnaire = lazy(
  () => import('../Pages/Questionnaire/OnceQuestionnaire/OnceQuestionnaire')
);
const OnceCompletedQuestionnaire = lazy(
  () =>
    import(
      '../Pages/CompletedQuestionnaire/OnceCompletedQuestionnaire/OnceCompletedQuestionnaire'
    )
);
const Accounts = lazy(() => import('../Pages/Admin/Accounts/Accounts'));
const CandidateInfo = lazy(
  () => import('../Pages/CandidateInfo/CandidateInfo')
);
const CompletedQuestionnaire = lazy(
  () => import('../Pages/CompletedQuestionnaire/CompletedQuestionnaire')
);
const MainPage = lazy(() => import('../Pages/MainPage/MainPage'));

const ElementWrapper = ({
  name,
  children,
  permission,
}: {
  name: string;
  children: React.ReactNode;
  permission: Array<string>;
}) => {
  const profile = useAppSelector((state) => state.authReducer.profile);
  const permissionList = useAppSelector(
    (state) => state.authReducer.permissionList
  );
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { tabs, activeKey } = useAppSelector(
    (state) => state.workplaceTabsReducer
  );
  const onHome = () => {
    navigation('/', { replace: true });
    dispatch(setInitialTab(tabs.filter((tab) => tab.key !== activeKey)));
    dispatch(setActiveKey('/'));
  };

  return (
    <WorkspaceLayout name={name} permission={permission}>
      {routerPermissionSetter(permission, permissionList, profile) ? (
        children
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Извините, у вас нет доступа к данному контенту."
          extra={
            <Button onClick={onHome} type="primary">
              Вернуться домой
            </Button>
          }
        />
      )}
    </WorkspaceLayout>
  );
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <ElementWrapper name="Главная страница" permission={[]}>
            <MainPage />
          </ElementWrapper>
        ),
      },
      {
        path: '/projects',
        element: (
          <ElementWrapper name="Проекты" permission={[projectPermission.all]}>
            <DictionaryComponentNew dictionaryData={projectDictionaryData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/projects/:id',
        element: (
          <ElementWrapper name="Проекты" permission={[projectPermission.one]}>
            <OnceRecord {...onceProjectProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/departments',
        element: (
          <ElementWrapper name="Отделы" permission={[departmentPermission.all]}>
            <DictionaryComponentNew dictionaryData={departmentDictionaryData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/departments/:id',
        element: (
          <ElementWrapper name="Отдел" permission={[departmentPermission.one]}>
            <OnceRecord {...onceDepartmentProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/workplaces',
        element: (
          <ElementWrapper
            name="Места работы"
            permission={[workplacePermission.all]}
          >
            <DictionaryComponentNew dictionaryData={workplaceDictionaryData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/workplaces/:id',
        element: (
          <ElementWrapper
            name="Место работы"
            permission={['WorkplaceController']}
          >
            <OnceRecord {...onceWorkplaceProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/candidates/:id',
        element: (
          <ElementWrapper
            name="Кандидат"
            permission={[candidatesPermissions.all]}
          >
            <CandidateInfo />
          </ElementWrapper>
        ),
      },
      {
        path: '/logs',
        element: (
          <ElementWrapper name="История" permission={[]}>
            {/* <Logs /> */}
            <Alert
              message={'Данный раздел находится в доработке'}
              showIcon
              style={{ marginTop: 16 }}
            />
          </ElementWrapper>
        ),
      },
      {
        path: '/positions',
        element: (
          <ElementWrapper
            name="Должности"
            permission={[positionPermission.all]}
          >
            <DictionaryComponentNew dictionaryData={positionDictionaryData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/positions/:id',
        element: (
          <ElementWrapper
            name="Должность"
            permission={[positionPermission.one]}
          >
            <OnceRecord {...oncePositionsProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/refusals',
        element: (
          <ElementWrapper
            name="Причины отказа"
            permission={[refusalPermission.all]}
          >
            <DictionaryComponentNew dictionaryData={refusalDictionaryData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/refusals/:id',
        element: (
          <ElementWrapper
            name="Причина отказа"
            permission={[refusalPermission.one]}
          >
            <OnceRecord {...onceRefusalProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/result-interview',
        element: (
          <ElementWrapper
            name="Результаты интервью"
            permission={[resultInterviewPermission.all]}
          >
            <DictionaryComponentNew
              dictionaryData={resultInterviewDictionaryData}
            />
          </ElementWrapper>
        ),
      },
      {
        path: '/result-interview/:id',
        element: (
          <ElementWrapper
            name="Результаты интервью"
            permission={[resultInterviewPermission.one]}
          >
            <OnceRecord {...onceResultInterviewProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/accounts',
        element: (
          <ElementWrapper name="Аккаунты" permission={[]}>
            <Accounts />
          </ElementWrapper>
        ),
      },
      {
        path: '/accounts/:id',
        element: (
          <ElementWrapper name="Аккаунт" permission={[]}>
            <OnceAccount />
          </ElementWrapper>
        ),
      },
      {
        path: '/candidates',
        element: (
          <ElementWrapper
            name="Кандидаты"
            permission={[candidatesPermissions.all]}
          >
            <Candidates />
          </ElementWrapper>
        ),
      },
      {
        path: '/questionnaire',
        element: (
          <ElementWrapper
            name="Опросники"
            permission={[questionnairePermission.all]}
          >
            <Questionnaire />
          </ElementWrapper>
        ),
      },
      {
        path: '/questionnaire/:id',
        element: (
          <ElementWrapper
            name="Опросник"
            permission={[questionnairePermission.one]}
          >
            <OnceQuestionnaire />
          </ElementWrapper>
        ),
      },
      {
        path: '/completed-questionnaire',
        element: (
          <ElementWrapper
            name="Пройденные опросы"
            permission={[completedQuestionnairePermission.all]}
          >
            <CompletedQuestionnaire />
          </ElementWrapper>
        ),
      },
      {
        path: '/completed-questionnaire/:id',
        element: (
          <ElementWrapper
            name="Пройденные опросы"
            permission={[completedQuestionnairePermission.one]}
          >
            <OnceCompletedQuestionnaire />
          </ElementWrapper>
        ),
      },
      {
        path: '/redirect',
        element: <Redirect />,
      },
      {
        path: '/organization',
        element: (
          <ElementWrapper
            name="Организации"
            permission={[organizationPermission.all]}
          >
            <DictionaryComponentNew
              dictionaryData={organizationDictionaryData}
            />
          </ElementWrapper>
        ),
      },
      {
        path: '/organization/:id',
        element: (
          <ElementWrapper
            name="Организация"
            permission={[organizationPermission.one]}
          >
            <OnceRecord {...onceOrganizationProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/working-method',
        element: (
          <ElementWrapper
            name="Методы работы"
            permission={[workingMethodPermission.all]}
          >
            <DictionaryComponentNew
              dictionaryData={workingMethodDictionaryData}
            />
          </ElementWrapper>
        ),
      },
      {
        path: '/working-method/:id',
        element: (
          <ElementWrapper
            name="Метод работы"
            permission={[workingMethodPermission.one]}
          >
            <OnceRecord {...onceWorkingMethodProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/citizenship',
        element: (
          <ElementWrapper
            name="Список граждаснств"
            permission={[citizenshipPermission.all]}
          >
            <DictionaryComponentNew
              dictionaryData={citizenshipDictionaryData}
            />
          </ElementWrapper>
        ),
      },
      {
        path: '/citizenship/:id',
        element: (
          <ElementWrapper
            name="Гражданство"
            permission={[citizenshipPermission.one]}
          >
            <OnceRecord {...onceCitizenshipProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/work-direction',
        element: (
          <ElementWrapper
            name="Направления работ"
            permission={[workDirectionPermission.all]}
          >
            <WorkDirection />
          </ElementWrapper>
        ),
      },
      {
        path: '/work-direction/:id',
        element: (
          <ElementWrapper
            name="Направление работы"
            permission={[workDirectionPermission.one]}
          >
            <OnceRecord {...onceWorkDirectionProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/sub-work-direction',
        element: (
          <ElementWrapper
            name="Поднаправления работ"
            permission={[subWorkDirectionPermission.all]}
          >
            <DictionaryComponentNew
              dictionaryData={subWorkDirectionDictionaryData}
            />
          </ElementWrapper>
        ),
      },
      {
        path: '/sub-work-direction/:id',
        element: (
          <ElementWrapper
            name="Пондаправление работы"
            permission={[subWorkDirectionPermission.one]}
          >
            <OnceRecord {...onceSubWorkDirectionProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/work-status',
        element: (
          <ElementWrapper
            name="Статусы работ"
            permission={[workStatusPermission.all]}
          >
            <DictionaryComponentNew dictionaryData={workStatusDictionaryData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/work-status/:id',
        element: (
          <ElementWrapper
            name="Статус работ"
            permission={[workStatusPermission.all]}
          >
            <OnceRecord {...onceWorkStatusProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/location-employee',
        element: (
          <ElementWrapper
            name="Местоположения сотрудников"
            permission={[locationEmployeePermission.all]}
          >
            <DictionaryComponentNew dictionaryData={locationEmployeeData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/location-employee/:id',
        element: (
          <ElementWrapper
            name="Местоположение сотрудника"
            permission={[locationEmployeePermission.one]}
          >
            <OnceRecord {...onceLocationEmployeeProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee',
        element: (
          <ElementWrapper
            name="Сотрудники"
            permission={[employeePermission.all]}
          >
            <Employee />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee/:id',
        element: (
          <ElementWrapper
            name="Сотрудник"
            permission={[employeePermission.one]}
          >
            <OnceEmployee />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee-report-card',
        element: (
          <ElementWrapper
            name="Табели сотрудников"
            permission={[employeeRepostCardPermission.all]}
          >
            <EmployeeReportCard />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee-report-card/:id',
        element: (
          <ElementWrapper
            name="Табель сотрудника"
            permission={[employeeRepostCardPermission.one]}
          >
            <OnceEmployeeReportCard />
          </ElementWrapper>
        ),
      },
      {
        path: '/departments-direction-work-bond',
        element: (
          <ElementWrapper
            name="Отдел - Направление - Поднаправление"
            permission={[departmentPermission.all]}
          >
            <DepartmentDirectionBond />
          </ElementWrapper>
        ),
      },
      {
        path: '/departments-direction-work-bond/:id',
        element: (
          <ElementWrapper
            name="Отдел - Направление - Поднаправление"
            permission={[departmentPermission.one]}
          >
            <OnceDepartmentDirectionBond />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee-report-card-summary',
        element: (
          <ElementWrapper
            name="Табель"
            permission={[employeeRepostCardPermission.all]}
          >
            <TableReportCard />
          </ElementWrapper>
        ),
      },
      {
        path: '/interviews-request',
        element: (
          <ElementWrapper
            name="Заявки на кандидатов"
            permission={[interviewRequestPermission.all]}
          >
            <InterviewRequest />
          </ElementWrapper>
        ),
      },
      {
        path: '/key-skills',
        element: (
          <ElementWrapper
            name="Ключевые навыки"
            permission={[keySkillsPermission.all]}
          >
            <DictionaryComponentNew dictionaryData={keySkillsData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/key-skills/:id',
        element: (
          <ElementWrapper
            name="Ключевой навык"
            permission={[keySkillsPermission.one]}
          >
            <OnceRecord {...onceKeySkillsProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee-devices',
        element: (
          <ElementWrapper
            name="Сотрудники"
            permission={[employeePermission.all]}
          >
            <EmployeeDevices />
          </ElementWrapper>
        ),
      },
      {
        path: '/employee-devices/:id',
        element: (
          <ElementWrapper
            name="Сотрудник"
            permission={[employeePermission.one]}
          >
            <OnceEmployeeDevices />
          </ElementWrapper>
        ),
      },
      {
        path: '/interviews-request/:id',
        element: (
          <ElementWrapper
            name="Заявки на кандидатов"
            permission={[interviewRequestPermission.one]}
          >
            <OnceInterviewRequest />
          </ElementWrapper>
        ),
      },
      {
        path: '/brands',
        element: (
          <ElementWrapper
            name="Компании производителей"
            permission={[brandPermission.all]}
          >
            <DictionaryComponentNew dictionaryData={brandsData} />
          </ElementWrapper>
        ),
      },
      {
        path: '/brands/:id',
        element: (
          <ElementWrapper
            name="Компания производителя"
            permission={[brandPermission.one]}
          >
            <OnceRecord {...onceBrandsProps} />
          </ElementWrapper>
        ),
      },
      {
        path: '/devices',
        element: (
          <ElementWrapper name="Устройства" permission={[devicePermission.all]}>
            <Devices />
          </ElementWrapper>
        ),
      },
      {
        path: '/devices/:id',
        element: (
          <ElementWrapper name="Устройство" permission={[devicePermission.one]}>
            <OnceDevices />
          </ElementWrapper>
        ),
      },
      {
        path: '/components',
        element: (
          <ElementWrapper
            name="Компоненты"
            permission={[componentDevicePermission.all]}
          >
            <ComponentDevice />
          </ElementWrapper>
        ),
      },
      {
        path: '/components/:id',
        element: (
          <ElementWrapper
            name="Компонент"
            permission={[componentDevicePermission.one]}
          >
            <OnceComponentDevices />
          </ElementWrapper>
        ),
      },
      {
        path: '/set-to-device',
        element: (
          <ElementWrapper
            name="Наборы устройств"
            permission={[setToDevicePermission.all]}
          >
            <SetToDevices />
          </ElementWrapper>
        ),
      },
      {
        path: '/set-to-device/:id',
        element: (
          <ElementWrapper
            name="Набор устройств"
            permission={[setToDevicePermission.one]}
          >
            <OnceSetToDevice />
          </ElementWrapper>
        ),
      },
      {
        path: '/device-to-employee',
        element: (
          <ElementWrapper
            name="Комплекты сотрудников"
            permission={[deviceToEmployeePermission.all]}
          >
            <DeviceToEmployee />
          </ElementWrapper>
        ),
      },
      {
        path: '/device-to-employee/:id',
        element: (
          <ElementWrapper
            name="Комплект сотрудника"
            permission={[deviceToEmployeePermission.one]}
          >
            <OnceDeviceToEmployee />
          </ElementWrapper>
        ),
      },
      {
        path: '/roles',
        element: (
          <ElementWrapper name="Роли" permission={[rolesPermission.all]}>
            <Roles />
          </ElementWrapper>
        ),
      },
      {
        path: '/roles/:id',
        element: (
          <ElementWrapper name="Роль" permission={[rolesPermission.one]}>
            <OnceRoles />
          </ElementWrapper>
        ),
      },
      {
        path: '/history-resume',
        element: (
          <ElementWrapper
            name="История просмотра резюме"
            permission={[historyResumePermission.all]}
          >
            <HistoryResume />
          </ElementWrapper>
        ),
      },
    ],
  },
]);
