import React from 'react';
import { Modal } from 'antd/es';
import { CollapsibleModalProps } from './types/CollapsibleModalTypes';
import { CaretDownOutlined } from '@ant-design/icons/es/icons';

/**
 * Модальное окно
 * @class
 */
export default class CollapsibleModal extends React.Component<
  CollapsibleModalProps,
  any
> {
  state = {
    isCollapsed: false,
    id: Date.now(),
  };

  /**
   * @param {boolean} value
   */
  setIsCollapsed = (value: boolean) => {
    this.setState({ isCollapsed: value });
    if (value) {
      let div;
      if (!document.getElementById('collapsibleModalDiv')) {
        div = document.createElement('div');
        div.style.position = 'fixed';
        div.style.zIndex = '1000';
        div.style.bottom = '0px';
        div.style.display = 'flex';
        div.style.justifyContent = 'space-evenly';
        div.id = 'collapsibleModalDiv';
      } else {
        div = document.getElementById('collapsibleModalDiv');
      }

      const button = document.createElement('button');
      button.id = `${this.state.id}`;
      button.style.marginLeft = '20px';
      button.innerText = this.props.title;
      button.className = 'btn-primary-custom';

      button.addEventListener('click', () => {
        this.setIsCollapsed(false);
      });

      div.appendChild(button);

      document.body.style.overflow = 'visible';
      document.body.appendChild(div);
    } else {
      document.getElementById(`${this.state.id}`)!.remove();
    }
  };

  // eslint-disable-next-line require-jsdoc
  render() {
    const { isCollapsed } = this.state;

    return (
      <div>
        <Modal
          title={
            <div
              style={{
                width: '92%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <>{this.props.title}</>
              <CaretDownOutlined
                style={{
                  cursor: 'pointer',
                  color: '#8c8c8c',
                  height: '100%',
                }}
                onClick={() => this.setIsCollapsed(true)}
              />
            </div>
          }
          // @ts-ignore
          onOk={this.props.onOk}
          destroyOnClose={this.props.destroyOnClose}
          okText={this.props.okText}
          mask={!isCollapsed}
          maskClosable={false}
          zIndex={isCollapsed ? -1000 : 1000}
          open={this.props.visible}
          width={this.props.width ? this.props.width : 600}
          footer={this.props.footer ? this.props.footer : null}
          // @ts-ignore
          onCancel={isCollapsed ? false : this.props.onCancel}
          cancelText={this.props.cancelText}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}
