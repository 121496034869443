import React from 'react';
import AbstractClass from '../../../utils/Components/AbstractClass/AbstractClass';
import { useAppSelector } from '../../../store/rootReducer';
import { getMobileItem, pageHeaderExtraDefault } from './DictionaryExtra';
import FormRecord from './components/EditRecord';
import { DictionaryProps } from './types';

/**
 * @param {DictionaryProps<any>} props
 * @constructor
 */
function DictionaryComponent({
  t,
  routerProps,
  dictionaryData,
}: DictionaryProps<any>) {
  const {
    apiMethod,
    idName,
    dataListName,
    tabNameField,
    pageHeaderTitle,
    pageHeaderExtra,
    reducer,
    setData,
    columnsName,
    columns,
    searchFields,
    name,
    setContent,
    popupList,
    addComponent,
    editComponent,
    formFields,
    permission,
  } = dictionaryData;
  const data = useAppSelector((state) => state[reducer]);
  const [isDownload, setIsDownload] = React.useState(false);

  const filteredDictionary = pageHeaderExtra
    ? [
        ...pageHeaderExtra(isDownload),
        ...pageHeaderExtraDefault(permission.create),
      ]
    : pageHeaderExtraDefault(permission.create);

  return (
    <AbstractClass
      apiMethod={apiMethod}
      routerProps={routerProps!}
      dataListName={dataListName}
      idName={idName}
      pageHeaderTitle={pageHeaderTitle}
      searchFields={searchFields(name)}
      columns={columns(name)}
      AddComponent={
        addComponent || (
          // @ts-ignore
          <FormRecord
            type={'add'}
            name={name}
            formFields={formFields ? formFields(t!) : []}
          />
        )
      }
      EditComponent={
        editComponent || (
          // @ts-ignore
          <FormRecord
            idName={idName}
            type={'edit'}
            formFields={formFields ? formFields(t!) : []}
            name={name}
          />
        )
      }
      popupList={popupList}
      tabNameField={tabNameField}
      pageHeaderExtra={filteredDictionary}
      createModalWidth={400}
      editModalWidth={400}
      toggleDownload={(value) => setIsDownload(value)}
      columnsName={columnsName}
      setData={setData}
      setContent={setContent}
      data={data.data}
      content={data.content}
      getListItemMobile={(content) => getMobileItem(content, dictionaryData)}
    />
  );
}

export default DictionaryComponent;
