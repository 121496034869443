import React, { useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  notification,
  Row,
  Spin,
  Table,
  Tabs,
  TabsProps,
} from 'antd';
import API from '../../API/API';
import { TableSearchProps } from './TableSearchTypes';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import { DeleteOutlined } from '@ant-design/icons';
import { getSummary } from './helpers/getSummary';
import cloneDeep from 'lodash/cloneDeep';
import { useFilterSearchFields } from '../AbstractPage/helpers/useFilterSearchFields';
import { getSearchParams } from '../../Base/helpers/getSearchParams';
import SearchPanel from '../SearchPanel/SearchPanel';
import { Key } from 'antd/es/table/interface';

const TableSearchForm = React.memo(
  ({
    api,
    onFinish,
    dataListName,
    idName,
    columns,
    searchFields,
    isQuantity,
    defaultValueName,
    initialSearchValues,
    disabledCreate,
    summary: summaryFunc,
    getCheckboxProps,
    searchFilesUri,
    type,
    initValue,
  }: TableSearchProps) => {
    const initParams = new URLSearchParams(initialSearchValues);
    const [searchParams, setSearchParams] = useState<any>(initParams);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorter, setSorter] = useState<any>(null);
    const [data, setData] = useState<Array<any>>();
    const [isFetchData, setIsFetchData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [selectedRowKey, setSelectedRowKey] = useState<Key[]>(
      initValue?.map((el) => el[idName] ?? el.id) ?? []
    );

    const [selectedRow, setSelectedRow] = useState<
      Array<any & { quantity?: number }>
    >(initValue ?? []);
    const onFinishSearch = (value) => {
      let data = value.data[dataListName].content;
      data = isQuantity
        ? data.map((el) => {
            const selectedRowQuantity = selectedRow.find(
              (row) => row[idName] === el[idName]
            )?.quantity;
            return {
              ...el,
              quantity:
                selectedRowQuantity !== undefined
                  ? selectedRowQuantity
                  : defaultValueName
                  ? el[defaultValueName]
                  : 0,
            };
          })
        : data;
      setData(data);
      setIsFetchData(false);
      setTotalElements(value.data[dataListName].totalElements);
    };

    /**
     * Замена запятой на точку и форматирование в число
     * @param {string | number} value
     * @return {number}
     */
    const formatStringToNumber = (value: string | number) => {
      return +value.toString().replace(',', '.');
    };

    const setQuantity = (value, id) => {
      setSelectedRow((prevState) => {
        if (!value) {
          return prevState.filter((el) => el[idName] !== id);
        }
        const clone = cloneDeep(prevState);
        const row = clone.find((row) => row[idName] === id);
        if (row) {
          row.quantity = formatStringToNumber(value);
        } else {
          const currentRow = data?.find((el) => el[idName] === id);
          clone.push({ ...currentRow, quantity: formatStringToNumber(value) });
        }
        return clone;
      });
      setSelectedRowKey((prevState) => {
        if (!value) {
          return prevState.filter((el) => el !== id);
        }
        return uniq([...prevState, id]);
      });
      setData((prevState) => {
        const currentIndex = prevState?.findIndex((el) => el[idName] === id);
        const copy = cloneDeep(prevState);
        if (currentIndex !== undefined && currentIndex >= 0) {
          if (copy) {
            copy[currentIndex]['quantity'] = formatStringToNumber(value);
          }
        }
        return copy;
      });
    };

    const debounceSetQuantity = debounce(setQuantity, 300);

    const onSelectChange = (
      newSelectedRowKey: Key[],
      newSelectedRow: Array<Record<string, any>>
    ) => {
      // const rawNewSelectedRow = newSelectedRow.map((row) => {
      //   const selectQuantity = selectedRow.find(
      //     (selectedRow) => selectedRow[idName] === row?.[idName]
      //   )?.quantity;
      //   return {
      //     ...row,
      //     quantity:
      //       selectQuantity !== undefined ? selectQuantity : row.quantity,
      //   };
      // });
      const getQuantity = (key: Key) => {
        return isQuantity
          ? newSelectedRow.find((el) => el[idName] === key)?.quantity
          : undefined;
      };
      setSelectedRow((prevState) => {
        const newRows: typeof selectedRow = [];
        for (let i = 0; i < newSelectedRowKey.length; i++) {
          const newSelectedRowKeyElement = newSelectedRowKey[i];
          const foundRow = prevState?.find(
            (el) => el[idName] === newSelectedRowKeyElement
          );
          if (foundRow) {
            const foundQuantity = foundRow.quantity;
            newRows.push({
              ...foundRow,
              quantity:
                foundQuantity === undefined
                  ? getQuantity(newSelectedRowKeyElement)
                  : foundQuantity,
            });
          } else {
            const findInNewSelectRow = newSelectedRow.find(
              (el) => el?.[idName] === newSelectedRowKeyElement
            );
            if (findInNewSelectRow) {
              newRows.push(findInNewSelectRow);
            }
          }
        }
        return newRows;
      });
      setSelectedRowKey(newSelectedRowKey);
    };
    const getData = (pagination, sorter?) => {
      setIsFetchData(true);
      const params = getSearchParams(searchParams, sorter);
      const url = api.split('?')[0];
      API.get(
        `${url}?page=${pagination.current - 1}&size=${
          pagination.pageSize
        }&${params}`
      )
        .then((response) => {
          let data = response.data[dataListName].content;
          data = isQuantity
            ? data.map((el) => {
                return {
                  ...el,
                  quantity:
                    selectedRow.find((row) => row[idName] === el[idName])
                      ?.quantity ??
                    (defaultValueName ? el[defaultValueName] : 0),
                };
              })
            : data;
          setIsFetchData(false);
          setData(data);
          setTotalElements(response.data[dataListName].totalElements);
        })
        .catch((error) => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    };
    const onChangeTable = ({ pagination, sorter }) => {
      setPageSize(pagination.pageSize);
      setCurrent(pagination.current);
      setSorter(sorter);
      getData(pagination, sorter);
    };
    useEffect(() => {
      getData({ pageSize: pageSize, current: current });
    }, []);

    const sendToServer = () => {
      setLoading(true);
      onFinish(selectedRowKey, selectedRow)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    const getColumnInfo = () => {
      const newColumns = [
        ...columns(setQuantity, selectedRowKey).filter(
          (col) => col.dataIndex !== 'quantity'
        ),
      ];
      if (isQuantity) {
        newColumns.push({
          title: 'Количество',
          key: 'quantity',
          dataIndex: 'quantity',
          align: 'center',
          width: columns(setQuantity, selectedRowKey).find(
            (col) => col.dataIndex === 'quantity'
          )?.width,
        });
      }
      newColumns.push({
        title: '',
        key: 'delete',
        dataIndex: idName,
        align: 'center',
        render: (item) => (
          <Button
            icon={<DeleteOutlined />}
            danger
            size={'small'}
            onClick={() => {
              setSelectedRow((prevState) =>
                prevState.filter((el) => el[idName] !== item)
              );
              setSelectedRowKey((prevState) =>
                prevState.filter((el) => el !== item)
              );
            }}
          />
        ),
      });
      return newColumns;
    };

    const columnsInfo = getColumnInfo();

    const summary = getSummary(columnsInfo);
    const { formatSearchFields, getFilteredSearchFields } =
      useFilterSearchFields(
        searchFields,
        searchFilesUri?.replace('?', '') ?? ''
      );

    useEffect(() => {
      const params = getSearchParams(searchParams, sorter);
      searchFilesUri && getFilteredSearchFields(params);
    }, []);

    const tabs: TabsProps['items'] = [
      {
        key: '1',
        label: 'Поиск',
        children: (
          <>
            {!!formatSearchFields.length && (
              <Collapse
                style={{ marginBottom: '32px' }}
                defaultActiveKey={['1']}
              >
                <Collapse.Panel key="1" header={'Поиск'}>
                  <SearchPanel
                    searchFields={formatSearchFields}
                    link={`${api}&page=${current - 1}&size=${pageSize}&`}
                    onFinish={onFinishSearch}
                    onLoad={(params) => {
                      setIsFetchData(true);
                      setSearchParams(params);
                      getFilteredSearchFields?.(params.toString());
                    }}
                    notAdditional
                    initialValues={initialSearchValues}
                  />
                </Collapse.Panel>
              </Collapse>
            )}
            <Spin spinning={isFetchData}>
              <Table
                rowKey={(record) => record[idName]}
                columns={columns(debounceSetQuantity, selectedRowKey)}
                bordered
                size={'small'}
                dataSource={data}
                rowSelection={{
                  type: type ? type : 'checkbox',
                  selectedRowKeys: selectedRowKey,
                  onChange: onSelectChange,
                  preserveSelectedRowKeys: true,
                  getCheckboxProps,
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalElements,
                  current: current,
                  pageSize: pageSize,
                  size: 'small',
                }}
                onChange={(pagination, filters, sorter) => {
                  onChangeTable({
                    pagination: pagination,
                    sorter: sorter,
                  });
                }}
              />
            </Spin>
            <Row>
              <Button
                type={'primary'}
                loading={loading}
                onClick={sendToServer}
                disabled={disabledCreate ? disabledCreate(selectedRow) : false}
                style={{ margin: '16px 0 32px' }}
              >
                {initValue
                  ? 'Редактировать'
                  : type === 'radio'
                  ? 'Выбрать'
                  : 'Создать'}
              </Button>
            </Row>
          </>
        ),
      },
      {
        key: '2',
        label: 'Выбранные элементы' + ` (${selectedRow.length})`,
        children: (
          <Table
            dataSource={selectedRow}
            columns={columnsInfo}
            pagination={false}
            summary={
              isQuantity ? summaryFunc?.(columnsInfo) ?? summary : undefined
            }
          />
        ),
      },
    ];

    return <Tabs defaultActiveKey={'1'} items={tabs} />;
  }
);
TableSearchForm.displayName = 'TableSearchForm';
export default TableSearchForm;
