import { Dictionary } from '../../AppTypes';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_BRANDS = 'SET_DATA_BRANDS';
const SET_CONTENT_BRANDS = 'SET_CONTENT_BRANDS';

export declare type DataBrands = {
  success: boolean;
  DataList: ResponseData<Dictionary>;
};

export declare type BrandsReducer = {
  data: DataBrands | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {DataBrands} data
 * @return {any}
 */
export function setDataBrands(data) {
  return {
    type: SET_DATA_BRANDS,
    payload: data,
  };
}

/**
 * @param {Array<DataBrands>} data
 * @return {any}
 */
export function setContentBrands(data) {
  return {
    type: SET_CONTENT_BRANDS,
    payload: data,
  };
}

const initialState: BrandsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_BRANDS:
      return { ...state, data: action.payload };
    case SET_CONTENT_BRANDS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}
