import store from './store';
import { combineReducers } from 'redux';
import { authReducer } from './reducers/AuthReducer';
import { notificationsReducer } from './reducers/NotificationsReducer';
import { dictionariesReducer } from './reducers/Dictionaries';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { mainPageReducer } from './reducers/mainPage';
import { projectsReducer } from './reducers/Projects';
import { departmentsReducer } from './reducers/Departments';
import { workplacesReducer } from './reducers/Workplaces';
import { positionsReducer } from './reducers/Positions';
import { refusalsReducer } from './reducers/Refusals';
import { candidatesReducer } from './reducers/Candidates';
import { resultInterviewReducer } from './reducers/ResultInterview';
import { dictionariesHhReducer } from './reducers/DictionariesHH';
import { questionnaireReducer } from './reducers/Questionnaire';
import { completedQuestionnaireReducer } from './reducers/CompletedQuestionnaire';
import { organizationReducer } from './reducers/Organization';
import { workingMethodReducer } from './reducers/WorkingMethod';
import { citizenshipReducer } from './reducers/Citizenship';
import { workDirectionReducer } from './reducers/WorkDirection';
import { subworkDirectionReducer } from './reducers/SubworkDirection';
import { workStatusReducer } from './reducers/WorkStatus';
import { employeeReducer } from './reducers/Employee';
import { departmentDirectionWorkBondReducer } from './reducers/DepartmentDirectionWorkBond';
import { locationEmployeeReducer } from './reducers/LocationEmployee';
import { employeeReportCardReducer } from './reducers/EmployeeReportCard';
import { accountsReducer } from './reducers/Accounts';
import { interviewRequestReducer } from './reducers/InterviewRequest';
import { keySkillsReducer } from './reducers/KeySkills';
import { commonApi } from './commonApi';
import { abstractClassDataReducer } from './reducers/AbstractClassData';
import workplaceTabsReducer from './reducers/WorkplaceTabs';
import abstractColumnsSlice from './reducers/AbstractColumnsSlice';
import { brandsReducer } from './reducers/Brands';
import { yambookApi } from './yambookApi';

const rootReducer = combineReducers({
  authReducer,
  notificationsReducer,
  dictionariesReducer,
  mainPageReducer,
  workplaceTabsReducer,
  abstractColumnsSlice,
  projectsReducer,
  departmentsReducer,
  workplacesReducer,
  positionsReducer,
  refusalsReducer,
  candidatesReducer,
  resultInterviewReducer,
  dictionariesHhReducer,
  questionnaireReducer,
  completedQuestionnaireReducer,
  organizationReducer,
  workingMethodReducer,
  citizenshipReducer,
  workDirectionReducer,
  subworkDirectionReducer,
  workStatusReducer,
  employeeReducer,
  departmentDirectionWorkBondReducer,
  locationEmployeeReducer,
  employeeReportCardReducer,
  accountsReducer,
  interviewRequestReducer,
  keySkillsReducer,
  abstractClassDataReducer,
  brandsReducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [yambookApi.reducerPath]: yambookApi.reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default rootReducer;
