import React from 'react';
import { Button, Descriptions, Modal, Result, Space } from 'antd/es';
import API from '../../utils/API/API';
import { connect } from 'react-redux';
import NotificationUtils from './NotificationUtils';
import dayjs from 'dayjs';
import {
  setCurrentNotification,
  setVisibleNotification,
} from '../../store/reducers/NotificationsReducer';
import { isDesktop } from 'react-device-detect';
import isEqual from 'lodash/isEqual';
import { notificationPermission } from './Notification.permission';

type Props = {
  list: Array<any>;
  dispatch: Function;
  current: any;
  visible: boolean;
  permissionList?: string[];
};

type State = {
  visible: boolean;
  timer: any;
  current: any;
};

class Notification extends React.Component<Props, State> {
  private notificationUtils;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      timer: null,
      current: null,
    };

    this.notificationUtils = new NotificationUtils({
      id: props.profile.id,
      dispatch: props.dispatch,
      successEdit: this.resetNotification,
    });
  }

  componentDidMount() {
    this.checkTime(this.props.list);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.list, this.props.list))
      this.checkTime(this.props.list);
  }

  resetNotification = () => {
    this.props.dispatch(setVisibleNotification(false));
    this.props.dispatch(setCurrentNotification(null));
  };

  checkTime = (list) => {
    if (this.state.timer) clearTimeout(this.state.timer);

    let time = 86400001;
    let current: any = null;

    if (list) {
      list.forEach((item: any) => {
        let currentTime = new Date(item.date).valueOf() - new Date().valueOf();

        if (currentTime > 0) {
          if (currentTime < time) {
            time = currentTime;
            current = item;
          }
        }
      });
    }

    if (time < 86400001) {
      this.props.dispatch(setCurrentNotification(current));
      this.startTimer(time);
    }
  };

  startTimer = (time: number) => {
    this.setState({
      timer: setTimeout(() => {
        this.props.dispatch(setVisibleNotification(true));
      }, time),
    });
  };

  onCloseNotification = (status) => {
    const self = this;

    API.put(
      `api/notification/completed/${this.props.current.id}?completed=${status}`
    ).then(function () {
      self.props.dispatch(setVisibleNotification(false));
      self.notificationUtils.getData();
    });
  };

  //Отложить напоминание
  onDeferNotification = (value) => {
    let userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    let time = new Date(new Date().getTime() - userTimezoneOffset + 1800000);
    const data = { ...value };
    data.date =
      dayjs(value.date).format().split('T')[0] +
      'T' +
      dayjs(time).format().split('T')[1];

    data.interviewerId = value.interviewer.id;
    data.accountId = value.account.id;

    this.notificationUtils.onEdit(data);
  };

  render() {
    const { current, visible, permissionList } = this.props;
    const content = current?.interviewer;

    const extraBtn = (
      <Space direction={isDesktop ? 'horizontal' : 'vertical'}>
        {permissionList?.includes(notificationPermission.update) && (
          <Button
            type="dashed"
            onClick={() => {
              this.onDeferNotification(current);
            }}
          >
            Отложить на 30 мин.
          </Button>
        )}
        {permissionList?.includes(notificationPermission.completed) && (
          <Button
            type="primary"
            onClick={() => {
              this.onCloseNotification(true);
            }}
          >
            {current?.type === 'Call' ? 'Звонок совершён' : 'Письмо отправлено'}
          </Button>
        )}
        {permissionList?.includes(notificationPermission.completed) && (
          <Button
            type="primary"
            danger
            onClick={() => {
              this.onCloseNotification(false);
            }}
          >
            {current?.type === 'Call'
              ? 'Звонок не совершён'
              : 'Письмо не отправлено'}
          </Button>
        )}
      </Space>
    );

    return (
      <Modal
        open={visible}
        footer={null}
        width={isDesktop ? '40vw' : '90vh'}
        onCancel={() => this.resetNotification()}
      >
        {current && (
          <>
            <Result
              status="warning"
              title={
                current.type === 'Call'
                  ? 'Пора позвонить!'
                  : 'Пора отправить письмо!'
              }
              subTitle={current.text}
              extra={extraBtn}
            />

            <Descriptions
              bordered
              size="small"
              layout="horizontal"
              labelStyle={{ fontWeight: 'semibold' }}
            >
              <Descriptions.Item label="Имя" span={6}>
                {`${content?.firstName} ${content?.lastName}`}
              </Descriptions.Item>
              <Descriptions.Item label="Город" span={6}>
                {content?.city?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Телефон" span={6}>
                {content?.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={6}>
                {content?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Должность" span={6}>
                {content?.positions?.positionsName}
              </Descriptions.Item>
              <Descriptions.Item label="Отдел" span={6}>
                {content?.department?.departmentName}
              </Descriptions.Item>
              <Descriptions.Item label="Место работы" span={6}>
                {content?.workplace?.workplaceName}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    visibleList: state.notificationsReducer.visibleList,
    notificationsList: state.notificationsReducer.notificationsList,
    profile: state.authReducer.profile,
    permissionList: state.authReducer.permissionList,
    current: state.notificationsReducer.currentNotification,
    visible: state.notificationsReducer.visibleNotification,
  };
};

export default connect(mapStateToProps)(Notification);
